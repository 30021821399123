import _ from 'lodash';
import lib from 'lib';
import K from 'k';
import DetailsHelper from 'helpers/details-helper';
import Room from 'project-helpers/room';
import { handleProductPropertyChange } from './product-properties-view-helpers';
import UpdatesMapsHelpers from 'helpers/updates-maps-helpers';

import Container from 'project-helpers/container';

function getContainerFieldGroups ({container, elevation, viewKey, viewMode, activeDetailLevel, activeFillMode, activeUserLense}) {
  var scribeFieldSets = [];
  var scribeTypeFieldSets = [];
  var wrapBackExtensionFieldSets = [];
  var {customData} = container;
  var {wrap} = customData;
  var {companyKey, project, isEmployee, room} = Container.get(['companyKey', 'project', 'isEmployee', 'room'], {container});
  var hasEndPanels = wrap.left || wrap.right;
  var {title, isOrnament} = Container.getTypeDataFor({container});

  var endCapPanelWording = {
    capPanel: 'Cap',
    endPanel: 'End'
  }[container.type] || 'End/Cap';

  var fieldSetGroups = [
    {title: 'Dimensions', properties: []},
    {title: 'Scribe', properties: []},
    {title: 'Kick', properties: []},
    {title: 'Lighting', properties: []},
    {title: `${endCapPanelWording} Panel`, properties: []},
    {title: `Additional ${endCapPanelWording} Panel`, properties: []},
    {title: 'Countertops', properties: []},
    {title: 'Materials', properties: []},
    // {title: 'Additional Materials', properties: []},
    {title: 'Pulls', properties: []},
    {title: 'Other', properties: []},
    ...(activeFillMode === 'grayscale' ? [{title: 'Grayscale', properties: []}] : [])
  ];

  var dimensionsGroupIndex = _.findIndex(fieldSetGroups, {title: 'Dimensions'});
  var kickGroupIndex = _.findIndex(fieldSetGroups, {title: 'Kick'});
  var scribeGroupIndex = _.findIndex(fieldSetGroups, {title: 'Scribe'});
  // var additionalScribeGroupIndex = _.findIndex(fieldSetGroups, {title: 'Additional Scribe'});
  var lightingGroupIndex = _.findIndex(fieldSetGroups, {title: 'Lighting'});
  var endCapPanelGroupIndex = _.findIndex(fieldSetGroups, {title: `${endCapPanelWording} Panel`});
  var additionalEndCapPanelGroupIndex = _.findIndex(fieldSetGroups, {title: `Additional ${endCapPanelWording} Panel`});
  var countertopGroupIndex = _.findIndex(fieldSetGroups, {title: 'Countertops'});
  var materialGroupIndex = _.findIndex(fieldSetGroups, {title: 'Materials'});
  // var additionalMaterialGroupIndex = _.findIndex(fieldSetGroups, {title: 'Additional Materials'});
  var pullGroupIndex = _.findIndex(fieldSetGroups, {title: 'Pulls'});
  var otherGroupIndex = _.findIndex(fieldSetGroups, {title: 'Other'});
  var grayscaleGroupIndex = _.findIndex(fieldSetGroups, {title: 'Grayscale'});

  var dimensionsFieldSets = DetailsHelper.getDimensionsEditablePropFieldSets({container});

  if (dimensionsFieldSets.length > 0) fieldSetGroups[dimensionsGroupIndex].properties.push(...dimensionsFieldSets);

  fieldSetGroups[dimensionsGroupIndex].properties.push({key: 'rotation', title: 'Rotation', placeholder: 0, type: 'number', views: ['top']});

  _.forEach(_.flatMap(Container.getTypeDataFor({container}).editablePropFieldSets), property => {
    let relevantFieldSetIndex = _.findIndex(fieldSetGroups, {title: 'Other'}); //other

    if (_.includes(property.key, 'customData.wrap.topThickness') && container.type === 'capPanel') {
      relevantFieldSetIndex = dimensionsGroupIndex;
    }
    else if (_.includes(property.key, 'customData.wrap') || _.includes(property.key, 'customData.customWrapBreaks') || _.includes(property.key, 'customData.sculptedPanel') || _.includes(property.key, 'customData.panelStyle') || _.includes(property.key, 'customData.flyby')) {
      relevantFieldSetIndex = endCapPanelGroupIndex;

      if (_.includes(property.key, 'customData.wrap.depths') || property.key === `customData.wrap.leftPanelRoundedSides` || property.key === `customData.wrap.rightPanelRoundedSides`) {
        relevantFieldSetIndex = (container.type === 'endPanel' || activeUserLense === 'sales') ? endCapPanelGroupIndex : additionalEndCapPanelGroupIndex;
      }

      if (_.includes(property.key, `customData.wrap.nonStandard`)) {
        relevantFieldSetIndex = otherGroupIndex;
      }
    }
    else if (_.includes(property.key, 'customData.insetKick')) {
      relevantFieldSetIndex = container.type === 'kick' ? dimensionsGroupIndex : kickGroupIndex;
    }
    else if (_.includes(property.key, 'customData.seatingDepth')) {
      relevantFieldSetIndex = dimensionsGroupIndex;
    }
    else if (_.includes(property.key, 'customData.unitDepth')) {
      relevantFieldSetIndex = dimensionsGroupIndex;
    }
    else if (_.includes(property.key, 'customData.noWallSpacing')) {
      relevantFieldSetIndex = dimensionsGroupIndex;
    }
    else if (_.includes(property.key, 'customData.cleatDepth')) {
      relevantFieldSetIndex = dimensionsGroupIndex;
    }

    fieldSetGroups[relevantFieldSetIndex].properties.push(property);
  });

  if (_.includes(['islandSeating', 'islandBackPanel'], container.type) && !container.customData.panelsAreDisabled) {
    fieldSetGroups[otherGroupIndex].properties.push({key: 'customData.backPanelWidths', title: 'Panel Widths', type: 'text', views: ['front']});
    if (companyKey === 'hb') fieldSetGroups[otherGroupIndex].properties.push({key: 'customData.pTextOffset', title: 'P Text Offset', type: 'size', views: ['front']});

  }

  if (container.type === 'vanity') {
    fieldSetGroups[otherGroupIndex].properties.push(
      {key: 'customData.hasShopDrawing', title: 'Non-Standard / Has Shop', type: 'checkbox', views: ['front']},
      ...(companyKey === 'hb' ? [
        {
          key: `customData.vanityLabelPosition`, type: 'radio',
          views: ['front'], title: `Unit # Position`,
          options: [{value: 'left', title: 'Left'}, {value: 'right', title: 'Right'}]
        },
        {
          key: 'customData.productionIdEnabled',
          title: 'Unit #',
          type: 'checkbox',
          defaultTrue: true,
          views: ['front']
        },
      ]: [])
    );
  }


  if (project.id === 6015 && Container.getHasWrap({container})) {
    fieldSetGroups[otherGroupIndex].properties.push({
      key: 'customData.showEndPanelInSection',
      title: 'Show End Panel In Section',
      type: 'checkbox',
      views: ['front', 'left', 'right', 'top', 'back']
    });
  }

  if (container.type === 'countertopSupport') {
    fieldSetGroups[otherGroupIndex].properties.push(
      ...(companyKey === 'hb' ? [
        {
          key: `customData.ctopSupportLabelXOffset`, type: 'number',
          views: ['front'], title: `Unit # X Offset`, allowEmptyString: true,
        },
        {
          key: `customData.ctopSupportLabelYOffset`, type: 'number',
          views: ['front'], title: `Unit # Y Offset`, allowEmptyString: true,
        },
      ]: [])
    );
  }

  if (Container.getTypeDataFor({container}).snapToFloor) {
    fieldSetGroups[otherGroupIndex].properties.push({key: 'customData.preventSnapToFloor', title: 'Allow floating', type: 'checkbox', views: ['front']});
  }

  if (isOrnament) {
    fieldSetGroups[otherGroupIndex].properties.push({key: 'customData.ornamentFill', title: 'Fill', type: 'text', views: ['front', 'top', 'bottom', 'left', 'right']});
    fieldSetGroups[otherGroupIndex].properties.push({key: 'customData.ornamentOpacity', title: 'Opacity (0-1)', type: 'number', placeholder: 1, allowEmptyString: true, views: ['front', 'top', 'bottom', 'left', 'right']});
    fieldSetGroups[otherGroupIndex].properties.push({key: 'customData.ornamentStrokeWidth', title: 'Line Weight', type: 'number', placeholder: 0.5, allowEmptyString: true, views: ['front', 'top', 'bottom', 'left', 'right']});
  }

  if (!_.get(room, 'customData.preventAutoGeneration') && _.includes(['base', 'baseWithChase', 'tall', 'wall', 'floatingBase', 'cornerCounterTransition', 'floatingShelves', 'hbIslandExtension', 'opencase',
  'backsplash', 'wallPanel', 'horizontalBarblock', 'rearFacingBarblock', 'assembly', 'daylightIsland', 'valet', 'sculptedPanel', 'pivotDoor', 'wallUnitLiner'], container.type)) {
    fieldSetGroups[otherGroupIndex].properties.push({
      key: 'customData.preventAutoGeneration',
      title: 'Prevent Auto Generation',
      type: 'checkbox',
      views: ['front', 'top', 'bottom', 'left', 'right'],
      userLenses: ['sales', 'design', 'engineering']
    });
  }

  fieldSetGroups[otherGroupIndex].properties.push({key: 'customData.isLocked', title: 'Locked', type: 'checkbox', views: ['front', 'top', 'bottom', 'left', 'right'], userLenses: ['sales', 'design', 'engineering']});
  if (!isOrnament) fieldSetGroups[otherGroupIndex].properties.push({key: 'customData.hideIn3d', title: 'Hide in 3d', type: 'checkbox', views: ['front', 'top', 'bottom', 'left', 'right'], userLenses: ['sales', 'design', 'engineering']});

  if (activeFillMode === 'grayscale') {
    fieldSetGroups[grayscaleGroupIndex].properties.push({
      key: 'customData.value', title: 'Value', type: 'radio', views: ['front', 'top', 'bottom', 'left', 'right'],
      options: [
        {value: '#f4f4f4', title: 'White'},
        {value: '#d7d7d7', title: 'Light Gray'},
        {value: '#ababab', title: 'Dark Gray'},
        {value: '#777777', title: 'Black'}
      ]
    });
  }

  if (title.toLowerCase().includes('appliance')) {
    fieldSetGroups[otherGroupIndex].properties.push(
      {
        key: 'customData.modelNumber',
        type: 'text',
        title: 'Model #',
        views: ['front', 'left', 'right']
      }
    );
    fieldSetGroups[otherGroupIndex].properties.push(
      {
        key: 'customData.vendor',
        type: 'text',
        title: 'Brand',
        views: ['front', 'left', 'right']
      }
    );
    fieldSetGroups[otherGroupIndex].properties.push(
      {
        key: 'customData.applianceType',
        type: 'text',
        title: 'Appliance Type',
        views: ['front', 'left', 'right']
      }
    );
  }

  if (Container.getSupportsScribe({container})) {
    var sideKeys = ['left', 'right'];
    if (Container.getSupportsTopScribe({container})) sideKeys.unshift('top');

    _.forEach(sideKeys, side => {
      scribeFieldSets.push({
        key: `customData.disabledScribe.${side}`,
        type: 'checkbox',
        inverseValue: true,
        title: `${_.startCase(side)} Scribe`,
        options: [{value: 0, title: 'Enabled'}, {value: 1, title: 'Disabled'}],
        views: side === 'top' ? ['front', 'left', 'right'] : ['top', 'front'],
        tooltipData: K.editorTooltipData.scribe
      });

      if (!_.get(container, `customData.disabledScribe.${side}`)) {
        scribeTypeFieldSets.push({
          key: `customData.scribeType.${side}`,
          type: 'radio',
          title: `${_.startCase(side)} Scribe Type`,
          options: [{value: 'automatic', title: 'Automatic'}, {value: 'recessed', title: 'Recessed'}, {value: 'panel', title: 'Panel'}, {value: 'corner', title: 'Corner'}],
          views: side === 'top' ? ['front', 'left', 'right'] : ['top', 'front'],
          tooltipData: K.editorTooltipData.scribe
        });
        fieldSetGroups[scribeGroupIndex].properties.push({
          key: `customData.customScribeBreaks.${side}`,
          type: 'text',
          title: side === 'top' ? 'Top Scribe Panel Widths' : `${_.startCase(side)} Scribe Break`,
          views: side === 'top' ? ['front', 'left', 'right'] : ['top', 'front'],
          tooltipData: K.editorTooltipData.scribe
        });

        if (_.includes(['left', 'right'], side)) {
          fieldSetGroups[scribeGroupIndex].properties.push({
            key: `customData.scribeProductionIdLocations.${side}`, type: 'radio',
            views: ['top', 'front'], title: `${_.startCase(side)} Scribe Label Position`,
            options: side === 'left' ? [{value: 'left', title: 'Left'}, {value: 'right', title: 'Right'}] : [ {value: 'right', title: 'Right'}, {value: 'left', title: 'Left'}]
          });
        }
      }

      if (!_.get(container, `customData.disabledScribe.${side}`) && (_.get(container, `customData.scribeType.${side}`, 'automatic') !== 'automatic')) {
        scribeTypeFieldSets.push({
          key: `customData.scribeWidth.${side}`,
          type: 'number',
          title: `${_.startCase(side)} Scribe ${_.includes(['top', 'bottom'], side) ? 'Height' : 'Width'}`,
          views: side === 'top' ? ['front', 'left', 'right'] : ['top', 'front'],
          tooltipData: K.editorTooltipData.scribe
        });
      }
    });
  }

  if (Container.getSupportsLighting({container})) {
    var lightingFieldSet = [
      {key: 'customData.hasLighting', title: 'Lighting', type: 'checkbox', views: ['front'], userLenses: ['sales', 'design', 'engineering']}
    ];

    if (container.customData.hasLighting) {
      lightingFieldSet.push({key: 'customData.lightingType', title: 'Lighting Type', type: 'radio', options: [{value: 'puck', title: 'puck'}, {value: 'linear', title: 'linear LED'}], views: ['front'], userLenses: ['sales', 'design', 'engineering']});

      if (companyKey === 'hb') lightingFieldSet.push({key: 'customData.lightingTemp', title: 'Lighting Temp', type: 'radio', options: [{value: '2700', title: '2700K'}, {value: '3000', title: '3000K'}], views: ['front']});

      if (container.customData.lightingType === 'puck') {
        if (companyKey === 'hb') lightingFieldSet.push({key: 'customData.puckLightColor', type: 'radio', title: 'Puck Light Color', options: [{value: 'matteSilver', title: 'Matte Silver'}, {value: 'matteBlack', title: 'Matte Black'}], views: ['front']})
        lightingFieldSet.push({key: 'customData.customLightPositions', type: 'text', title: 'Custom Light Positions', views: ['front']});
      }
    }

    fieldSetGroups[lightingGroupIndex].properties.push(...lightingFieldSet);
  }

  if (Container.getSupportsCountertop({container}) && container.type !== 'daylightIsland') {
    fieldSetGroups[countertopGroupIndex].properties.push({
      key: 'customData.managedCountertopsEnabled',
      type: 'checkbox',
      defaultTrue: true,
      title: 'Automatic countertops',
      views: ['top', 'front']
    });
  }

  if (scribeTypeFieldSets.length > 0) fieldSetGroups[scribeGroupIndex].properties.unshift(...scribeTypeFieldSets);
  if (scribeFieldSets.length > 0) fieldSetGroups[scribeGroupIndex].properties.unshift(...scribeFieldSets);

  if (_.includes(['base', 'baseWithChase', 'floatingBase', 'vanity'], container.type) && isEmployee) {
    fieldSetGroups[dimensionsGroupIndex].properties.push(
      {
        key: 'customData.subcounterHeight',
        type: 'radio',
        noThumbnail: true,
        title: 'Subcounter Height',
        options: [{value: 0, title: 'none'}, {value: 0.25, title: '1/4"'}, ...(!(companyKey === 'hb' && container.type === 'vanity') ? [{value: 0.5, title: '1/2"'}] : [{}]), {value: 0.75, title: '3/4"'}],
        views: ['front', 'left', 'right']
      },
      ...(companyKey === 'hb' && !_.includes(['vanity'], container.type) ? [{
        key: 'customData.customSubcounterHeight',
        type: 'size',
        title: 'Custom Subcounter Height',
        views: ['front', 'left', 'right']
      }] : [])
    );
  }

  if (Container.getHasKick({container})) {
    fieldSetGroups[container.type === 'kick' ? dimensionsGroupIndex : kickGroupIndex].properties.unshift(...K[companyKey].fields.kick);

    if (_.get(container, 'customData.flushKick')) {
      fieldSetGroups[container.type === 'kick' ? dimensionsGroupIndex : kickGroupIndex].properties.push({key: 'customData.flushKickCount', title: 'Flush Kick Count Override', type: 'number',
      views: ['top', 'front'],
      userLenses: ['sales', 'design', 'engineering']
    });
    }
  }

  if (container.type === 'countertopSupport') {
    fieldSetGroups[otherGroupIndex].properties.push({key: 'customData.aluminumLedgerCount', title: 'Ledger Count Override', type: 'number', allowEmptyString: true,
      views: ['top', 'front', 'left', 'right', 'back']
    });
  }

  if (_.includes(['base', 'baseWithChase'], container.type)) {
    fieldSetGroups[dimensionsGroupIndex].properties.push({key: 'customData.wallSpacing', title: 'Wall Spacing', type: 'radio', views: ['top', 'front', 'left', 'right'], options: [{title: 'Automatic', value: 'automatic'}, {title: 'None', value: 'none'}, {title: 'Include Cleat', value: 'cleat'}]});
  }

  //HINT ST doesn't want this option, but needed it for specific projects
  if ((companyKey === 'hb' || _.includes([8814], project.id)) && !isOrnament) {
    if (_.includes([583, 59, 9843, 1485, 10772, 5605, 7395, 6], project.userId) || _.includes([9019], project.id)) {
      fieldSetGroups[dimensionsGroupIndex].properties.push({
        title: 'Non-Standard Dimensions (REQUIRES ENGINEERING APPROVAL)',
        key: 'customData.hasNonStandardDimensions',
        type: 'checkbox',
        views: ['top', 'front', 'left', 'right', 'back']
      });
    }

    if (container.type === 'countertop') {
      fieldSetGroups[dimensionsGroupIndex].properties.push({key: 'customData.hasShopDrawing', title: 'Non-Standard / Has Shop', type: 'checkbox', views: ['front', 'left', 'right', 'back', 'top']});
    }
  }

  _.forEach(['top', 'bottom', 'left', 'right'], sideKey => {
    var title = {
      left: 'Left End Panel',
      right: 'Right End Panel',
      bottom: 'Bottom Cap Panel',
      top: 'Top Cap Panel'
    }[sideKey];

    if (wrap[sideKey] && !(container.type === 'endPanel' && sideKey !== 'left')) {
      if (_.includes(['endPanel', 'capPanel'], container.type)) {
        title = container.type === 'endPanel' ? 'End Panel' : 'Cap Panel';
      }

      fieldSetGroups[endCapPanelGroupIndex].properties.push(
        {
          key: `customData.wrap.${sideKey}ByOthers`,
          type: 'checkbox',
          title: `${title} By Others`,
          views: _.includes(['endPanel', 'capPanel'], container.type) ? ['top', 'front', 'left', 'right', 'back'] : (_.includes(['bottom', 'top'], sideKey) ? ['front'] : ['top', 'front', `${sideKey}`])
        },
        {
          key: `customData.wrap.${sideKey}CustomThickness`,
          type: 'size',
          title: `${title} Thickness (By Others)`,
          views: _.includes(['endPanel', 'capPanel'], container.type) ? ['top', 'front', 'left', 'right', 'back'] : (_.includes(['bottom', 'top'], sideKey) ? ['front'] : ['top', 'front', `${sideKey}`])
        },
      );

      fieldSetGroups[_.includes(['endPanel', 'capPanel'], container.type) ? endCapPanelGroupIndex : additionalEndCapPanelGroupIndex].properties.push(
        {
          key: `customData.wrap.${sideKey}CustomFill`,
          type: 'text',
          title: `${title} Fill`,
          views: _.includes(['endPanel', 'capPanel'], container.type) ? ['top', 'front', 'left', 'right', 'back'] : (_.includes(['bottom', 'top'], sideKey) ? ['front'] : ['top', 'front', `${sideKey}`])
        },
        {
          key: `customData.wrap.${sideKey}HideLabel`,
          type: 'checkbox',
          title: `${title} Hide Label`,
          views: _.includes(['bottom', 'top'], sideKey) ? ['front'] : ['top', 'front', `${sideKey}`]
        },
        {
          key: `customData.wrap.${sideKey}LabelPosition`, title: `${title} Label Position`, type: 'radio',
          views: ['front'],
          options: sideKey === 'left' ? [{value: 'right', title: 'Right'}, {value: 'left', title: 'Left'}] : [{value: 'left', title: 'Left'}, {value: 'right', title: 'Right'}]
        },
        {
          key: `customData.wrap.nonStandard.${sideKey}`,
          type: 'checkbox',
          title: `${title} Non-Standard`,
          views: _.includes(['endPanel', 'capPanel'], container.type) ? ['top', 'front', 'left', 'right', 'back'] : (_.includes(['bottom', 'top'], sideKey) ? ['front'] : ['top', 'front', `${sideKey}`])
        }
      );
    }
  });

  _.forEach(['left', 'right', 'top'], side => {
    var wrapSizes = Container.getWrapSizes({container, side});

    if (
      !(container.type === 'endPanel' && side !== 'left') &&
      !(container.type === 'capPanel' && side !== 'top') &&
      (_.get(container, `customData.wrap.${side}`) === 1 && wrapSizes[side] === 1.5) &&
      !_.get(wrap, `${side}ByOthers`)
    ) {
      fieldSetGroups[additionalEndCapPanelGroupIndex].properties.push({
        key: `customData.wrap.${side}ProductVariation`,
        title: _.includes(['endPanel', 'capPanel'], container.type) ? 'Panel Variation' : `${_.startCase(side)} Panel Variation`,
        views: ['front', 'top'],
        type: 'radio',
        options: [{value: 'standard', title: 'Standard'}, {value: 'anchored', title: 'Anchored'}, {value: 'singleSided', title: 'Single Sided'}],
        userLenses: ['engineering']
      })
    }
  });

  if (container.type === 'capPanel' || wrap.top) {
    fieldSetGroups[container.type === 'capPanel' ? endCapPanelGroupIndex : additionalEndCapPanelGroupIndex].properties.push({key: 'customData.capPanelLabelPosition', title: 'Cap Panel Label Position', type: 'radio',
      views: ['top', 'front'],
      options: [{value: 'top', title: 'Top'}, {value: 'bottom', title: 'Bottom'}]
    });
  }

  if (container.type === 'endPanel') {
    fieldSetGroups[endCapPanelGroupIndex].properties.push({key: 'customData.endPanelLabelPosition', title: 'End Panel Label Position', type: 'radio',
      views: ['top', 'front'],
      options: [{value: 'right', title: 'Right'}, {value: 'left', title: 'Left'}]
    });
  }

  var updatedFieldSets = [];
  var dependentFieldsNotToShow = [];

  _.forEach(['top', 'bottom', 'left', 'right'], side => {
    if (!wrap[side]) {
      dependentFieldsNotToShow.push(
        `customData.wrap.depths.${side}`,
        `customData.wrap.isDashed.${side}`,
        `customData.wrap.${side}LabelPosition`,
        `customData.wrap.nonStandard.${side}`,
        `customData.wrap.${side}GrainIndicatorSide`
      );
    }
    if (_.includes(['top', 'bottom'], side) && !wrap[side]) {
      dependentFieldsNotToShow.push(`customData.wrap.${side}Thickness`);
    }
    if (_.includes(['top', 'bottom'], side) && (!wrap.top && !wrap.bottom)) {
      dependentFieldsNotToShow.push('customData.wrap.capPanelHeights', 'customData.flyby.cap');
    }
    if (_.includes(['left', 'right'], side) && !hasEndPanels) {
      dependentFieldsNotToShow.push(
        'customData.wrap.thickness',
        'customData.flyby.end',
        `customData.wrap.style.${side}`,
        `customData.wrap.${side}LabelPosition`,
      );
    }
    if (_.get(container, 'customData.flyby.cap') !== 1) {
      dependentFieldsNotToShow.push('customData.flyby.cap');
    }
    if (_.get(container, 'customData.flyby.end') !== 1) {
      dependentFieldsNotToShow.push('customData.flyby.end');
    }
    if (!_.get(wrap, `${side}ByOthers`)) {
      dependentFieldsNotToShow.push(`customData.wrap.${side}CustomThickness`);
      dependentFieldsNotToShow.push(`customData.wrap.${side}CustomFill`);
      dependentFieldsNotToShow.push(`customData.wrap.${side}HideLabel`);
    }

    if (_.get(wrap, `${side}ByOthers`)) {
      dependentFieldsNotToShow.push(`customData.wrap.nonStandard.${side}`, `customData.wrap.${side}GrainIndicatorSide`);
    }

    if (_.get(wrap, `${side}ByOthers`) || container.type === 'endPanel') {
      dependentFieldsNotToShow.push(`customData.wrap.${side}LabelPosition`);
    }

    if (_.includes(['left', 'right'], side) && (!wrap[side] || !_.includes(['endPanel', 'tall'], container.type))) {
      dependentFieldsNotToShow.push(`customData.customWrapBreaks.${side}`);
    }

    if (_.includes(['left', 'right'], side) && !_.includes([1.625, 2.125], wrap.thickness)) {
      dependentFieldsNotToShow.push(`customData.wrap.style.${side}`);
    }

    if (_.includes(['left', 'right'], side) && (!wrap[side] || !_.includes([4], wrap.thickness))) {
      dependentFieldsNotToShow.push(
        `customData.wrap.boldStyle.${side}`,
        `customData.wrap.boldStyle.${side}PanelRoundedSides`,
      );
    }

    if (_.includes(['left', 'right'], side) && (companyKey !== 'vp' || !wrap[side] || !_.includes([3], wrap.thickness))) {
      dependentFieldsNotToShow.push(
        `customData.wrap.isSTMetalEndPanel`,
      );
    }

    if (_.includes(['left', 'right'], side) && wrap.thickness === 4) {
      dependentFieldsNotToShow.push(
        `customData.wrap.isDashed.${side}`,
        'customData.flyby.end',
      );
    }

    if (_.includes(['left', 'right'], side) && _.get(wrap, `boldStyle.${side}`) !== 'radius') {
      dependentFieldsNotToShow.push(`customData.wrap.boldStyle.${side}PanelRoundedSides`);
    }

    if (_.includes(['left', 'right'], side) && !_.includes([1.5], wrap.thickness)) {
      dependentFieldsNotToShow.push(
        `customData.wrap.${side}PanelRoundedSides`,
      );
    }

    if (viewKey !== 'front') {
      dependentFieldsNotToShow.push(`customData.wrap.${side}LabelPosition`);
    }

    if (wrap[side] && container.type !== 'endPanel') {
      wrapBackExtensionFieldSets.push({
        key: `customData.wrap.backExtension.${side}`,
        type: 'size',
        title: `${_.startCase(side)} End Panel Extension`,
        views: ['top', side]
      });
    }
  });

  if (wrapBackExtensionFieldSets.length > 0) fieldSetGroups[_.includes(['endPanel', 'capPanel'], container.type) ? endCapPanelGroupIndex : additionalEndCapPanelGroupIndex].properties.push(...wrapBackExtensionFieldSets);

  // //HINT backtrack and filter out fields we actually don't want
  // //WARNING antipattern - these fields should have never been pushed
  // if (companyKey === 'hb') {
  //   var sculptedPanelLocation = Container.getSculptedPanelLocation({container});
  //   var eitherEndPanelIsSculpted = sculptedPanelLocation === 'end' && (_.get(wrap, 'style.left', 'panel') !== 'panel' || _.get(wrap, 'style.right', 'panel') !== 'panel');
  //   var backPanelIsSculpted = sculptedPanelLocation === 'back' && (customData.panelStyle && customData.panelStyle !== 'panel');

  //   if (!backPanelIsSculpted && !eitherEndPanelIsSculpted) {
  //     dependentFieldsNotToShow.push(
  //       'customData.sculptedPanel.topRailHeight',
  //       'customData.sculptedPanel.bottomCasingHeight',
  //       'customData.sculptedPanel.isFlutedAdjacent'
  //     );
  //   }

  //   if (customData.panelStyle !== 'fluted') {
  //     dependentFieldsNotToShow.push('customData.sculptedPanel.isFlutedAdjacent');
  //   }
  // }

  if (companyKey !== 'hb') {
    dependentFieldsNotToShow.push('customData.wrap.style.left');
    dependentFieldsNotToShow.push('customData.wrap.style.right');
    dependentFieldsNotToShow.push('customData.panelStyle');
    // dependentFieldsNotToShow.push('customData.sculptedPanel.topRailHeight');
    // dependentFieldsNotToShow.push('customData.sculptedPanel.bottomCasingHeight');
    // dependentFieldsNotToShow.push('customData.sculptedPanel.isFlutedAdjacent');
  }

  if ((companyKey === 'vp' && !isEmployee) || (Container.getFlybyData({container}).left && Container.getFlybyData({container}).right) || !Container.getHasWrap({container})) dependentFieldsNotToShow.push('customData.wrap.overhang');

  if (!isEmployee) {
    dependentFieldsNotToShow.push(
      'customData.flyby.cap',
      'customData.flyby.end',
      'customData.flushKick',
      'customData.insetKick.left',
      'customData.insetKick.right'
    );
  }

  _.forEach(DetailsHelper.getCompatibleDetailsFor({container}), detail => {
    if (detail.type === 'material') {
      //HINT left and right scribe
      var relevantGroupIndex = _.includes(detail.key, 'ScribeMaterial') ? materialGroupIndex : materialGroupIndex;

      if (_.includes(detail.key, 'pullMaterial')) relevantGroupIndex = pullGroupIndex;

      fieldSetGroups[relevantGroupIndex].properties[_.includes(['frontMaterial', 'boxMaterial'], detail.key) ? 'unshift' : 'push']({
        path: `details.${detail.key}.id`,
        noThumbnail: false,
        type: 'radio',
        hasOptionGroups: true,
        options: detail.options,
        optionGroups: detail.optionGroups,
        title: detail.title,
        views: ['top', 'front', 'left', 'right', 'back'],
        userLenses: ['design', 'sales', 'engineering']
      });
    }
    else {
      var relevantGroupIndex = (_.includes(detail.key, 'grain') || _.includes(detail.key, 'frontPanelType')) ? materialGroupIndex : pullGroupIndex;
      fieldSetGroups[relevantGroupIndex].properties[_.includes(['pullType'], detail.key) ? 'unshift' : 'push']({
        path: `details.${detail.key}.id`,
        noThumbnail: true,
        type: 'radio',
        options: detail.options,
        title: detail.title,
        views: ['top', 'front', 'left', 'right', 'back'],
        userLenses: _.includes(['pullLocation', 'pullOrientation', 'grainDirection', 'grainContinuity'], detail.key) ? ['design', 'engineering'] : ['design', 'sales', 'engineering']
      });

      if (detail.key === 'grainContinuity') {
        fieldSetGroups[relevantGroupIndex].properties.push({
          key: 'customData.wrap.leftGrainIndicatorSide',
          type: 'select',
          title: 'Grain Flow Indicator Side',
          views: container.type === 'endPanel' ? ['left', 'right'] : ['left'],
          options: [{value: 'right', title: 'Right'}, {value: 'left', title: 'Left'}],
        });

        if (container.type !== 'endPanel') {
          fieldSetGroups[relevantGroupIndex].properties.push({
            key: 'customData.wrap.rightGrainIndicatorSide',
            type: 'select',
            title: 'Grain Flow Indicator Side',
            views: ['right'],
            options: [{value: 'right', title: 'Right'}, {value: 'left', title: 'Left'}],
          });
        }
      }
    }


    //HINT doesn't really make sense to edit pull offsets at the container level
    // if (detail.key === 'pullLocation') {
    //   if (companyKey === 'hb') {
    //     fieldSetGroups[_.includes(detail.key, 'pull') ? pullGroupIndex : materialGroupIndex].properties.push({
    //       path: `details.${detail.key}.settings.offsetX`,
    //       noThumbnail: true,
    //       type: 'number',
    //       title: 'Pull X Offset',
    //       placeholder: 0,
    //       views: ['front'],
    //     }, {
    //       path: `details.${detail.key}.settings.offsetY`,
    //       noThumbnail: true,
    //       type: 'number',
    //       title: 'Pull Y Offset',
    //       placeholder: 0,
    //       views: ['front']
    //     });
    //   }
    // }
  });

  if (_.includes([583, 59, 9843, 1485, 10772, 5605, 7395, 6], project.userId)) {
    fieldSetGroups[materialGroupIndex].properties.push({
      path: `customData.allowDiscontinuedMaterials`,
      type: 'checkbox',
      title: 'Allow Discontinued Materials',
      views: ['front', 'top', 'left', 'right', 'back'],
      userLenses: ['design', 'engineering']
    });
  }

  if (activeDetailLevel === 'rendering') {
    fieldSetGroups[otherGroupIndex].properties.push({
      path: `customData.renderingOverrideFill`,
      type: 'color',
      title: 'Rendering Override Fill Color',
      allowEmptyString: true,
      views: ['front'],
      userLenses: ['design', 'engineering']
    });
  }

  _.forEach(fieldSetGroups, fieldSets => {
    _.forEach(fieldSets.properties, field => {
      if (field.key === 'customData.wrap.thickness' && companyKey !== 'hb') {
        field.options = _.reject(field.options, option => _.includes([1.625, 2.125], option.value));
      }
      else if (field.key === 'customData.kickHeight' && companyKey === 'vp' && !isEmployee) {
        field.options = _.reject(field.options, option => _.includes([0.75], option.value));
      }
    });

    var updatedFieldSetProperties = _.filter(fieldSets.properties, field => {
      return !_.includes(dependentFieldsNotToShow, field.key) && (viewMode === 'both' ? true : (container.type === 'countertop' || viewMode === 'lite' || _.includes(field.views, Container.getSideKey({container, viewKey, elevation}))));
    });

    if (updatedFieldSetProperties.length > 0) updatedFieldSets.push({title: fieldSets.title, properties: updatedFieldSetProperties});
  });

  if (container.type === 'countertop') {
    var fieldSets = updatedFieldSets;
    var {isByOthers} = container.customData;
    var structure = Container.getEditablePropsStructure({container});

    //HINT using unshift because these should be above other dimension fields
    fieldSets.unshift({title: 'Countertop', properties: [{key: 'customData.isByOthers', type: 'checkbox', title: `Made by ${K[companyKey].title}`,
      inverseValue: true,
      views: ['top', 'front', 'left', 'right', 'back'],
      userLenses: ['sales', 'design', 'engineering']
    }]});

    if (isByOthers) {
      fieldSets[1].properties.push({key: 'dimensions.height', type: 'size', title: 'Height', step: 1 / 16});
    }
    else {
      fieldSets[0].properties.push(
        {key: 'customData.productId', title: 'Type', type: 'radio', options: K[companyKey].fieldOptions.countertop.productId, userLenses: ['design', 'sales', 'engineering']}
      );

      if (structure.customData.sinks) {
        var s = 0;

        _.forEach(structure.customData.sinks.children, (sinkStructure, id) => {
          var sinkFieldSet = [{
            key: `customData.sinks.${id}.includeCutout`,
            type: 'checkbox',
            title: `Include Sink Cutout${_.keys(structure.customData.sinks.children).length > 1 ? ` - Sink ${s + 1}` : ''}`,
            subtitle: '$600',
            userLenses: ['sales', 'design', 'engineering']
          }];

          if (sinkStructure.includeDrainfield) {
            if (sinkStructure.includeDrainfield.options.length > 1) {
              sinkFieldSet.push({
                key: `customData.sinks.${id}.includeDrainfield`,
                type: 'radio',
                title: `${_.get(container, `customData.sinks.${id}.drainfieldIsFlat`) ? '' : 'Grooved '}Drainfield${_.keys(structure.customData.sinks.children).length > 1 ? ` - Sink ${s + 1}` : ''}`,
                subtitle: '$600 each',
                options: _.map(sinkStructure.includeDrainfield.options, value => ({value, title: _.startCase(value)})),
                userLenses: ['sales', 'design', 'engineering']
              });

              if (companyKey === 'hb' && _.get(container, `customData.sinks.${id}.includeDrainfield`, 'none') !== 'none') {
                sinkFieldSet.push({
                  key: `customData.sinks.${id}.drainfieldIsFlat`,
                  type: 'checkbox',
                  title: `Flat Drainfield${_.keys(structure.customData.sinks.children).length > 1 ? ` - Sink ${s + 1}` : ''}`,
                  userLenses: ['sales', 'design', 'engineering']
                });
              }
            }

            sinkFieldSet.push({key: `customData.sinks.${id}.width`, title: `Sink size${_.keys(structure.customData.sinks.children).length > 1 ? ` - Sink ${s + 1}` : ''}`, type: 'size', breakBefore: true});
            sinkFieldSet.push({key: `customData.sinks.${id}.depth`, title: `Depth${_.keys(structure.customData.sinks.children).length > 1 ? ` - Sink ${s + 1}` : ''}`, type: 'size'});
          }

          s++;
          fieldSets[0].properties.push(...sinkFieldSet);
        });
      }
    }

    fieldSets[0].properties.push(
      {key: 'customData.labelPosition', title: 'Label Position', type: 'radio',
        views: ['top', 'front'],
        options: [{value: 'top', title: 'Top'}, {value: 'bottom', title: 'Bottom'}]
      },
      {key: 'customData.labelXOffset', title: 'Label X Offset', type: 'size',
        views: ['front', 'left', 'right', 'back'],
      },
      {key: 'customData.inManualMode', type: 'checkbox', title: 'Manually Managed',
        options: [{value: 0, title: 'Automatic'}, {value: 1, title: 'Manual'}],
        views: ['top', 'front'],
        userLenses: ['sales', 'design', 'engineering']
      }
    );

    if (isByOthers && activeFillMode === 'materialColors') {
      fieldSets[0].properties.push(
        {key: 'customData.fillColor', title: 'Color', type: 'text'}
      );
    }

    return fieldSets;
  }

  return updatedFieldSets;
}

function handleContainerPropertyChange({activeEntityId, activeEntity, path, resourceActions, isBatched, value}) {
  var {room, companyKey} = Container.get(['room', 'companyKey'], {container: activeEntity});

  var cachedContainer = _.cloneDeep(activeEntity);

  _.set(activeEntity, path, value);

  let updatedProps = {};
  var updatesMap = {
    rooms: {creations: [], deletedIds: [], updates: []},
    containers: {creations: [], deletedIds: [], updates: [], tracks: []},
    products: {creations: [], deletedIds: [], updates: [], tracks: []},
    productOptions: {creations: [], deletedIds: [], updates: []}
  };

  if (activeEntity.type === 'countertop') {
    if (activeEntity.customData.isByOthers) {
      updatedProps.customData = {...activeEntity.customData, sinks: {}};

      //HINT remove previously selected type of countertop when made by st
      delete updatedProps.customData.productId;
    }
    else if (activeEntity.customData.productId) {
      updatedProps.dimensions = {...activeEntity.dimensions, height: K[companyKey].ids.countertop.productIdHeightMap[activeEntity.customData.productId]};
    }
  }

  if (path === 'customData.hasNonStandardDimensions') {
    if (value) {
      if (activeEntity.type === 'vanity') {
        updatedProps.customData = {...activeEntity.customData, hasShopDrawing: 1};
        activeEntity.customData = {...activeEntity.customData, hasShopDrawing: 1};
      }
      else if (activeEntity.type === 'capPanel') {
        updatedProps.customData = {...activeEntity.customData, wrap: {...activeEntity.customData.wrap, nonStandard: {..._.get(activeEntity, 'customData.wrap.nonStandard', {}), top: 1}}};
        activeEntity.customData = {...activeEntity.customData, wrap: {...activeEntity.customData.wrap, nonStandard: {..._.get(activeEntity, 'customData.wrap.nonStandard', {}), top: 1}}};
      }
      else if (activeEntity.type === 'countertop') {
        updatedProps.customData = {...activeEntity.customData, hasShopDrawing: 1};
        activeEntity.customData = {...activeEntity.customData, hasShopDrawing: 1};
      }
    }
  }

  if (_.split(path, '.').length > 1) {
    var topLevelKey = _.split(path, '.')[0];

    if (!updatedProps[topLevelKey]) updatedProps[topLevelKey] = activeEntity[topLevelKey];
    else _.set(activeEntity, path, value);
  }
  else {
    updatedProps[path] = value;
  }

  if (_.includes(['endPanel', 'capPanel'], activeEntity.type) && _.includes(['customData.wrap.thickness', 'customData.wrap.topThickness'], path)) {
    var dimensionKey = activeEntity.type === 'capPanel' ? 'height' : 'width';

    updatedProps.dimensions = {...activeEntity.dimensions, ...(updatedProps.dimensions || {}), [dimensionKey]: value};
    activeEntity.dimensions = {...activeEntity.dimensions, [dimensionKey]: value};
  }

  if (_.includes(['kick'], activeEntity.type) && _.includes(['customData.kickHeight'], path)) {
    var dimensionKey = 'height';

    updatedProps.dimensions = {...activeEntity.dimensions, ...(updatedProps.dimensions || {}), [dimensionKey]: value};
    activeEntity.dimensions = {...activeEntity.dimensions, [dimensionKey]: value};
  }

  //HINT handle lighting temp defaults, which are dependent on lighting type
  if (companyKey === 'hb' && path === 'customData.lightingType' && value !== _.get(activeEntity, 'customData.lightingType')) {
    updatedProps.customData.lightingTemp = value === 'puck' ? '2700' : '3000';
    activeEntity.customData.lightingTemp = updatedProps.customData.lightingTemp;
  }

  var shouldConstrainDimensions = _.includes(['customData.hasNonStandardDimensions', 'customData.panelsAreDisabled'], path);

  if (shouldConstrainDimensions && !(activeEntity.type === 'countertop' && activeEntity.customData.isByOthers)) {
    if (!value) {
      const containerType = Container.get('containerType', {container: activeEntity});
      const constraints = containerType.constraintsFor({container: activeEntity});
      const constrainer = new lib.DimensionConstrainer({constraints});

      var constrainedDimensions = constrainer.constrain({dimensions: activeEntity.dimensions});

      updatedProps.dimensions = constrainedDimensions;
      activeEntity.dimensions = constrainedDimensions;
    }
  }

  if (_.includes(path, 'details')) {
    updatedProps.details = _.mapValues(updatedProps.details, detail => {
      return _.pick(detail, ['id', 'settings']);
    });

    if (path === 'details.scribeMaterial.id') {
      var scribeSides = ['top', 'left', 'right'];

      _.forEach(scribeSides, side => {
        updatedProps.details[`${side}ScribeMaterial`] = updatedProps.details.scribeMaterial;
        activeEntity.details[`${side}ScribeMaterial`] = updatedProps.details[`${side}ScribeMaterial`];
      });
    }
  }

  UpdatesMapsHelpers.combineUpdatesMaps(updatesMap, Container.update({container: activeEntity, cachedContainer, props: updatedProps, isBatched: true, isPropertiesViewUpdate: true, resourceActions}));

  if (_.includes(path, 'customData.subcounterHeight')) {
    var children = Container.get('products', {container: activeEntity});
    var subcounterHeight = value;

    _.forEach(children, product => {
      if (_.includes([1355, 901, 691, 762, 1516], product.productId)) {
        var shouldAddSubcounterHeight = subcounterHeight === 0.5;

        updatesMap.products.updates.push({where: {id: product.id}, props: {productionDimensions: {
          ...(product.productionDimensions || {}), height: product.dimensions.height + (shouldAddSubcounterHeight ? subcounterHeight : 0)
        }}});
      }
    });
  }

  if (_.includes(path, 'details') && !_.includes(['details.topScribeMaterial.id', 'details.rightScribeMaterial.id', 'details.leftScribeMaterial.id'], path)) {
    var children = DetailsHelper.getDetailsChildrenFor({container: activeEntity});

    if (children) _.forEach(children, child => {
      UpdatesMapsHelpers.combineUpdatesMaps(updatesMap, handleProductPropertyChange({activeEntityId: child.id, activeEntity: child, path, resourceActions, value, isBatched: true}));
    });
  }

  if (!isBatched) {
    Room.updateManagedResources({room, resourceActions});

    UpdatesMapsHelpers.makeReduxUpdatesFor({updatesMap, resourceActions});
  }

  if (path === 'customData.panelsAreDisabled' && value) {
    alert('This setting is for when base storage is placed behind seating.\n\nIn order to be able to select the storage container, and adjust the products it contains, interaction with this seating container will be disabled in front view.');
  }

  return updatesMap;
}

export {getContainerFieldGroups, handleContainerPropertyChange};
