import React, { useContext } from 'react';

import _ from 'lodash';
import lib from 'lib';
import Elevation from 'project-helpers/elevation';
import Volume from 'project-helpers/volume/index';

import ProjectDataContext from 'contexts/project-data-context';
import {CanvasDataContext, CanvasScriptObject, CanvasSelectionContext, EditableCanvasPolyline, CanvasErrorFallback } from 'canvas';
import { resourceActions, connect } from 'redux/index.js';
import K from 'k';

import { withErrorBoundary } from 'react-error-boundary';

class CanvasVolume extends React.Component {
  state = {
    isTransforming: false,
    cachedVolume: undefined
  };

  handleSelect = () => {
    const {volume, selectionData, canvasData, isSelected, viewKey} = this.props;
    const {setActiveEntities} = selectionData;
    const {isShifting} = canvasData;

    //HINT currently only allowing multi-select in top view
    if (isShifting && viewKey === 'top') {
      setActiveEntities({entities: [{resourceKey: 'volume', id: volume.id}], isMultiSelect: true});
    }
    else {
      setActiveEntities({entities: [{resourceKey: 'volume', id: volume.id}], isMultiSelect: false});
    }
  };

  handleDelete = () => {
    var {volume, projectData, selectionData} = this.props;

    selectionData.onDeselect();

    Volume.destroy({volume, resourceActions: this.props, pushToUndoQueue: projectData.pushToUndoQueue});
  };

  handleChange = async ({hitApi = true, props}) => {//eslint-ignore-line
    const {cachedVolume} = this.state;

    if (!hitApi && !this.state.isTransforming) {
      this.setState({cachedVolume: this.props.volume, isTransforming: true});
    }
    else if (hitApi) {
      this.props.projectData.pushToUndoQueue({type: 'volume', eventKey: 'transformEnd', instance: cachedVolume || this.props.volume});
      this.setState({cachedVolume: undefined, isTransforming: false});
    }

    this.props.updateVolume({id: this.props.volume.id, hitApi: false, props});

    if (hitApi) {
      await lib.api.update('volume', {where: {id: this.props.volume.id}, props});

      // setTimeout(() => {
      //   Project.autogenerateElevations({project: Room.get('project', {room: this.props.room}), resourceActions: this.props});
      // });
    }
  };

  handlePointsChange = ({sideKey, volume}) => (points, hitApi = true) => {//eslint-ignore-line
    const outlinesBySideKey = {...volume.outlinesBySideKey};

    outlinesBySideKey[sideKey] = _.map(points, (point) => ({x: point.x, y: point.y}));

    this.handleChange({props: {outlinesBySideKey}, hitApi});
  };

  handleAddOrDeletePoint = ({sideKey, volume}) => ({points}) => {//eslint-ignore-error
    const outlinesBySideKey = {...volume.outlinesBySideKey};

    outlinesBySideKey[sideKey] = _.map(points, (point) => ({x: point.x, y: point.y}));

    this.handleChange({props: {outlinesBySideKey}});
  };

  //WARNING only works for front view
  handleDragEnd = (position) => {//eslint-ignore-error
    var {volume} = this.props;
    //handler only used in front view with EditableCanvasPolyline

    this.handleTransformEnd({
      position,
      size: {width: volume.dimensions.width, height: volume.dimensions.height},
      rotation: volume.rotation
    });
  };

  handlePointDragMove = ({sideKey, volume}) => (points) => {
    this.handlePointsChange({sideKey, volume})(points, false);
  };

  handlePointDragEnd = ({sideKey, volume}) => (points) => {//eslint-ignore-error)
    this.handlePointsChange({sideKey, volume})(points);
  };

  handleTransform = (transformerProps) => {
    if (!this.state.isTransforming) {
      this.setState({cachedvolume: this.props.volume, isTransforming: true});
    }

    var {volume, viewKey, elevation, room, viewOffset} = this.props;

    const updatedProps = Volume.getUpdatedPropsForTransformerProps({volume, viewKey, elevation, room, viewOffset, transformerProps});

    //TODO if we're in top view, update relevant side points

    this.props.updateVolume({id: volume.id, props: {...updatedProps, eventType: 'transform'}, hitApi: false});
  };

  handleTransformEnd = (transformerProps) => {
    var cachedVolume;
    var {volume, viewKey, elevation, room, viewOffset, projectData} = this.props;

    volume = _.omit(volume, ['eventType']);

    if (transformerProps) {
      cachedVolume = _.cloneDeep(volume);

      let updatedProps = Volume.getUpdatedPropsForTransformerProps({volume, viewKey, elevation, room, viewOffset, transformerProps, roundToMinPrecision: !projectData.infinitePrecision});

      volume = {...volume, ...updatedProps};
    }
    else {
      cachedVolume = this.state.cachedvolume;
    }

    Volume.update({cachedVolume, volume, props: volume, resourceActions: this.props, pushToUndoQueue: projectData.pushToUndoQueue});

    this.setState({isTransforming: false, cachedvolume: undefined});
  };

  render() {
    var {viewKey, elevation, volume, realPosition, room, viewOffset, isSelected, activeDetailLevel, activeFillMode, renderForDrawings, multipleEntitiesSelected, canvasData, selectionData} = this.props;

    if (!volume) return null;

    var sideKey = Volume.getSideKey({volume, elevation, viewKey});
    var position, size, points;
    // var snapToLines = _.map(Volume.getSnapToLines({volume, elevation, room, viewKey}), line => _.mapValues(line, value => lib.object.sum(value, viewOffset)));
    var isRotatable = viewKey === 'top';
    var fill = Volume.getFill({volume, activeFillMode});

    if (sideKey === 'top' && _.get(volume, 'customData.hideTopFill')) fill = 'transparent';

    if (realPosition) position = realPosition;

    size = {width: volume.dimensions[K.sideSizeMap[sideKey].width], height: volume.dimensions[K.sideSizeMap[sideKey].height]};
    points = Volume.getPoints({volume, sideKey, viewKey, elevation});

    if (viewKey === 'top') {
      if (!realPosition) position = lib.object.sum(room.plan.position, viewOffset, {x: volume.position.x, y: volume.position.z});
    }
    else {
      if (!realPosition) position = lib.object.sum(viewOffset, Volume.getPositionInElevation({volume, elevation}));
    }

    var angledSideData = {};

    var isNon90 = false;

    if (viewKey === 'front') {
      var theta = lib.round(lib.math.trig.theta({degrees: [Elevation.getRotation({elevation}), volume.rotation]}), {toNearest: 1});

      if (!_.includes([0, 90, 180, 270, 360], theta)) {
        isNon90 = true;
        var volumeFootprint = Volume.getFootprintInRoom({volume});
        var volumeFootprintLines = Volume.getFootprintLines({volume});
        var elevationFootprint = Elevation.getFootprintInRoom({elevation});
        var elevationFootprintLines = Elevation.getFootprintLinesInRoom({elevation});

        var intersectedFootprintLines = _.filter(volumeFootprintLines, footprintLine => {
          return lib.math.linesIntersect({l1: elevation.lineInRoom, l2: footprintLine});
        });

        if (intersectedFootprintLines.length > 0) {
          var intersectionPoint1 = lib.math.intersectionPoint({l1: elevation.lineInRoom, l2: intersectedFootprintLines[0]});
          var intersectionPoint2;

          if (intersectedFootprintLines.length > 1) {
            intersectionPoint2 = lib.math.intersectionPoint({l1: elevation.lineInRoom, l2: intersectedFootprintLines[1]});
          }
          else {
            intersectionPoint2 = _.find(elevation.lineInRoom, elevationLinePoint => {
              return lib.polygon.pointInsidePolygon({point: elevationLinePoint, polygon: volumeFootprint});
            });
          }

          if (intersectionPoint2) {
            var sectionSize = lib.trig.distance({fromPoint: intersectionPoint1, toPoint: intersectionPoint2});

            var sectionScaleX = 1;

            var sectionPosition = _.minBy([Elevation.getPosition2d({elevation, position3d: {x: intersectionPoint1.x, y: volume.position.y, z: intersectionPoint1.y}}), Elevation.getPosition2d({elevation, position3d: {x: intersectionPoint2.x, y: volume.position.y, z: intersectionPoint2.y}})], 'x');

            var z = 0;

            angledSideData = {section: {side: 'section', z, scaleX: sectionScaleX, size: {height: size.height, width: sectionSize}, position: lib.object.sum(viewOffset, {x: sectionPosition.x, y: sectionPosition.y -volume.dimensions.height})}};
          }
        }

        _.forEach(['left', 'right', 'front', 'back'], volumeSide => {
          var sideFootprintLine = volumeFootprintLines[volumeSide];
          var sideRotationMap = {left: 90, right: 270, front: 0, back: 180};

          var sideTheta = lib.math.trig.theta({degrees: [Elevation.getRotation({elevation}), lib.trig.radiansToDegrees(lib.trig.alpha({p1: sideFootprintLine.from, p2: sideFootprintLine.to}))]});

          var sideLineExistsWithinElevationRect = () => {
            return (_.some(sideFootprintLine, point => {
              return lib.polygon.pointInsidePolygon({point, polygon: elevationFootprint});
            }) || _.some(elevationFootprintLines, eLine => {
              return lib.math.linesIntersect({l1: eLine, l2: sideFootprintLine});
            }));
          }

          var sideIsVisibleInElevation = sideLineExistsWithinElevationRect();


          var sideVisible = sideTheta < 90 || sideTheta > 270;

          if (sideVisible && sideIsVisibleInElevation) {
            var sideSize = _.includes(['left', 'right'], volumeSide) ? volume.dimensions.depth : volume.dimensions.width;
            var visibleSize = Math.cos(lib.trig.degreesToRadians(sideTheta)) * sideSize;
            var sidePositionInElevation = Elevation.getPosition2d({elevation, position3d: {x: sideFootprintLine.from.x, y: volume.position.y, z: sideFootprintLine.from.y}});
            var sidePosition = lib.object.sum(viewOffset, sidePositionInElevation, {y: -volume.dimensions.height});

            //z is max distance from the intersection point of the line and elevation line and the point on the original footprint line
            // var z = _.min([lib.math.minPointDistanceFromLine({line: elevation.lineInRoom, point: sideFootprintLine.from}), lib.math.minPointDistanceFromLine({line: elevation.lineInRoom, point: sideFootprintLine.to})]);

            var maskingPolygons;

            if (lib.math.linesIntersect({l1: elevation.lineInRoom, l2: sideFootprintLine})) {
              var intersectionPoint = lib.math.intersectionPoint({l1: elevation.lineInRoom, l2: sideFootprintLine});

              var maskStartingX = Elevation.getPosition2d({elevation, position3d: {x: intersectionPoint.x, y: volume.position.y, z: intersectionPoint.y}}).x - sidePositionInElevation.x;
              var maskClosingX = sideTheta > 270 ? -100000 : (maskStartingX + 100000);

              maskingPolygons = [[{x: maskStartingX, y: -100000}, {x: maskStartingX, y: 100000}, {x: maskClosingX, y: 100000}, {x: maskClosingX, y: -100000}]];
            }

            angledSideData[volumeSide] = {
              scaleX: visibleSize / sideSize,
              size: {height: size.height, width: sideSize},
              position: sidePosition, z: 1, side: volumeSide,
              maskingPolygons,
            };

          }
          //TODO calculate side position
          //TODO calculate mask
        });

        //TODO scribes
      };
    }

    return (
      <>
        {isNon90 ? (
          (
          _.map(_.orderBy(angledSideData, 'z', ['desc']), (sideData) => {
            var side = sideData.side;
            var overrideSideKey = side === 'section' ? 'left' : side;

            //TODO
            return <CanvasScriptObject
            name={'volume'}
            key={sideData.side}
            resourceData={{resourceKey: 'volume', id: volume.id}}
            script={
              side === 'section' ? `
                rect({isFillable: true, fill: '${fill}'})
              ` : `
              path({closed: true, fill: '${fill}', isFillable: true}, ${JSON.stringify(Volume.getPoints({volume, sideKey: side, viewKey}))})
            `
            }
            position={sideData.position}
            rotation={0}
            metaProps={
              {props: {...volume}, dimensions: volume.dimensions}
            }
            onSelect={this.handleSelect}
            onDelete={this.handleDelete}
            isRotatable={false}
            isDisabled={canvasData.isStatic || selectionData.drawingSelectionBox}
            stroke={_.includes(['schematic'], activeDetailLevel) ? 'rgba(0, 0, 0, 0.5)' : _.includes(['rendering'], activeDetailLevel) ? '#BBBBBB' : 'black'}
            maskingPolygons={sideData.maskingPolygons}
            // {...{...(_.includes(['schematic'], activeDetailLevel) ? {stroke: '#999999', opacity: 0.5} : {stroke: 'black'})}}
            {...{isSelected, multipleEntitiesSelected, scaleX: sideData.scaleX, size: sideData.size, viewKey, locked: true, renderForDrawings}}
          />
          }))
        ) : (
          (viewKey === 'top' || !volume.customData.editAsPolyline) ? (
            <CanvasScriptObject
              name={'volume'}
              resourceData={{resourceKey: 'volume', id: volume.id}}
              script={viewKey === 'front' ? `
                path({closed: true, fill: '${fill}', isFillable: true}, ${JSON.stringify(points)})
              ` :
              `
                rect({width: '100%', height: '100%', fill: '${fill}', isFillable: true, dashed: ${sideKey === 'top' && _.get(volume, 'customData.hideTopFill')}});
              `}
              position={realPosition ? realPosition : position}
              rotation={viewKey === 'top' ? volume.rotation : 0}
              metaProps={
                {props: {...volume}, dimensions: volume.dimensions}
              }
              onSelect={this.handleSelect}
              onTransformEnd={this.handleTransformEnd}
              onTransform={this.handleTransform}
              onDelete={this.handleDelete}
              isRotatable={viewKey === 'top'}
              isDisabled={canvasData.isStatic  || selectionData.drawingSelectionBox || (!isSelected && viewKey === 'top' && _.get(volume, 'customData.isDisabledInPlan'))}
              stroke={_.includes(['schematic'], activeDetailLevel) ? 'rgba(0, 0, 0, 0.5)' : _.includes(['rendering'], activeDetailLevel) ? '#BBBBBB' : 'black'}
              // {...{...(_.includes(['schematic'], activeDetailLevel) ? {stroke: '#999999', opacity: 0.5} : {stroke: 'black'})}}
              {...{isSelected, multipleEntitiesSelected, size, viewKey, locked: volume.customData.isLocked, renderForDrawings}}
            />
          ) : (
            <EditableCanvasPolyline
              stroke={_.includes(['schematic', 'rendering'], activeDetailLevel) ? 'rgba(0, 0, 0, 0.5)' : 'black'}
              isEnabled={sideKey === 'front' && !volume.customData.isLocked}
              isLocked={volume.customData.isLocked || sideKey !== 'front' || (sideKey === 'front' && Volume.getIsCutoffInElevation({elevation, volume, sideKey}))}
              isDraggable={!(volume.customData.isLocked || sideKey !== 'front')}
              onPointsChange={this.handlePointsChange({sideKey, volume})}
              onAddPoint={this.handleAddOrDeletePoint({sideKey, volume})}
              onDelete={this.handleDelete}
              onDragEnd={this.handleDragEnd}
              onDeletePoint={this.handleAddOrDeletePoint({sideKey, volume})}
              onPointDragMove={this.handlePointDragMove({sideKey, volume})}
              onPointDragEnd={this.handlePointDragEnd({sideKey, volume})}
              onSelect={this.handleSelect}
              closed
              //TODO handles for point changes and polyline movement
              {...{position, points, isSelected, fill}}
            />
          )
        )}
      </>
    );
  }
}

function CanvasVolumeWithContext(props) {
  const canvasData = useContext(CanvasDataContext);
  let selectionData = useContext(CanvasSelectionContext);
  const projectData = useContext(ProjectDataContext);

  var isSelected = props.volume && _.size(selectionData) ? selectionData.getIsActiveEntity({resourceKey: 'volume', id: props.volume.id}) : false;
  var multipleEntitiesSelected = _.size(selectionData) ? selectionData.activeEntities.length > 1 : false;

  selectionData = _.omit(selectionData, ['activeEntities', 'activeDimensionData', 'activeDatumData']);

  return <CanvasVolume {...props} {...{isSelected, multipleEntitiesSelected, canvasData: _.pick(canvasData, ['isStatic', 'isShifting']), selectionData, projectData}}/>;
}

export default withErrorBoundary(connect({
  mapState: (state, ownProps) => {
    var {volume, elevation, viewKey} = ownProps;
    var props = {};

    if (ownProps.id) {
      volume = props.volume = state.resources.volumes.byId[ownProps.id];
    }

    if (volume) {
      var {room} = Volume.get(['room',], {volume, state});

      props = {...props, room};
    }

    return props;
  },
  mapDispatch: {
    ..._.pick(resourceActions.scopes, ['trackScopes', 'updateScope']),
    ..._.pick(resourceActions.elevations, ['createElevations', 'updateElevations', 'destroyElevations']),
    ..._.pick(resourceActions.volumes, ['trackVolumes', 'createVolumes', 'updateVolume', 'updateVolumes', 'destroyVolume', 'destroyVolumes', 'modifyVolumes']),
    ..._.pick(resourceActions.products, ['trackProducts', 'updateProduct', 'updateProducts', 'createProducts', 'destroyProducts', 'modifyProducts']),
    ..._.pick(resourceActions.containers, ['trackContainers', 'createContainers', 'updateContainer', 'updateContainers', 'destroyContainer', 'destroyContainers', 'modifyContainers']),
    ...resourceActions.elevations,
  }
})(CanvasVolumeWithContext), {
  FallbackComponent: CanvasErrorFallback,
  onError: (error, info) => global.handleError({error, info, message: 'CanvasVolume'})
});
