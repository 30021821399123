import React, {useState, useRef} from 'react';
import K from 'k';
import useClickOutside from '../../../hooks/click-outside';

import Popup from '../../../components/popup';
import Checkbox from '../../../components/checkbox';

const SetScalePopup = ({onClose, page, onUpdatePageData, onUpdateAllPages}) => {
  const [scaleAllPages, setScaleAllPages] = useState(false);
  const [scale, setScale] = useState(page.scale);
  const [hoveredKey, setHoveredKey] = useState(null);
  const scalePopupContentRef = useRef(null);

  useClickOutside(scalePopupContentRef, () => {
    onClose();
  });

  const styles = {
    popup: {
      display: 'flex',
      flexDirection: 'column',
      borderRadius: 3,
      padding: 0
    },
    button: (key) => ({
      color: K.colors.white,
      background: K.colors.black,
      border: '0px solid transparent',
      ...K.fonts.label,
      height: 20,
      opacity: hoveredKey === key ? 0.7 : 0.9
    }),
    buttonContainer: {marginTop: K.spacing, display: 'flex', gap: K.spacing, justifyContent: 'flex-end'}
  };

  const handleUpdateScale = (event) => {
    let updatedScale = +event.target.value;

    setScale(updatedScale);
  };

  const handleSetScale = () => {
    if (!scaleAllPages) {
      onUpdatePageData({...page, scale});
    }
    else {
      onUpdateAllPages({scale});
    }

    onClose();
  };

  const handleMouseEnter = (key) => () => setHoveredKey(key);
  const handleMouseLeave = () => setHoveredKey(null);

  return (
    <Popup style={styles.popup}>
      <div ref={scalePopupContentRef} style={{backgroundColor: 'white', padding: K.spacing * 2}}>
        <div style={{}}>
          <div style={{marginTop: K.spacing / 2, marginBottom: K.spacing / 2}}>Scale</div>
          <input
            type='number'
            value={scale}
            onChange={handleUpdateScale}
            min={0.01}
          />
        </div>
        <div style={{display: 'flex', flexDirection: 'row', marginTop: K.spacing}}>
          <Checkbox checked={scaleAllPages} onChange={setScaleAllPages} style={{borderRadius: 0}} />
          <div style={{margin: K.spacing}}>Set scale for all pages?</div>
        </div>
        <div style={styles.buttonContainer}>
          <button style={styles.button('cancel')} onClick={onClose} onMouseEnter={handleMouseEnter('cancel')} onMouseLeave={handleMouseLeave}>Cancel</button>
          <button style={styles.button('ok')} onClick={handleSetScale} onMouseEnter={handleMouseEnter('ok')} onMouseLeave={handleMouseLeave}>Ok</button>
        </div>
      </div>
    </Popup>
  );
};

export default SetScalePopup;