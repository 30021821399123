import React, { useContext, Fragment } from 'react';

import _ from 'lodash';
import lib from 'lib';
import Elevation from 'project-helpers/elevation';
import Container from 'project-helpers/container/index';
import Product from 'project-helpers/product';
import Wall from 'project-helpers/wall';
import CanvasProduct from './canvas-product';
import CanvasContainerHelper from 'project-component-helpers/canvas-container-helper';
import ContainerTypesScriptHelpersFor from 'project-helpers/container/container-types-helpers/container-scripts-helpers';
import Room from 'project-helpers/room';

import { handleContainerPropertyChange } from 'properties-view-data/container-properties-view-helpers';
import UpdatesMapsHelpers from 'helpers/updates-maps-helpers';

import {withErrorBoundary} from 'react-error-boundary';

import ProjectDataContext from 'contexts/project-data-context';
import { CanvasScriptObject, CanvasDataContext, CanvasSelectionContext, CanvasSettingsGroup, CanvasErrorFallback } from 'canvas';
import { resourceActions, connect } from 'redux/index.js';
import K from 'k';
import CanvasContainerDetails from 'canvas/canvas-container-details';

class CanvasContainer extends React.Component {
  shouldComponentUpdate(nextProps, nextState) {
    if (!this.props.container) return true;
    if (this.props.container.type === 'countertop') return true;
    var preventUpdate = false;

    const hasContainerChanged = this.difference(nextProps.container, this.props.container).length > 0;

    if (hasContainerChanged || this.props.container.eventType === 'transform') return true;

    if (this.state.isTransforming !== nextState.isTransforming) return true;

    const haveSiblingContainersChanged = _.flatMap(nextProps.siblingContainers, (sibling, index) => this.difference(sibling, this.props.siblingContainers[index])).length > 0;
    const areSiblingContainersTransforming = _.some(this.props.siblingContainers, container => container.eventType === 'transform');

    const haveVolumesChanged = _.flatMap(nextProps.volumes, (volume, index) => this.difference(volume, _.get(this.props, `volumes[${index}]`))).length > 0;
    const areVolumesTransforming = _.some(this.props.volumes, volume => volume.eventType === 'transform');

    if ((areSiblingContainersTransforming || areVolumesTransforming) && this.props.container.type !== 'countertop') return false;

    const hasRoomChanged = this.difference(nextProps.room, this.props.room).length > 0;
    const haveProductsChanged = _.flatMap(nextProps.productIds, (product, index) => this.difference(product, this.props.productIds[index])).length > 0;
    const hasContainerTypeChanged = this.difference(nextProps.containerType, this.props.containerType).length > 0;

    // HINT check that only siblingContainers changed, return false if some of the containers have eventType === 'transform'
    if (
      !(
        hasContainerChanged || hasContainerChanged || hasRoomChanged || haveProductsChanged
      )
      && haveSiblingContainersChanged
    ) {
      preventUpdate = areSiblingContainersTransforming;
    }

    if (
      !(
        hasContainerChanged || hasContainerChanged || hasRoomChanged || haveProductsChanged
      )
      && haveVolumesChanged
    ) {
      preventUpdate = areVolumesTransforming;
    }

    const hasReduxStateUpdated = hasRoomChanged || hasContainerChanged || haveProductsChanged || hasContainerTypeChanged || haveSiblingContainersChanged || haveVolumesChanged;
    const haveNonReduxPropsUpdated = !_.isEqual(_.omit(nextProps, ['room', 'container', 'productIds', 'containerType', 'siblingContainers', 'volumes']), _.omit(this.props, ['room', 'container', 'productIds', 'containerType', 'siblingContainers', 'volumes']));

    return (haveNonReduxPropsUpdated || hasReduxStateUpdated) && !preventUpdate;
  }

  difference = (obj1, obj2) => {
    if (!obj2) return [undefined];

    const diffProperties = _.reduce(obj1, (result, value, key) => {
      return _.isEqual(value, obj2[key]) ?
        result : result.concat(key);
    }, []);

    return diffProperties || [];
  };

  state = {
    isTransforming: false
  };

  handleSelect = () => {
    const {container, selectionData, canvasData, isSelected, viewKey} = this.props;
    const {setActiveEntities} = selectionData;
    const {isShifting} = canvasData;

    //HINT currently only allowing multi-select in top view
    if (isShifting && viewKey === 'top') {
      setActiveEntities({entities: [{resourceKey: 'container', id: container.id}], isMultiSelect: true});
    }
    else if (!isSelected) {
      setActiveEntities({entities: [{resourceKey: 'container', id: container.id}], isMultiSelect: false});
    }
  };

  handleDelete = () => {
    var {container, projectData, selectionData} = this.props;

    selectionData.onDeselect();

    Container.destroy({container, resourceActions: this.props, pushToUndoQueue: projectData.pushToUndoQueue});
  };

  handleTransform = (transformerProps) => {
    if (!this.state.isTransforming) {
      this.setState({cachedContainer: this.props.container, isTransforming: true});
    }

    var {container, viewKey, elevation, room, viewOffset, overridePosition, isNonSpacial, nonSpacialSideKey} = this.props;

    const updatedProps = Container.getUpdatedPropsForTransformerProps({container, transformerProps, viewKey, elevation, room, viewOffset, overridePosition, isNonSpacial, nonSpacialSideKey});

    var updatesMap = Container.getDependentProductUpdates({container: {...container, ...updatedProps}, oldContainer: container, actionKey: 'transform'});

    updatesMap.containers = {updates: [
      {where: {id: container.id}, props: {...updatedProps, eventType: 'transform'}}
    ]};

    UpdatesMapsHelpers.makeReduxUpdatesFor({updatesMap, resourceActions: this.props, hitApi: false});

    // this.props.updateContainer({id: container.id, props: {...updatedProps, eventType: 'transform'}, hitApi: false});
  };

  handleTransformEnd = (transformerProps) => {
    var cachedContainer;
    var {container, viewKey, elevation, room, viewOffset, projectData, overridePosition, isNonSpacial, nonSpacialSideKey} = this.props;

    container = _.omit(container, ['eventType']);

    if (transformerProps) {
      cachedContainer = _.cloneDeep(container);

      //TODO round unless project is using infiniteprecision
      let updatedProps = Container.getUpdatedPropsForTransformerProps({container, transformerProps, viewKey, elevation, room, viewOffset, roundToMinPrecision: !projectData.infinitePrecision, overridePosition, isNonSpacial, nonSpacialSideKey});

      container = {...container, ...updatedProps};
    }
    else {
      cachedContainer = this.state.cachedContainer;
    }

    Container.update({cachedContainer, container, props: container, resourceActions: this.props, pushToUndoQueue: projectData.pushToUndoQueue});

    this.setState({isTransforming: false, cachedContainer: undefined});
  };

  handleOnSettingsChange = ({key, value}) => {
    var {container} = this.props

    handleContainerPropertyChange({activeEntityId: container.id, activeEntity: container, path: key, value, resourceActions: this.props})
  }

  render() {
    var {activeDetailLevel, activeFillMode, activeUserLense, viewKey, viewMode, elevation, container, realPosition, room, productIds, viewOffset, selectionData, renderForContextCanvas, showWallsAndArchElements, multipleEntitiesSelected,
      canvasData, showCanvasSettings, showProductDetails, containerType, showRevealSymbols, showUnitNumbers, showGrainFlow, showProjections, showUnitLabels, projectData, isSelected,
      renderForDrawings, showOrnamentTopIndicators, scaleX=1, scaleY=1, scaleOffset, showPerspective, overridePosition, isNonSpacial, nonSpacialSideKey
    } = this.props;
    if (!container) return null;

    const areSiblingContainersTransforming = _.some(this.props.siblingContainers, container => container.eventType === 'transform');
    const isManaged = !container.customData.inManualMode && !!container.customData.managingContainerId;

    if (container.type === 'countertop' && isManaged && areSiblingContainersTransforming && !isSelected) return null;
    if (container.type === 'countertop' && isManaged && Room.waitingForUpdateManagedResources) return null;

    var {countertopsAreSelectable, companyKey, isEmployee} = projectData;
    var sideKey = isNonSpacial ? nonSpacialSideKey : Container.getSideKey({container, elevation, viewKey});
    var position, size, childrenDropzone;
    var snapToLines = isNonSpacial ? [] : _.map(Container.getSnapToLines({container, elevation, room, viewKey}), line => _.mapValues(line, value => lib.object.sum(value, viewOffset)));
    var {snapToFloor} = containerType;
    var disabledAnchors = [];
    var isScalable = !containerType.isOrnament;
    var isRotatable = !isNonSpacial && viewKey === 'top';
    const fill = Container.getFill({container, elevation, activeDetailLevel, activeFillMode, countertopsAreSelectable});
    var hatchFill, hatchFills, hatchFillData;
    if (!renderForContextCanvas) {
      var hatchFillData = Container.getHatchFillData({container, viewKey, activeFillMode, activeDetailLevel});
      var {hatchFill, hatchFills} = hatchFillData;
    }
    const isInvalid = Container.getIsInvalid({container, elevation});

    if (realPosition) position = realPosition;
    if (overridePosition) position = overridePosition;

    size = {width: container.dimensions[K.sideSizeMap[sideKey].width], height: container.dimensions[K.sideSizeMap[sideKey].height]};

    if (viewKey === 'top') {
      if (!realPosition) position = lib.object.sum(room.plan.position, viewOffset, scaleOffset, {x: container.position.x, y: container.position.z});
    }
    else {
      if (!realPosition) position = lib.object.sum(viewOffset, scaleOffset, Container.getPositionInElevation({container, elevation, isNonSpacial, nonSpacialSideKey, overridePosition}));

      if (!container.customData.preventSnapToFloor && snapToFloor) disabledAnchors = ['bottom-center'];

      childrenDropzone = {position, size};
    }

    var script = Container.getScript({container, elevation, viewKey, isNonSpacial, nonSpacialSideKey});

    if (showProjections && viewKey === 'front' && sideKey === 'front') {
      var {hasProjection, projectionY} = Container.getProjectionData({container, elevation});
    }

    var constraints, inCanvasConstraints;

    if (!(container.type === 'countertop' && container.customData.isByOthers) && !container.customData.hasNonStandardDimensions) {
      constraints = containerType.constraintsFor({container});

      inCanvasConstraints = {width: constraints[K.sideSizeMap[sideKey].width], height: constraints[K.sideSizeMap[sideKey].height]};

      // if (sideKey === 'top' && _.includes([90, 270], container.rotation)) {
      //   const heightConstraint = {...inCanvasConstraints.height};

      //   inCanvasConstraints.height = inCanvasConstraints.width;
      //   inCanvasConstraints.width = heightConstraint;
      // }
    }

    var angledSideData = {};
    var isNon90 = false;

    if (viewKey === 'front' && !isNonSpacial) {
      var theta = lib.round(Container.getElevationTheta({container, elevation}), {toNearest: 1});

      if (!_.includes([0, 90, 180, 270, 360], theta)) {
        isNon90 = true;
        isScalable = false;
        var containerFootprint = Container.getFootprintInRoom({container});
        var containerFootprintLines = Container.getFootprintLines({container});
        var elevationFootprint = Elevation.getFootprintInRoom({elevation});
        var elevationFootprintLines = Elevation.getFootprintLinesInRoom({elevation});

        var intersectedFootprintLines = _.filter(containerFootprintLines, footprintLine => {
          return lib.math.linesIntersect({l1: elevation.lineInRoom, l2: footprintLine});
        });

        if (intersectedFootprintLines.length > 0) {
          var intersectionPoint1 = lib.math.intersectionPoint({l1: elevation.lineInRoom, l2: intersectedFootprintLines[0]});
          var intersectionPoint2;

          if (intersectedFootprintLines.length > 1) {
            intersectionPoint2 = lib.math.intersectionPoint({l1: elevation.lineInRoom, l2: intersectedFootprintLines[1]});
          }
          else {
            intersectionPoint2 = _.find(elevation.lineInRoom, elevationLinePoint => {
              return lib.polygon.pointInsidePolygon({point: elevationLinePoint, polygon: containerFootprint});
            });
          }

          if (intersectionPoint2) {
            var sectionSize = lib.trig.distance({fromPoint: intersectionPoint1, toPoint: intersectionPoint2});

            var sectionScaleX = 1;

            var sectionPosition = _.minBy([Elevation.getPosition2d({elevation, position3d: {x: intersectionPoint1.x, y: container.position.y, z: intersectionPoint1.y}}), Elevation.getPosition2d({elevation, position3d: {x: intersectionPoint2.x, y: container.position.y, z: intersectionPoint2.y}})], 'x');

            angledSideData = {section: {side: 'section', z: 0, scaleX: sectionScaleX, size: {height: size.height, width: sectionSize}, position: lib.object.sum(viewOffset, {x: sectionPosition.x, y: sectionPosition.y - container.dimensions.height})}};
          }
        }

        _.forEach(['left', 'right', 'front', 'back'], containerSide => {
          var sideFootprintLine = containerFootprintLines[containerSide];
          var sideRotationMap = {left: 90, right: 270, front: 0, back: 180};

          var sideTheta = lib.math.trig.theta({degrees: [Elevation.getRotation({elevation}), lib.trig.radiansToDegrees(lib.trig.alpha({p1: sideFootprintLine.from, p2: sideFootprintLine.to}))]});

          var sideLineExistsWithinElevationRect = () => {
            return (_.some(sideFootprintLine, point => {
              return lib.polygon.pointInsidePolygon({point, polygon: elevationFootprint});
            }) || _.some(elevationFootprintLines, eLine => {
              return lib.math.linesIntersect({l1: eLine, l2: sideFootprintLine});
            }));
          }

          var sideVisible = sideTheta < 90 || sideTheta > 270;
          var sideIsVisibleInElevation = sideLineExistsWithinElevationRect();

          if (sideVisible && sideIsVisibleInElevation) {
            var sideSize = _.includes(['left', 'right'], containerSide) ? container.dimensions.depth : container.dimensions.width;
            //TODO determine whether to use sin or cos
            var visibleSize = Math.cos(lib.trig.degreesToRadians(sideTheta)) * sideSize;
            var sidePositionInElevation = Elevation.getPosition2d({elevation, position3d: {x: sideFootprintLine.from.x, y: container.position.y, z: sideFootprintLine.from.y}});
            var sidePosition = lib.object.sum(viewOffset, scaleOffset, sidePositionInElevation, {y: -container.dimensions.height});

            // var z = _.min([lib.math.minPointDistanceFromLine({line: elevation.lineInRoom, point: sideFootprintLine.from}), lib.math.minPointDistanceFromLine({line: elevation.lineInRoom, point: sideFootprintLine.to})]);

            var maskingPolygons;

            if (lib.math.linesIntersect({l1: elevation.lineInRoom, l2: sideFootprintLine})) {
              var intersectionPoint = lib.math.intersectionPoint({l1: elevation.lineInRoom, l2: sideFootprintLine});

              var maskStartingX = Elevation.getPosition2d({elevation, position3d: {x: intersectionPoint.x, y: container.position.y, z: intersectionPoint.y}}).x - sidePositionInElevation.x;
              var maskClosingX = sideTheta > 270 ? -100000 : (maskStartingX + 100000);

              maskingPolygons = [[{x: maskStartingX, y: -100000}, {x: maskStartingX, y: 100000}, {x: maskClosingX, y: 100000}, {x: maskClosingX, y: -100000}]];
            }

            angledSideData[containerSide] = {
              scaleX: visibleSize / sideSize,
              size: {height: size.height, width: sideSize}, sidePositionInElevation,
              position: sidePosition, z: 1, side: containerSide, maskingPolygons
            };

          }
          //TODO calculate side position
          //TODO calculate mask
        });

        //TODO scribes
      };
    }

    var stroke = (isInvalid && !renderForDrawings) ? 'red' : 'black';

    if (_.includes(['schematic'], activeDetailLevel)) stroke = '#999999'
    else if (_.includes(['rendering'], activeDetailLevel)) stroke = 'rgba(0, 0, 0, 0.3)'

    var addableFromTopOrnaments = ['scaleFigure7', 'scaleFigure8', 'scaleFigure9', 'propCouch1', 'propCouch2'];
    var isHiddenOrnament = false;

    if (_.get(containerType, 'isOrnament', false)
        && !showOrnamentTopIndicators
        && _.includes(['top'], viewKey)
        && !_.includes(addableFromTopOrnaments, container.type)
    ) {
      isHiddenOrnament = true;
    }

    var shouldHideProducts = viewKey === 'front' && _.includes(['right', 'left'], sideKey) && _.includes(['base', 'baseWithChase', 'tall', 'floatingBase'], container.type) && !Container.getIsSection({container, elevation}) && _.get(container, `customData.wrap.${sideKey}`, false);

    var opacity = _.get(containerType, 'isOrnament') ? (container.customData.ornamentOpacity || 1) : 1;
    var strokeWidth = renderForDrawings ? 0.25 : 0.5;

    if (_.get(containerType, 'isOrnament') && container.customData.ornamentStrokeWidth) {
      strokeWidth = container.customData.ornamentStrokeWidth || strokeWidth;
    }

    return (
      <>
        {isNon90 ? (
          !isHiddenOrnament && (
          _.map(_.orderBy(angledSideData, 'z', ['desc']), (sideData) => {
            var {side} = sideData;
            var overrideSideKey = side === 'section' ? 'left' : side;
            //TODO
            return <Fragment key={`${container.id}-${side}`}>
              <CanvasScriptObject
                name={'container'}
                resourceData={{resourceKey: 'container', id: container.id}}
                constraints={inCanvasConstraints}//unneeded
                script={
                  side === 'section' ? `
                    rect({isFillable: true, fill: 'rgb(120, 120, 120)'})
                  ` : Container.getScript({container, elevation, viewKey, isNonSpacial: true, nonSpacialSideKey: overrideSideKey})
                }
                rotation={0}
                fill = {!renderForContextCanvas ? fill : ''}
                metaProps={
                  {props: {...container}, ...CanvasContainerHelper.getMetaProps({container, elevation, viewKey, showUnitNumbers, showGrainFlow, activeFillMode, isSelected, activeDetailLevel, renderForDrawings, hatchFillData, isNonSpacial: true, nonSpacialSideKey: overrideSideKey, countertopsAreSelectable})}
                }
                isDisabled={canvasData.isStatic || selectionData.drawingSelectionBox || (!isSelected && viewKey === 'top' && container.type === 'countertop' && !countertopsAreSelectable) || (viewKey === 'front' && !isNonSpacial && container.customData.panelsAreDisabled)}
                onSelect={this.handleSelect}
                onDelete={this.handleDelete}
                isDraggable={false}
                zIndex={isNonSpacial ? 0 : Container.getZIndex({container, elevation, room, viewKey})}//todo calculate
                hideText={activeDetailLevel === 'rendering'}
                {...{stroke, strokeWidth, opacity, maskingPolygons: sideData.maskingPolygons, position: sideData.position, size: sideData.size, scaleX: sideData.scaleX, scaleY: 1, scaleOffset, viewKey, disabledAnchors, snapToLines, locked: true, isRotatable: false, isScalable: false, hatchFill, hatchFills, renderForDrawings}}
              />
              {side !== 'section' && _.map(productIds, productId => (
                <CanvasProduct
                  key={`${side}-${productId}`}
                  id={productId}
                  isNonSpacial={true}
                  nonSpacialContainerPosition={sideData.sidePositionInElevation}
                  maskPosition={sideData.position}
                  maskingPolygons={sideData.maskingPolygons}
                  {...{overrideSideKey, viewKey, viewOffset, elevation, room, showPerspective, scaleX: sideData.scaleX, scaleY: 1, scaleOffset, showRevealSymbols, showUnitNumbers, showGrainFlow, showUnitLabels, activeDetailLevel, activeFillMode, activeUserLense, renderForDrawings, showCanvasSettings, showProductDetails}}
                  {..._.pick(this.props, ['canvasDeps'])}
                />
              ))}
              </Fragment>
            }))
        ) : (
          !isHiddenOrnament && (<CanvasScriptObject
            name={'container'}
            resourceData={{resourceKey: 'container', id: container.id}}
            constraints={inCanvasConstraints}
            script={script}
            rotation={viewKey === 'top' ? container.rotation : 0}
            fill = {!renderForContextCanvas ? fill : ''}
            // stroke={(isInvalid && !renderForDrawings) ? 'red' : '#333333'}
            // {...{...(_.includes(['schematic'], activeDetailLevel) ? {stroke: '#999999'} : {stroke: (isInvalid && !renderForDrawings) ? 'red' : 'black'})}}
            metaProps={
              {props: {...container}, ...CanvasContainerHelper.getMetaProps({container, elevation, viewKey, showUnitNumbers, showGrainFlow, activeFillMode, isSelected, activeDetailLevel, renderForDrawings, hatchFillData, isNonSpacial, nonSpacialSideKey, countertopsAreSelectable})}
            }
            isDisabled={canvasData.isStatic || selectionData.drawingSelectionBox || (!isSelected && viewKey === 'top' && container.type === 'countertop' && !countertopsAreSelectable) || (viewKey === 'front' && !isNonSpacial && container.customData.panelsAreDisabled)}
            onSelect={this.handleSelect}
            onTransformEnd={this.handleTransformEnd}
            onTransform={this.handleTransform}
            onDelete={this.handleDelete}
            isDraggable={!isNonSpacial}
            zIndex={isNonSpacial ? 0 : Container.getZIndex({container, elevation, room, viewKey})}
            hideText={activeDetailLevel === 'rendering'}
            {...{stroke, strokeWidth, opacity, position, size, isSelected, multipleEntitiesSelected, scaleX, scaleY, scaleOffset, viewKey, disabledAnchors, snapToLines, locked: container.customData.isLocked, isRotatable, isScalable, hatchFill, hatchFills, renderForDrawings}}
          />)
        )}

        {hasProjection && (
          <CanvasScriptObject
            name={'container'}
            script={script}
            fill = {!renderForContextCanvas ? fill : ''}
            metaProps={
              {props: {...container}, ...CanvasContainerHelper.getMetaProps({container, elevation, viewKey, showUnitNumbers, showGrainFlow, activeFillMode, isSelected, isProjection: true, activeDetailLevel, renderForDrawings, hatchFillData, isNonSpacial, nonSpacialSideKey})}
            }
            isDisabled={true}
            {...{position: {...position, y: position.y + projectionY - Container.getPositionInElevation({container, elevation}).y}, size, viewKey, hatchFill, hatchFills, renderForDrawings}}
          />
        )}
        {!renderForContextCanvas && !shouldHideProducts && !isNon90 && //(_.includes(['top', 'front'], sideKey) || (isSection || !_.includes(['base', 'tall'], container.type))) &&
          _.map(productIds, productId => (
            <CanvasProduct
              key={productId}
              id={productId}
              {...(realPosition ? {containerRealPosition: position} : {})}
              {...(isNonSpacial ? {overrideSideKey: nonSpacialSideKey, nonSpacialContainerPosition: overridePosition, isNonSpacial} : {})}
              {...{viewKey, viewOffset, elevation, room, showPerspective, scaleX, scaleY, scaleOffset, showRevealSymbols, showUnitNumbers, showGrainFlow, showUnitLabels, activeDetailLevel, activeFillMode, activeUserLense, renderForDrawings, showCanvasSettings, showProductDetails}}
              {..._.pick(this.props, ['canvasDeps'])}
            />
          )
        )}
        {hasProjection && activeDetailLevel === 'production' && _.map(productIds, productId => (
          <CanvasProduct
            key={productId}
            id={productId}
            isProjection={true}
            {...{viewKey, viewOffset, elevation, room, showRevealSymbols, showUnitNumbers, showGrainFlow, showUnitLabels, projectionY, activeDetailLevel, activeFillMode, activeUserLense, renderForDrawings, showCanvasSettings}}
            {..._.pick(this.props, ['canvasDeps'])}
          />
        ))}
        {sideKey === 'front' && container.type === 'vanity' && (
          <CanvasScriptObject
            script={`
            var {hasWoodDrawers, hasExpressedBox, expressedBoxThickness=0.75} = _.props.customData;
            var children = [];

            if (hasWoodDrawers) {
              children.push(text({text: 'WD', top: '100% - 3', left: '100% - 3', fontSize: 11/3, opacity: 0.5, origin: {x: 'right', y: 'bottom'}}));
            }

            group({}, [
              ...children,
              ${ContainerTypesScriptHelpersFor({companyKey, isEmployee}).script.productLabelFor({key: 'vanity', hasShopDrawing: container.customData.hasShopDrawing})}
            ]);`}
            metaProps={
              {props: {...container}, ...CanvasContainerHelper.getMetaProps({container, elevation, viewKey, showUnitNumbers, showGrainFlow, isSelected, activeDetailLevel, renderForDrawings, isNonSpacial, nonSpacialSideKey})}
            }
            isDisabled={true}
            {...{position, size, viewKey, renderForDrawings}}
          />
        )}
        {showCanvasSettings && sideKey === 'front' && (
          <CanvasSettingsGroup
            position={position}
            size={size}
            resourceKey='container'
            resourceId={container.id}
            resource={container}
            onSettingsChange={this.handleOnSettingsChange}
          />
        )}
        {viewMode === 'lite' && showProductDetails && <CanvasContainerDetails {...{container, position, viewMode}}/>}
      </>
    );
  }
}

function CanvasContainerWithContext(props) {
  var canvasData = useContext(CanvasDataContext);
  let selectionData = useContext(CanvasSelectionContext);
  const projectData = useContext(ProjectDataContext);

  var isSelected = props.container && _.size(selectionData) ? selectionData.getIsActiveEntity({id: props.container.id, resourceKey: 'container'}) : false;
  var multipleEntitiesSelected = _.size(selectionData) ? selectionData.activeEntities.length > 1 : false;

  selectionData = _.omit(selectionData, ['activeEntities', 'activeDimensionData', 'activeDatumData']);

  return <CanvasContainer {...props} {...{isSelected, multipleEntitiesSelected, canvasData: _.pick(canvasData, ['isStatic', 'isShifting']), selectionData, projectData}}/>;
}

export default withErrorBoundary(connect({
  mapState: (state, ownProps) => {
    var {container, elevation, room, viewKey, overrideSideKey, isNonSpacial} = ownProps;
    var props = {};

    if (ownProps.id) {
      container = props.container = state.resources.containers.byId[ownProps.id];
    }

    if (container && container.id) {
      var {products, containerType, siblings, volumes} = Container.get(['products', 'containerType', 'siblings', 'volumes'], {container, state});

      products = products || {};

      var filteredOrderedProducts = _.orderBy(
        _.filter(products, product => {
          return _.includes([undefined, 'autofilledStorage'], _.get(product, 'managedData.managedKey'));
        }), product => {
          return Product.getZIndex({
            product, elevation, viewKey, container, room, isNonSpacial,
            sideKey: isNonSpacial ? overrideSideKey : Container.getSideKey({container, elevation, viewKey}),
          });
        }
      );

      props = {...props, room, productIds: _.map(filteredOrderedProducts, 'id'), containerType, siblingContainers: siblings, volumes};
    }
    else if (container) {
      props.containerType = Container.get('containerType', {container});
    }

    return props;
  },
  mapDispatch: {
    ..._.pick(resourceActions.scopes, ['trackScopes', 'updateScope']),
    ..._.pick(resourceActions.containers, ['trackContainers', 'createContainers', 'updateContainer', 'updateContainers', 'destroyContainer', 'destroyContainers', 'modifyContainers']),
    ...resourceActions.elevations,
    ..._.pick(resourceActions.parts, ['updateParts']),
    ..._.pick(resourceActions.products, ['trackProducts', 'updateProduct', 'updateProducts', 'createProducts', 'destroyProducts', 'modifyProducts']),
    ..._.pick(resourceActions.productOptions, ['createProductOptions', 'destroyProductOptions', 'modifyProductOptions']),
  }
})(CanvasContainerWithContext), {
  FallbackComponent: CanvasErrorFallback,
  onError: (error, info) => global.handleError({error, info, message: 'CanvasContainer'})
});
