import _ from 'lodash';
import lib from 'lib';
import K from 'k';
import DetailsHelper from 'helpers/details-helper';
import getProductsByCategoryFor from 'helpers/product-categories-helper';
import UpdatesMapsHelpers from 'helpers/updates-maps-helpers';

import Product from 'project-helpers/product';
import Container from 'project-helpers/container';
import Room from 'project-helpers/room';

function getCompatibleProductOptionsFieldSets({product, includeAutomanagedOptions}) {
  var productOptionFieldSets = [];
  return productOptionFieldSets;
}

function getProductFieldGroups ({product, elevation, viewKey, viewMode, activeUserLense, activeDetailLevel}) {
  var fieldSetGroups = [
    {title: 'Dimensions & Product Specifics', properties: []},
    {title: 'Appliance Settings', properties: []},
    // {title: 'Product Specifics', properties: []},
    {title: 'Product Options', properties: []},
    {title: 'Materials', properties: []},
    {title: 'Advanced Material Options', properties: []},
    {title: 'Pulls', properties: []},
    {title: 'Advanced Pull Options', properties: []},
    {title: 'Reveals', properties: []},
    {title: 'Fly Bys', properties: []},
    {title: 'Other', properties: []}
  ];

  var dimensionsAndSpecificsGroupIndex = _.findIndex(fieldSetGroups, {title: 'Dimensions & Product Specifics'});
  var applianceSettingsGroupIndex = _.findIndex(fieldSetGroups, {title: 'Appliance Settings'});
  var revealsGroupIndex = _.findIndex(fieldSetGroups, {title: 'Reveals'});
  var flyBysGroupIndex = _.findIndex(fieldSetGroups, {title: 'Fly Bys'});
  var productOptionsGroupIndex = _.findIndex(fieldSetGroups, {title: 'Product Options'});
  var materialsGroupIndex = _.findIndex(fieldSetGroups, {title: 'Materials'});
  var additionalMaterialsGroupIndex = _.findIndex(fieldSetGroups, {title: 'Advanced Material Options'});
  var pullsGroupIndex = _.findIndex(fieldSetGroups, {title: 'Pulls'});
  var additionalPullsGroupIndex = _.findIndex(fieldSetGroups, {title: 'Advanced Pull Options'});
  var otherGroupIndex = _.findIndex(fieldSetGroups, {title: 'Other'});

  const {productType, companyKey, isEmployee, container, parentProduct, productOptionInstances, room, appliances, dependencies, project} = Product.get(['productType', 'companyKey', 'isEmployee', 'container', 'parentProduct', 'productOptionInstances', 'dependencies', 'project', 'appliances', 'room'], {product});
  const type = Product.getType({product});
  const applianceInstancesData = Product.getApplianceInstancesData({product});
  const hasSink = Product.getHasSink({product});

  var dimensionsFieldSets = DetailsHelper.getDimensionsEditablePropFieldSets({product});

  if (dimensionsFieldSets.length > 0) fieldSetGroups[dimensionsAndSpecificsGroupIndex].properties.push(...dimensionsFieldSets);

  //HINT fluted sculpted panel
  if (_.includes([1460], productType.id)) {
    fieldSetGroups[dimensionsAndSpecificsGroupIndex].properties.unshift([
      {
        key: 'customData.isFlutedAdjacent',
        type: 'checkbox',
        title: 'Is Fluted Adjacent Panel',
        views: ['front'],
        userLenses: ['sales', 'design', 'engineering']
      }
    ]);
  }

  // if (_.includes([1132, 1133, 759, 962, 760, 764, 765], product.productId) && product.dimensions.width === 27) {
  //   fieldSetGroups[dimensionsAndSpecificsGroupIndex].properties.push({
  //     key: 'customData.upperLeafDoorType',
  //     type: 'select',
  //     title: 'Leaf Door Type',
  //     options: [{value: 'single', title: 'Single'}, {value: 'double', title: 'Double'}],
  //     views: ['front']
  //   });
  // }

  if (_.includes([1479], product.productId)) {
    fieldSetGroups[dimensionsAndSpecificsGroupIndex].properties.push({
      key: 'customData.notchedPanelFrontExposed',
      type: 'checkbox',
      title: 'Front Exposed',
      views: ['front']
    });
  }

  //HINT medicine cabinet has different options for front type
  if (_.includes([405], product.productId)) {
    fieldSetGroups[dimensionsAndSpecificsGroupIndex].properties.push({
      key: 'customData.medicineCabinetFrontType',
      type: 'select',
      title: 'Front Type',
      options: [{value: 'framedMirror', title: 'Framed Mirror'}, {value: 'mirror', title: 'Mirror'}, {value: 'solid', title: 'Solid Panel'}],
      views: ['front']
    });
  }

  if (_.includes([1589, 1630, 1635, 34, 36, 35, 84, 83], productType.id)) {
    fieldSetGroups[dimensionsAndSpecificsGroupIndex].properties.push({
      key: 'customData.hideRedTrashDrawerNote',
      type: 'checkbox',
      title: 'Hide Red Trash Drawer Note',
      views: ['front']
    });
  }
  //HINT all sculpted panels have bottom casing and top railing
  if (_.includes(K.hb.ids.sculptedPanels, productType.id)) {
    fieldSetGroups[dimensionsAndSpecificsGroupIndex].properties.unshift([
      {
        key: 'customData.topRailHeight',
        type: 'number',
        minValue: 0,
        maxValue: 5,
        step: 0.125,
        // options: [{value: 5, title: '5'}, {value: 1.25, title: '1 1/4"'}, {value: 0, title: '0'}],
        title: 'Top Rail Height (0, or a value between 1 1/4" and 5)',
        placeholder: 5,
        views: ['front'],
        userLenses: ['sales', 'design', 'engineering']
      },
      {
        key: 'customData.bottomCasingHeight',
        type: 'size',
        title: 'Bottom Casing Height (min is 5")',
        // minValue: 5,
        maxValue: 8,
        step: 0.125,
        placeholder: 5,
        views: ['front'],
        userLenses: ['sales', 'design', 'engineering']
      }
    ]);
  }

  //HINT sculpted vertical end panels have stiles both sides, either side can be rounded
  if (_.includes([...K.hb.ids.verticalSculptedEndPanels, ...K.hb.ids.horizontalSculptedEndPanels], productType.id)) {
    fieldSetGroups[dimensionsAndSpecificsGroupIndex].properties.push([
      {
        key: 'customData.stileRoundedSides',
        type: 'select',
        title: 'Stile Rounded Sides',
        options: [{value: 'none', title: 'None'}, {value: 'left', title: 'Left'}, {value: 'right', title: 'Right'}, {value: 'both', title: 'Both'}],
        views: ['front'],
        userLenses: ['sales', 'design', 'engineering']
      }
    ]);
  }

  if (_.includes([173], productType.id)) {
    fieldSetGroups[dimensionsAndSpecificsGroupIndex].properties.unshift([
      {
        key: 'customData.hoodShelfHeight',
        type: 'size',
        title: 'Shelf Height',
        views: ['front'],
        minValue: 8,
        maxValue: 18
      }
    ]);
  }

  if (_.includes([186], productType.id)) {
    fieldSetGroups[dimensionsAndSpecificsGroupIndex].properties.unshift([
      {
        key: 'customData.backsplashWCGroupAlignment',
        type: 'radio',
        title: 'Alignment',
        views: ['front'],
        options: [{value: 'centered', title: 'Centered'}, {value: 'justifiedLeft', title: 'Justified Left'}, {value: 'justifiedRight', title: 'Justified Right'}],
        userLenses: ['sales', 'design', 'engineering']
      }
    ]);
  }

  if (_.includes([356, 357, 358, 359], productType.id) || (_.includes([442], productType.id) && _.some(productOptionInstances, productOptionInstance => productOptionInstance.productOptionId === 18))) {
    fieldSetGroups[dimensionsAndSpecificsGroupIndex].properties.unshift([
      {
        key: 'customData.knifeBlockAlignment',
        type: 'radio',
        title: 'Knifeblock Alignment',
        views: ['front'],
        options: [{value: 'right', title: 'Right'}, {value: 'left', title: 'Left'}, ],
        userLenses: ['sales', 'design', 'engineering']
      }
    ]);
  }

  if (_.includes([38, 902], productType.id)) {
    fieldSetGroups[dimensionsAndSpecificsGroupIndex].properties.unshift([
      {
        key: 'customData.accessSide',
        type: 'radio',
        title: 'Access Side',
        views: ['front'],
        options: [{value: 'left', title: 'Left'}, {value: 'right', title: 'Right'}],
        userLenses: ['sales', 'design', 'engineering']
      }
    ]);
  }

  if (_.includes([376, 377, 378, 375], productType.id)) {
    fieldSetGroups[dimensionsAndSpecificsGroupIndex].properties.push({
      key: 'customData.isDashed',
      type: 'checkbox',
      noThumbnail: true,
      title: 'Is Dashed',
      views: ['front'],
    });
  }

  if (_.includes([354, 356, 357, 358, 359, 353, 355, 352, 525, 1102], productType.id)) {
    fieldSetGroups[dimensionsAndSpecificsGroupIndex].properties.push({
      key: 'customData.isDashed',
      type: 'checkbox',
      noThumbnail: true,
      title: 'Flange Dashed',
      views: ['front'],
    });

    //HINT shoe shelf can't be inverted
    if (!_.includes([525], productType.id)) {
      fieldSetGroups[dimensionsAndSpecificsGroupIndex].properties.push({
        key: 'customData.isInverted',
        type: 'checkbox',
        noThumbnail: true,
        title: 'Flange Inverted',
        views: ['front'],
      });
    }
  }

  //HINT HB Shelfbank
  if (_.includes([162, 163, 164, 165, 166, 165, 167], productType.id)) {
    fieldSetGroups[dimensionsAndSpecificsGroupIndex].properties.push(
      {
        key: 'customData.shelfOffsets',
        type: 'text',
        title: 'Shelf Spacings (comma separated, decimal)',
        views: ['front']
      }
    );

    if (productType.id === 162) {
      fieldSetGroups[dimensionsAndSpecificsGroupIndex].properties.push({
        key: 'customData.bottomShelfOffset',
        type: 'number',
        title: 'Bottom Shelf Offset',
        views: ['front']
      });

      if (product.dimensions.depth !== 18.5) {
        fieldSetGroups[dimensionsAndSpecificsGroupIndex].properties.push({
          key: 'customData.hasOnly1Shelf',
          type: 'checkbox',
          title: 'Has Only 1 Shelf',
          views: ['front']
        });
      }
    }
  }

  //HINT HB Bookcases have option to be all fixed shelves
  if (_.includes([119, 174, 37, 85], productType.id)) {
    fieldSetGroups[dimensionsAndSpecificsGroupIndex].properties.push(
      {
        key: 'customData.bookcaseAllFixedShelves',
        type: 'checkbox',
        title: 'All Fixed Shelves',
        views: ['front']
      }
    );

    if (product.customData.bookcaseAllFixedShelves) {
      fieldSetGroups[dimensionsAndSpecificsGroupIndex].properties.push(
        {
          key: 'customData.bookcaseFixedShelfCount',
          type: 'number',
          title: 'Fixed Shelf Count',
          allowEmptyString: true,
          views: ['front']
        }
      );
    }
  }

  if (_.includes([1132, 1133, 759, 760, 764, 765], productType.id)) {
    //TODO constrain displayed options based on available size.
    //Difficult because the model is where most defaults are set
    // var appliancesDisplayData = Product.getAppliancesDisplayData({product});
    // var lowerDrawerHeights = Product.getUnderOvenShelfHeights({product});

    // //TODO default drawer heights and appliances displayData
    // var topBayHeight = product.dimensions.height - _.sum(_.map(appliancesDisplayData, 'dimensions.height')) - _.sum(lowerDrawerHeights);

    var topBayTypeOptions = [];

    // var supportsLeafDoorSingle = product.dimensions.width >= 12 && product.dimensions.width <= 30 && topBayHeight >= 16;
    // var supportsLeafDoorDouble = product.dimensions.width >= 24 && topBayHeight >= 16;
    // var supportsFlipUp = product.dimensions.width >= 15 && topBayHeight >= 10 && topBayHeight <= 24;

    // if (supportsLeafDoorSingle) {
    topBayTypeOptions.push({value: 'leafDoor', title: 'Leaf Door Single'});
    // }
    // if (supportsLeafDoorDouble) {
    //   if (product.dimensions.width > 27) {
    //     topBayTypeOptions.unshift({value: 'leafDoor', title: 'Leaf Door Single'});
    //   }
    //   else {
    topBayTypeOptions.push({value: 'leafDoorDouble', title: 'Leaf Door Double'});
    //   }
    // }
    // if (supportsFlipUp) {
    topBayTypeOptions.push({value: 'flipUp', title: 'Flip Up'});
    // }

    fieldSetGroups[dimensionsAndSpecificsGroupIndex].properties.push({
      key: 'customData.topBayType',
      type: 'select',
      options: topBayTypeOptions,
      title: 'Top Bay Type',
      views: ['front'],
      userLenses: ['sales', 'design', 'engineering']
    });
  }

  if (_.includes([1592, 1598], productType.id)) {
    var topBayHeight = product.customData.topBayHeight || product.dimensions.height - (product.customData.middleBayHeight || 36) - 30;

    var topBayTypeOptions = [];

    if (topBayHeight >= 10 && topBayHeight <= 22) {
      topBayTypeOptions.push({value: 'flipUp', title: 'Single Flip Up'});
    }

    if (topBayHeight >= 16 && topBayHeight <= 31) {
      topBayTypeOptions.push({value: 'leafDoorDouble', title: 'Double Leaf Door'});
    }

    fieldSetGroups[dimensionsAndSpecificsGroupIndex].properties.push({
      key: 'customData.topBayType',
      type: 'select',
      options: topBayTypeOptions,
      title: 'Top Bay Type',
      views: ['front'],
      userLenses: ['sales', 'design', 'engineering']
    },
    {
      key: 'customData.middleBayHeight',
      type: 'size',
      minValue: 36,
      maxValue: 40,
      step: 0.125,
      title: 'Middle Bay Height',
      views: ['front'],
      userLenses: ['sales', 'design', 'engineering']
    },
    {
      key: 'customData.bottomBayType',
      type: 'select',
      options: [
        {value: 'leafDoorDoubleWall', title: 'Double Leaf Door With Shelves'},
        {value: 'twoDrawer', title: 'Two Drawer'},
        {value: 'threeDrawer', title: 'Three Drawer'},
        {value: 'fourDrawer', title: 'Four Drawer'},
      ],
      title: 'Bottom Bay Type',
      views: ['front'],
      userLenses: ['sales', 'design', 'engineering']
    },
    );
  }

  if (companyKey === 'hb') {
    if (_.includes([516], productType.id)) {
      fieldSetGroups[dimensionsAndSpecificsGroupIndex].properties.push({
        key: 'size',
        type: 'size',
        minValue: 0,
        step: 0.01,
        title: 'Price',
        views: ['front'],
        userLenses: ['sales', 'design', 'engineering']
      });

      fieldSetGroups[dimensionsAndSpecificsGroupIndex].properties.push({
        key: 'customTitle',
        title: 'Product Title (used for proposal)',
        type: 'text',
        isMultiline: true,
        views: ['front'],
        userLenses: ['sales', 'design', 'engineering']
      });

      fieldSetGroups[dimensionsAndSpecificsGroupIndex].properties.push({
        key: 'customData.frontLabel',
        title: 'Label',
        type: 'text',
        isMultiline: true,
        views: ['front'],
        userLenses: ['sales', 'design', 'engineering']
      });

      fieldSetGroups[dimensionsAndSpecificsGroupIndex].properties.push({
        key: 'customData.planLabel',
        title: 'Plan Label',
        type: 'text',
        isMultiline: true,
        views: ['top']
      });
    }
  }
  if (type === 'horizontalBarblock' && container.type !== 'rearFacingBarblock') {
    fieldSetGroups[dimensionsAndSpecificsGroupIndex].properties.push({
      key: 'customData.countertopThickness',
      type: 'number',
      noThumbnail: true,
      title: 'Countertop Thickness',
      minValue: 0.375,
      maxValue: 2.5,
      step: 1 / 16,
      views: ['front'],
      userLenses: ['sales', 'design', 'engineering']
    });
  }

  if (container.type !== 'daylightIsland') {
    fieldSetGroups[dimensionsAndSpecificsGroupIndex].properties.push(
      [
        {key: 'position.z', title: 'Z', type: 'size'},
      ],
    );
  }

  //< product options
  var productFieldSet = [];

  _.times((productType.bayCount || 0) - 1, index => {
    productFieldSet.push({
      key: `customData.bayWidths.${index}`,
      type: 'size',
      title: `Bay ${index + 1} Width${productType.id === 1586 ? ' (Bays must be 21-42" wide)' : ''}`,
      views: ['front'],
      //HINT constrain xl cooktop bays
      ...(productType.id === 1586 ? {
        minValue: 21, maxValue: 42, step: 0.125
      } : {})
    });
  });

  if (productType.hasDoorActionOption) {
    productFieldSet.unshift({
      key: 'customData.doorAction',
      type: 'radio',
      title: 'Door Swings',
      options: [{value: 'left', title: 'Left'}, {value: 'right', title: 'Right'}],
      userLenses: ['sales', 'design', 'engineering']
    });
  }

  if (productType.hasOrientationOption) {//TODO corner units
    productFieldSet.unshift({
      key: 'customData.orientation',
      type: 'radio',
      title: 'Orientation',
      options: [{value: 'left', title: 'Left'}, {value: 'right', title: 'Right'}],
      userLenses: ['sales', 'design', 'engineering']
    });
  }

  if (productFieldSet.length > 0) fieldSetGroups[dimensionsAndSpecificsGroupIndex].properties.push(productFieldSet);
  //> product options

  if (container.type !== 'daylightIsland' && ((companyKey === 'hb' || _.includes([8814], project.id)) && product.productId !== 516)) {
    fieldSetGroups[dimensionsAndSpecificsGroupIndex].properties.push(
      ...[
        //HINT checkbox limited to hb and st engineering + sf team
        //plus specific HB project that has widespread non-standard dim units
        ...((_.includes([583, 59, 9843, 1485, 10772, 5605, 7395, 6], project.userId) || _.includes([9019], project.id)) ? [{ //HINT hide option for prototype
          title: 'Non-Standard Dimensions (REQUIRES ENGINEERING APPROVAL)',
          key: 'customData.hasNonStandardDimensions',
          type: 'checkbox'
        }] : []),
        {key: 'customData.hasShopDrawing', title: 'Non-Standard / Has Shop', type: 'checkbox'},
      ]
    );
  }

  //< sink by others
  if (hasSink) {
    if (companyKey === 'vp') {
      fieldSetGroups[applianceSettingsGroupIndex].properties.push({
        key: 'customData.isByOthers',
        type: 'checkbox',
        inverseValue: true,
        title: 'Sink provided by Space Theory',
        options: [{value: 0, title: 'Space Theory'}, {value: 1, title: 'Others'}],
        userLenses: ['sales', 'design', 'engineering']
      });
    }

    fieldSetGroups[applianceSettingsGroupIndex].properties.push({
      key: 'customData.faucetType',
      type: 'radio',
      title: 'Faucet Type:',
      options: [
        {value: 'kitchenSink', title: 'Kitchen Sink'},
        {value: 'deckMounted', title: 'Deck-Mounted'},
        {value: 'wallMounted', title: 'Wall-Mounted'},
        ...(container.type === 'vanity' ? [{value: 'vessel', title: 'Vessel'}] : []),
        {value: 'none', title: 'None'}
      ]
    });

    if (product.customData.faucetType === 'vessel') {
      fieldSetGroups[applianceSettingsGroupIndex].properties.push({
        key: 'customData.vesselWidth',
        type: 'size',
        title: 'Vessel Width:',
      });

      fieldSetGroups[applianceSettingsGroupIndex].properties.push({
        key: 'customData.vesselHeight',
        type: 'size',
        title: 'Vessel Height:',
      });

      fieldSetGroups[applianceSettingsGroupIndex].properties.push({
        key: 'customData.vesselFaucetOffset',
        type: 'size',
        title: 'Top of Bowl to Faucet Centerline:',
      });
    }

    if (container && container.position && !_.isEqual(container.position, {})) {
      if (companyKey === 'hb' || !(container.type === 'vanity' && !_.includes([1215, 1219], product.productId))) {
        var walls = Room.get('walls', {room});

        fieldSetGroups[applianceSettingsGroupIndex].properties.push({
          key: 'customData.sinkDimensionWallId',
          type: 'select',
          noThumbnail: true,
          options: _.map(walls, ({id, outline}) => ({value: id, title: `Wall ${id} - ${outline.width}" wide`})),
          title: 'Centerline Dimension Wall',
          views: ['front', 'top']
        });
      }
    }
  }
  //> sink by others

  if (Product.getIsOvenWithDrawersUnder({product}) && !_.includes(K[companyKey].ids.tallCustomApplianceFrameProductIds, productType.id)) {
    fieldSetGroups[dimensionsAndSpecificsGroupIndex].properties.push(
      {
        key: 'customData.ovenDrawerHeights',
        type: 'text',
        title: 'Lower Drawer Heights (ie \'10, 12\') minimum 6"',
        views: ['front']
      }
    );
  }

  //< appliance stack appliance
  if (_.includes([1529], productType.id)) {
    //HINT adds appliance material colors for HB stacked appliances
    fieldSetGroups[dimensionsAndSpecificsGroupIndex].properties.push({
      key: 'appliancesData.0.primaryDisplayColor',
      type: 'text',
      title: 'Metal Color',
      views: ['front']
    });

    fieldSetGroups[dimensionsAndSpecificsGroupIndex].properties.push({
      key: 'appliancesData.0.secondaryDisplayColor',
      type: 'text',
      title: 'Glass Color',
      views: ['front']
    });
  }

  if (Product.getIsAppliance({product}) || hasSink) {
    if (product.productId === 1586) {
      fieldSetGroups[applianceSettingsGroupIndex].properties.push({
        key: 'customData.applianceQuantity',
        type: 'size',
        title: 'Appliance Quantity',
        minValue: 1,
      });
    }

    if (product.productId === 56) {
      fieldSetGroups[applianceSettingsGroupIndex].properties.push({
        key: 'customData.hasDowndraft',
        type: 'checkbox',
        title: 'Has Downdraft',
        userLenses: ['sales', 'design', 'engineering']
      });
    }

    if (_.includes([67, 65, 1230], product.productId)) {
      fieldSetGroups[applianceSettingsGroupIndex].properties.push({
        key: 'customData.hasCooktop',
        type: 'checkbox',
        title: 'Has Cooktop',
      });
    }

    if (Product.getIsCooktop({product}) && _.includes(_.lowerCase(productType.title), 'fixed panel')) {
      fieldSetGroups[applianceSettingsGroupIndex].properties.push({
        key: 'customData.noCooktopKnobs',
        type: 'checkbox',
        title: 'Cooktop Knobs Hidden',
      });
    }

    _.forEach(applianceInstancesData, ({fit, compatibleAppliances, filteredCompatibleAppliances}, index) => {
      var applianceFieldSet = [];
      var isCustomAppliance = _.get(product, `appliancesData[${index}].isCustomAppliance`) === 1;
      var isPreCustomApplianceUpdate = product.customData.isPreCustomApplianceUpdate;
      var brandTitle = 'Brand';
      var modelNumberTitle = 'Model Number';

      if (_.includes([918, 1376, 1566, 56], product.productId) && index !== 0 && index === applianceInstancesData.length - 1) {
        brandTitle = 'Downdraft Brand';
        modelNumberTitle = 'Downdraft Model Number';
      }

      if (_.includes([67, 65, 1230], product.productId) && index !== 0 && index === applianceInstancesData.length - 1) {
        brandTitle = 'Cooktop Brand';
        modelNumberTitle = 'Cooktop Model Number';
      }

      if (_.includes([693, 761, 1222], product.productId)) {
        brandTitle = index === 0 ? 'Oven Brand' : 'Cooktop Brand';
        modelNumberTitle = index === 0 ? 'Oven Model Number' : 'Cooktop Model Number';
      }

      if (fit === 'fitted') {
        //HINT st sinks already doing this
        if (!(companyKey === 'vp' && hasSink)) {
          applianceFieldSet.push({
            key: `appliancesData.${index}.isCustomAppliance`,
            title: 'Appliance Not on List',
            type: 'checkbox',
          });
        }

        if (!isCustomAppliance || isPreCustomApplianceUpdate) {
          _.forEach([
            {title: 'Brand', key: 'vendor'},
            {title: 'Type', key: 'subType'},
            // {title: 'Panel Type', key: 'panelType'}
          ], ({title, key}) => {
            var netOptionCount = _.reject(_.uniqBy(compatibleAppliances, key), {[key]: ''}).length;

            if (netOptionCount > 1) {
              var options = _.reject(_.uniqBy(_.map(filteredCompatibleAppliances, appliance => ({
                title: _.startCase(appliance[key]), value: appliance[key]
              })), 'value'), {value: ''});

              options = [{value: '', title: `All ${lib.string.pluralize(title)}`}, ...options];

              applianceFieldSet.push({
                key: `appliancesData.${index}.${key}`,
                title: `Appliance ${title}`,
                type: 'select',
                options
              });
            }
          });

          if (filteredCompatibleAppliances.length > 1) {
            applianceFieldSet.push({
              key: `appliancesData.${index}.id`,
              type: 'select',
              title: `Appliance Model${applianceInstancesData.length > 1 ? ` ${index + 1}` : ''}`,
              noThumbnail: true,
              options: _.map(filteredCompatibleAppliances, ({vendor, title, modelNumber, id}) => ({
                title: `${vendor} ${title.toLowerCase()} - ${modelNumber}`, value: id
              }))
            });
          }
        }

        //HINT user needs to be able to specify order of drawers and appliance if not using an appliance in our list
        // if ((isCustomAppliance || isPreCustomApplianceUpdate) && product.productId === 1659) {
        if (product.productId === 1659) {
          applianceFieldSet.push({
            key: `appliancesData.${index}.panelType`,
            type: 'select',
            title: `Unit Construction${applianceInstancesData.length > 1 ? ` ${index + 1}` : ''}`,
            noThumbnail: true,
            options: [{value: 'doubleDrawerBelow', title: 'Drawers Below Appliance'}, {value: 'doubleDrawerAbove', title: 'Drawers Above Appliance'}, {value: 'drawerAboveDrawerBelow', title: 'Drawer, Appliance, Drawer'}]
          });
        }

        //HINT waiting on this
        //HINT user needs to be able to specify order of drawer and appliances if not using an appliance in our list
        // if (product.productId === 1672) {
        //   applianceFieldSet.push({
        //     key: `appliancesData.${index}.panelType`,
        //     type: 'select',
        //     title: `Unit Construction${applianceInstancesData.length > 1 ? ` ${index + 1}` : ''}`,
        //     noThumbnail: true,
        //     options: [{value: 'doubleApplianceBelow', title: 'Appliances Below Drawer'}, {value: 'doubleApplianceAbove', title: 'Appliances Above Drawer'}, {value: 'applianceAboveApplianceBelow', title: 'Appliance, Drawer, Appliance'}]
        //   });
        // }

        //HINT user needs to be able to specify panel type if not using an appliance in our list
        if ((isCustomAppliance || isPreCustomApplianceUpdate) && product.productId === 1225) {
          applianceFieldSet.push({
            key: `appliancesData.${index}.panelType`,
            type: 'select',
            title: `Unit Construction${applianceInstancesData.length > 1 ? ` ${index + 1}` : ''}`,
            noThumbnail: true,
            options: [{value: 'frenchDoor28.5', title: 'French Door 28.5'}, {value: 'frenchDoor30', title: 'French Door 30'}, {value: 'overDoubleUnder', title: 'Over Double Under'}]
          });
        }
      }
      else if (fit === 'universal') {
        if (hasSink) {
          applianceFieldSet.push(
            {key: `appliancesData.${index}.customVendor`, type: 'text', title: brandTitle},
            {key: `appliancesData.${index}.modelNumber`, type: 'text', title: modelNumberTitle},
            {key: `appliancesData.${index}.displayedApplianceType`, type: 'text', title: 'Displayed Appliance Type', placeholder: Product.getApplianceType({product, index, showDisplayedApplianceType: true})},
          );
        }
        else {
          applianceFieldSet.push(
            {key: `appliancesData.${index}.customVendor`, type: 'text', title: brandTitle},
            {key: `appliancesData.${index}.modelNumber`, type: 'text', title: modelNumberTitle},
            {key: `appliancesData.${index}.displayedApplianceType`, type: 'text', title: 'Displayed Appliance Type', placeholder: Product.getApplianceType({product, index, showDisplayedApplianceType: true})},
            ...(!(index === 1 && _.includes([693, 761, 1222, 67, 65, 1230], productType.id)) && !_.includes([1239, 1242, 1241, 1244, 440, 779, 776, 777], productType.id) ? [
              {key: `appliancesData.${index}.dimensions.height`, type: 'size', title: _.includes([762, 64], product.productId) ? 'Opening Height' : 'Appliance Height'},
              {key: `appliancesData.${index}.dimensions.width`, type: 'size', title: 'Width'},
              {key: `appliancesData.${index}.dimensions.depth`, type: 'size', title: 'Depth'}
            ] : [])
          );
        }
      }

      //HINT already has text input for model number
      if (fit !== 'universal' && !hasSink && (!(fit === 'fitted' && !isCustomAppliance) || isPreCustomApplianceUpdate)) {
        applianceFieldSet.push(
          {key: `appliancesData.${index}.customVendor`, type: 'text', title: brandTitle},
          {key: `appliancesData.${index}.customModelNumber`, type: 'text', title: modelNumberTitle},
          {key: `appliancesData.${index}.displayedApplianceType`, type: 'text', title: 'Displayed Appliance Type', placeholder: Product.getApplianceType({product, index, showDisplayedApplianceType: true})},
          ...(Product.getIsRangetop({product}) ? [{key: `appliancesData.${index}.customHeight`, type: 'size', title: 'Display Height'}] : [])
        );
      }

      if (applianceFieldSet.length > 0) {
        if (!hasSink && fit === 'fitted' && (isCustomAppliance || isPreCustomApplianceUpdate) && (Product.getAppliancePanelQuantity({product}) === 0 || _.includes([1276], product.productId)) && !_.includes([1224, 1660], product.productId) && !_.includes(K[companyKey].ids.tallCustomApplianceFrameProductIds, product.productId)) {
          applianceFieldSet.push({key: `appliancesData.${index}.customHeight`, type: 'size', allowEmptyString: true, title: 'Custom Display Height'});
        }

        //HINT adds appliance material colors for ST stacked appliances
        if (!Product.getIsApplianceStackFrame({product})
          && _.includes([65, 66, 67, 1222, 1230, 1535, 100, 101, 1529, 1103, 1516, 779, 924, 923, 747, 755, 693, 761, 1132, 1133, 776, 777, 759, 962, 760, 764, 765, 1027, 1028, 853], productType.id)
          && !(index === 1 && _.includes([693, 761, 1222, 67, 65, 1230], productType.id))
        ) {
          applianceFieldSet.push({
            key: `appliancesData.${index}.primaryDisplayColor`,
            type: 'text',
            title: 'Metal Color',
            views: ['front']
          });

          applianceFieldSet.push({
            key: `appliancesData.${index}.secondaryDisplayColor`,
            type: 'text',
            title: 'Glass Color',
            views: ['front']
          });
        }

        fieldSetGroups[applianceSettingsGroupIndex].properties.push(applianceFieldSet);
      }
    });

    //HINT double appliance needs to be able to adjust both appliance sizes
    //Even though it is one appliance for the appliances list
    if (productType.id === 1132) {
      fieldSetGroups[applianceSettingsGroupIndex].properties.push({key: 'appliancesData.1.customHeight', type: 'size', title: 'Lower Oven Custom Display Height'});
      fieldSetGroups[applianceSettingsGroupIndex].properties.push({
        key: 'appliancesData.1.primaryDisplayColor',
        type: 'text',
        title: 'Lower Oven Metal Color',
        views: ['front']
      });

      fieldSetGroups[applianceSettingsGroupIndex].properties.push({
        key: 'appliancesData.1.secondaryDisplayColor',
        type: 'text',
        title: 'Lower Oven Glass Color',
        views: ['front']
      });
    }
  }

  if (Product.getIsRangetop({product})) {
    fieldSetGroups[applianceSettingsGroupIndex].properties.push({
      key: 'customData.rangetopHeightOffset',
      type: 'size',
      title: 'Height Addition (extra distance above subcounter)',
      views: ['front']
    });
  }
  //> appliances

  //HINT ST Shelfbank
  if (_.includes([978], productType.id)) {
    var shelfbankOffsetFieldSet = [];

    if (product.dimensions.height >= 28.5) {
      shelfbankOffsetFieldSet.push({
        key: 'customData.topShelfOffset',
        type: 'number',
        title: 'Top Shelf Offset',
        views: ['front']
      },
      {
        key: 'customData.middleShelfOffset',
        type: 'number',
        title: 'Middle Shelf Offset',
        views: ['front']
      });
    }

    if (product.dimensions.height >= 57) {
      shelfbankOffsetFieldSet.push({
        key: 'customData.lowerMiddleShelfOffset',
        type: 'number',
        title: 'Lower Middle Shelf Offset',
        views: ['front']
      });
    }

    shelfbankOffsetFieldSet.push({
      key: 'customData.bottomShelfOffset',
      type: 'number',
      title: 'Bottom Shelf Offset',
      views: ['front']
    });

    if (shelfbankOffsetFieldSet.length) fieldSetGroups[dimensionsAndSpecificsGroupIndex].properties.push(shelfbankOffsetFieldSet);
  }

  if (_.includes([65, 755, 758, 1230, 1659, 1672], productType.id)) {
    fieldSetGroups[dimensionsAndSpecificsGroupIndex].properties.push(
      {
        key: 'customData.hasFixedPanel',
        type: 'checkbox',
        title: 'Has Fixed Panel',
        defaultTrue: productType.id === 1659,
        views: ['front'],
      }
    );

    if (product.customData.hasFixedPanel) {
      fieldSetGroups[dimensionsAndSpecificsGroupIndex].properties.push(
        {
          key: 'customData.fixedPanelHeight',
          type: 'number',
          title: 'Fixed Panel Height',
          placeholder: 2,
          allowEmptyString: true,
          views: ['front'],
        }
      );
    }
  }

  if (_.includes([66], productType.id)) {
    fieldSetGroups[dimensionsAndSpecificsGroupIndex].properties.push(
      {
        key: 'customData.lowerPanelHeight',
        allowEmptyString: true,
        type: 'number',
        title: 'Lower Panel Height',
        views: ['front'],
      }
    );
  }

  if (_.includes([1661], productType.id)) {
    fieldSetGroups[dimensionsAndSpecificsGroupIndex].properties.push({
      key: 'customData.fixedPanelSide',
      type: 'select',
      title: 'Fixed Panel Side',
      options: [{title: 'Bottom', value: 'bottom'}, {title: 'Top', value: 'top'}],
      views: ['front'],
    });
  }

  //HINT assembly
  if (_.includes([512, 513, 514], productType.id)) {
    fieldSetGroups[dimensionsAndSpecificsGroupIndex].properties.push(
      {
        key: 'customData.shelfTopOffsets',
        type: 'text',
        title: 'Shelf Offsets (comma separated, decimal)',
        views: ['front']
      },
      {
        key: 'customData.hasRadiusCorners',
        type: 'checkbox',
        title: 'Has Radius Corners',
        views: ['front']
      }
    );
  }

  //HINT bench assembly
  if (_.includes([512], productType.id)) {
    fieldSetGroups[dimensionsAndSpecificsGroupIndex].properties.push(
      {
        key: 'customData.hasShoeShelf',
        type: 'checkbox',
        title: 'Has Shoe Shelf',
        views: ['front'],
        userLenses: ['sales', 'design', 'engineering']
      },
      // {
      //   key: 'customData.hasHooks',
      //   type: 'checkbox',
      //   title: 'Has Hooks',
      //   views: ['front']
      // }
    );
  }

  if (_.includes([1335, 1337], productType.id)) {
    fieldSetGroups[dimensionsAndSpecificsGroupIndex].properties.push(
      {
        key: 'customData.outletSide',
        type: 'radio',
        title: 'Outlet side',
        views: ['front'],
        options: [{value: 'left', title: 'Left'}, {value: 'right', title: 'Right'}]
      }
    );
  }

  if (_.includes(_.toLower(productType.title), 'outlet')) {
    fieldSetGroups[dimensionsAndSpecificsGroupIndex].properties.push(
      {
        key: 'customData.outletColor',
        type: 'radio',
        title: 'Outlet Color',
        views: ['front'],
        options: [{value: 'black', title: 'Black'}, {value: 'white', title: 'White'}]
      },
    );
  }

  if (_.includes(K[companyKey].ids.notchedWallPanels, productType.id) || productType.id === 1501) {
    fieldSetGroups[dimensionsAndSpecificsGroupIndex].properties.push(
      {
        key: 'customData.notchType',
        type: 'select',
        title: 'Notch Type',
        views: ['front'],
        options: [{value: 'both', title: 'Left and Right'}, {value: 'left', title: 'Left'}, {value: 'right', title: 'Right'}, ...(productType.id === 1501 ? [] : [{value: 'none', title: 'None'}])]
      }
    );
  }

  //HINT units with external hangrod
  if (_.includes([1295, 1296, 1297, 1298, 1299, 1300, 1301, 1302, 1303, 1304, 1305, 1379, 406, 517, 420, 1331, 1332, 509], product.productId)) {
    fieldSetGroups[dimensionsAndSpecificsGroupIndex].properties.push({
      key: 'customData.hangrodWidth',
      type: 'size',
      title: 'Hangrod Width',
      views: ['front']
    }, {
      key: 'customData.hangrodX',
      type: 'size',
      title: 'Hangrod Position',
      views: ['front']
    }, {
      key: 'customData.hangrodCount',
      type: 'number',
      title: 'Rod Count',
      views: ['front']
    });
  }

  if (_.includes([384, 385], product.productId)) {
    fieldSetGroups[dimensionsAndSpecificsGroupIndex].properties.push({
      key: 'customData.jambPanelDepths',
      type: 'size',
      title: 'Jamb Panel Depth',
      views: ['front'],
      placeholder: 2.25,
      minValue: 2.25,
      maxValue: 12,
      step: 0.125
    });
  }

  //HINT non-frame VBB units (not components or frames)
  if (_.includes([496, 497, 498, 499, 500, 501, 502, 503, 504, 505, 506, 507], product.productId)) {
    fieldSetGroups[dimensionsAndSpecificsGroupIndex].properties.push({
      key: 'customData.vbbCustomBayType',
      type: 'select',
      title: 'Customizable Bay Type',
      views: ['front'],
      options: [{value: 'outlet', title: 'Outlet'}, {value: 'drawer', title: 'Drawer'}, {value: 'wineShelf', title: 'Wine Shelf'}]
    });
  }

  // Swap Product Logic
  let interchangableProductsByCategory = _.map(getProductsByCategoryFor({...(parentProduct ? {product: parentProduct} : {container})}), productCategory => {
    return {title: productCategory.title, options: productCategory.productTypes};
  });

  if (interchangableProductsByCategory) {
    let interchangableProductOptionGroups = _.map(interchangableProductsByCategory, ({title, options}) => ({
      title, options: _.map(options, ({id, title}) => ({value: id, title}))
    }));

    let optionArray = _.map(interchangableProductOptionGroups, ({title, options}) => {
      return {title, options: _.map(options, option => ({title: title + ' ' + option.title, value: option.value}))};
    });

    fieldSetGroups[otherGroupIndex].properties.push(
      {key: 'productId', noThumbnail: true, title: 'Swap Product', type: 'select', optionGroups: optionArray || [], hasOptionGroups: true, userLenses: ['sales', 'design', 'engineering']}
    );
  }
  //swap product>

  var canUseProductionId = Product.getDefaultUseProductionId({product});
  var usingProductionId = Product.getUseProductionId({product});

  if (isEmployee) {
    var labelPlaceholder = (productType.shortTitle || '').toLowerCase();

    if (Product.getHasSink({product})) labelPlaceholder = 'sink';

    _.forEach(product.appliancesData, applianceData => {
      var appliance = _.find(appliances, {id: applianceData.id});

      var modelNumber = appliance ? appliance.modelNumber : applianceData.modelNumber;

      if (modelNumber) {
        labelPlaceholder += ` ${modelNumber}`;
      }
    });

    fieldSetGroups[otherGroupIndex].properties.push({key: 'customData.planLabel', placeholder: labelPlaceholder, title: 'Plan Label', type: 'text', isMultiline: true});
    fieldSetGroups[otherGroupIndex].properties.push({key: 'customData.isLocked', title: 'Locked', type: 'checkbox', userLenses: ['sales', 'design', 'engineering']});
    fieldSetGroups[otherGroupIndex].properties.push({key: 'customData.hideInSection', title: 'Hide In Section', type: 'checkbox'});
    fieldSetGroups[otherGroupIndex].properties.push({key: 'customData.hideInPlan', title: 'Hide In Plan', type: 'checkbox'});
  }

  if (isEmployee && (canUseProductionId || product.customData.hasShopDrawing)) {
    fieldSetGroups[otherGroupIndex].properties.push(
      {key: 'customData.hasShopDrawing', title: 'Non-Standard / Has Shop', type: 'checkbox'},
      {
        key: 'customData.productionIdEnabled',
        title: 'Unit #',
        type: 'checkbox',
        defaultTrue: true,
      },
    );
  }

  //hasFront
  if (isEmployee) {
    var hinge = Product.getHingeData({product, productType});

    //TODO check if cabinet
    fieldSetGroups[flyBysGroupIndex].properties.push(..._.map(['left', 'top', 'right', 'bottom'], sideKey => {
      var propertyOptions = [];

      if (!hinge[sideKey]) {
        var propertyOptions = [
          {
            key: `customData.flyBys.${sideKey}`,
            type: 'select',
            noThumbnail: true,
            title: `${_.upperFirst(sideKey)} flyby`,
            views: ['front'],
            options: [{value: '', title: 'None'}, {value: 0.75, title: '3/4"'}, {value: 0.6875, title: '11/16"'}, {value: 0.625, title: '5/8"'}, {value: 'custom', title: 'Custom'}],
            userLenses: ['design', 'engineering']
          }
        ]

        if (_.get(product, `customData.flyBys.${sideKey}`, '') === 'custom') {
          propertyOptions.push({
            key: `customData.flyBys.${sideKey}Custom`,
            type: 'number',
            title: `${_.upperFirst(sideKey)} custom flyby`,
            views: ['front'],
            maxValue: (product.customData.hasShopDrawing) ? undefined : 6,
            userLense: ['design', 'engineering']
          })
        }
      }

      return [...propertyOptions]
    }));

    fieldSetGroups[revealsGroupIndex].properties.push(..._.map(['left', 'top', 'right', 'bottom'], sideKey => ({
      key: `customData.reveals.${sideKey}`,
      type: 'select',
      noThumbnail: true,
      title: `${_.upperFirst(sideKey)} reveal notation`,
      views: ['front'],
      options: [{value: 'none', title: 'None'}, {value: 'oneEighthReveal', title: '1/8th Reveal'}, {value: 'fullOverlay', title: 'Full Overlay'}, {value: 'flyBy', title: 'Fly-By'}],
      userLenses: ['engineering']
    })));

    if (usingProductionId) {
      var unitNumberPositionOptions = [{value: 'bottomLeft', title: 'Bottom Left'}, {value: 'bottomRight', title: 'Bottom Right'}];

      //HINT hidden cap panel
      if (_.includes([1619, 1481, 1202, 1204, 1205, 1448, 1639, 1681, 1688, 1692], product.productId)) {
        unitNumberPositionOptions = [{value: 'top', title: 'Top'}, {value: 'bottom', title: 'Bottom'}];
      }
      else if (_.includes([1479, 1480, 1521, 1201, 1198, 1199, 1200, 1201, 1440, 1526, 1682, 1687, 1689, 1691], product.productId)) {
        unitNumberPositionOptions = [{value: 'center', title: 'Center'}, {value: 'bottomLeft', title: 'Bottom Left'}, {value: 'bottomRight', title: 'Bottom Right'}];
      }
      else {
        unitNumberPositionOptions.push({value: 'topRight', title: 'Top Right'}, {value: 'topLeft', title: 'Top Left'});
      }

      fieldSetGroups[otherGroupIndex].properties.push(
        {
          key: 'customData.productionIdPosition',
          title: 'Unit # Position',
          type: 'select',
          noThumbnail: true,
          options: unitNumberPositionOptions
        },
        {
          key: 'customData.productionIdOffset',
          title: 'Unit # offset (offset 1 means A3, A5, A6)',
          type: 'text'
        }
      );
    }
  }

  if (_.includes(['woodShelf', 'steelShelf'], type)) {
    fieldSetGroups[dimensionsAndSpecificsGroupIndex].properties = _.map(_.filter(_.flatMap(fieldSetGroups[dimensionsAndSpecificsGroupIndex].properties), field => field.key !== 'dimensions.height'));
  }

  //HINT adds individual stack heights for stacked products
  if (
    _.includes([148, 149, 150, 151, 152, 153, 154, 155, 395, 396], product.productId)
  ) {
    var stackData = Product.getStackData({product});

    fieldSetGroups[dimensionsAndSpecificsGroupIndex].properties.push({
      key: 'customData.topStackHeight',
      type: 'size',
      allowEmptyString: true,
      title: 'Top Height',
      views: ['front'],
      step: 0.125,
      placeholder: stackData.top.height,
      minValue: stackData.top.constraints.min,
      maxValue: stackData.top.constraints.constrainedMax
    });

    fieldSetGroups[dimensionsAndSpecificsGroupIndex].properties.push({
      key: 'customData.bottomStackHeight',
      type: 'size',
      allowEmptyString: true,
      title: 'Bottom Height',
      views: ['front'],
      step: 0.125,
      placeholder: stackData.bottom.height,
      minValue: stackData.bottom.constraints.min,
      maxValue: stackData.bottom.constraints.constrainedMax
    });
  }

  var productOptionTypes = _.values(dependencies.productOptionTypes.byId);
  var pricingRules = _.values(dependencies.pricingRules.byId);
  var productOptionAssociationKeys = _.keys(productType.associations.product_options); //formatted 'id_${po.id}'
  var autoManagedProductOptionIds = [1, 3, 7, 8, 10, 16, 19, 21, 22, 31, 39, 43, 45, 46, 47, 48, 49, 50, 51, 52, 53, 60, 61, 62, 72, 73, 77, 87, 88, 89, 90, 91, 92, 93];
  //HINT these options have been automated. Users should be allowed to toggle them off for legacy projects, but not on
  var includedWhenOnProductOptionIds = [56, 57, 58, 59];
  var nonStandardProductOptionIds = companyKey === 'hb' ? [106, 107] : [];

  //HINT internal lighting normally assigned to container except for these products
  var isInternallyLit = Product.getHasInternalLighting({product});

  if (companyKey === 'hb' && isInternallyLit) {
    fieldSetGroups[dimensionsAndSpecificsGroupIndex].properties.push({
      key: 'customData.lightingTemp', title: 'Lighting Temp', type: 'radio', options: [{value: '3000', title: '3000K'}, {value: '2700', title: '2700K'}]
    });
  }

  var productOptionFieldSets = [];
  var {compatibleProductOptions, nonStandardProductOptionIds, pricingRules, productOptionTypes} = Product.getCompatibleProductOptions({product});

  _.forEach(compatibleProductOptions, productOption => {
    var {total, unitPrice} = lib.price.dataForProductOptionInstance({productOptionId: productOption.id}, {pricingRules, productOptions: productOptionTypes, productInstance: {}, project});

    //HINT these are productoptions that can only be applied to a product once, like internal lighting
    var productOptionCanHaveQuantity = productOption.plural;

    productOptionFieldSets.push(
      [{
        key: `customData.productOptions.${productOption.id}.enabled`,
        type: 'checkbox',
        title: `${productOption.title} (${total.toFormat('$0,0')})${productOptionCanHaveQuantity ? ' per' : ''}`,
        views: ['front', 'top'],
        userLenses: ['sales', 'design', 'engineering'],
      },
      ...((_.get(product, `customData.productOptions.${productOption.id}.enabled`) && productOptionCanHaveQuantity) ? [{
        key: `customData.productOptions.${productOption.id}.quantity`,
        type: 'text',
        title: `${productOption.title} ${_.includes(nonStandardProductOptionIds, productOption.id) ? 'Price' : 'Quantity'}`,
        views: ['front', 'top'],
        userLenses: ['sales', 'design', 'engineering'],
      }] : [])
      ]);
  });

  fieldSetGroups[productOptionsGroupIndex].properties.push(...productOptionFieldSets);

  //capped products
  if (_.includes([
    1494, 1503, 1667, //Wall panels (1/4" cleat / 1/4" cleat w. spacer, notched wall panels)
    186, 444, 442, 185, 184, 443, //backsplash
    435, 451, //opencase panels (still missing (w. part addition: opencase end cap))
    165, 1150, 1159, 1160, 1322, 164, 166, 163, 162, 1379, 406, // shelfbanks (all but pass through)
  ], product.productId)) {
    fieldSetGroups[otherGroupIndex].properties.push({
      key: 'customData.productVariation',
      title: 'Capped',
      view: ['top', 'front'],
      type: 'radio',
      options: [{value: 'standard', title: 'Standard'}, {value: 'capped', title: 'Capped'}],
      userLenses: ['engineering']
    });
  }

    //end and cap panel products
    if (_.includes([
      1440, 1448, 1681, 1443, 1637, 1682
    ], product.productId)) {
      fieldSetGroups[otherGroupIndex].properties.push({
        key: 'customData.productVariation',
        title: 'Product Variation',
        view: ['top', 'front'],
        type: 'radio',
        options: [{value: 'standard', title: 'Standard'}, {value: 'anchored', title: 'Anchored'}, {value: 'singleSided', title: 'Single Sided'}],
        userLenses: ['engineering']
      });
    }

  _.forEach(DetailsHelper.getCompatibleDetailsFor({product}), detail => {
    var pullFieldSetGroup = detail.isSubproductControl ? fieldSetGroups[additionalPullsGroupIndex] : fieldSetGroups[pullsGroupIndex];
    var materialFieldSetGroup = detail.isSubproductControl ? fieldSetGroups[additionalMaterialsGroupIndex] : fieldSetGroups[materialsGroupIndex];

    var fieldSetGroup = ((detail.type === 'material' && !_.includes(detail.key, 'pullMaterial')) || _.includes(detail.key, 'grain') || _.includes(detail.key, 'frontPanelType')) ? materialFieldSetGroup : pullFieldSetGroup;

    if (!(activeUserLense === 'sales' && detail.isSubproductControl)) {
      if (detail.type === 'material') {
        fieldSetGroup.properties.push({
          path: `details.${detail.key}.id`,
          noThumbnail: false,
          type: 'radio',
          hasOptionGroups: true,
          optionGroups: detail.optionGroups,
          options: detail.options,
          title: detail.title,
          views: ['top', 'front'],
          userLenses: detail.userLenses ? detail.userLenses : ['sales', 'design', 'engineering']
        });
      }
      else {
        fieldSetGroup.properties[(detail.type === 'pullType' && !detail.isSubproductControl) ? 'unshift' : 'push']({
          path: `details.${detail.key}.id`,
          noThumbnail: detail.noThumbnail,
          type: 'radio',
          options: detail.options,
          title: detail.title,
          views: ['top', 'front'],
          userLenses: detail.userLenses ? detail.userLenses : ['sales', 'design', 'engineering']
        });
      }

      if (detail.key === 'grainDirection') {
        fieldSetGroup.properties.push({
          path: `customData.grainIndicatorSide`,
          noThumbnail: true,
          type: 'radio',
          options: [{value: 'right', title: 'Right'}, {value: 'left', title: 'Left'}],
          title: 'Grain Indicator Side',
          views: ['top', 'front', 'left', 'right', 'back'],
          userLenses: ['design', 'engineering']
        });
      }

      if (_.includes(detail.key, 'pullLocation')) {
        if (_.includes([1335, 1337], productType.id)) {
          var pullIsCompatibleWithOption = Product.getOutletPullCanBeCentered({product});

          if (pullIsCompatibleWithOption) {
            fieldSetGroup.properties.push(
              {
                key: 'customData.alignPullWithLowerDrawers',
                type: 'checkbox',
                title: 'Align Pull with Drawers',
                views: ['front'],
                userLenses: detail.userLenses ? detail.userLenses : ['design', 'engineering']
              }
            );
          }
        }

        if (companyKey === 'hb') {
          fieldSetGroup.properties.push({
            path: `details.${detail.key}.settings.offsetX`,
            noThumbnail: true,
            type: 'number',
            title: `${detail.titlePrefix}Pull X Offset`,
            placeholder: 0,
            views: ['front'],
            userLenses: detail.userLenses ? detail.userLenses : ['design', 'engineering']

          }, {
            path: `details.${detail.key}.settings.offsetY`,
            noThumbnail: true,
            type: 'number',
            title: `${detail.titlePrefix}Pull Y Offset`,
            placeholder: 0,
            views: ['front'],
            userLenses: detail.userLenses ? detail.userLenses : ['design', 'engineering']

          });
        }
      }
    }
  });

  if (_.includes([583, 59, 9843, 1485, 10772, 5605, 7395, 6], project.userId)) {
    fieldSetGroups[materialsGroupIndex].properties.push({
      path: `customData.allowDiscontinuedMaterials`,
      type: 'checkbox',
      title: 'Allow Discontinued Materials',
      views: ['front'],
      userLenses: ['design', 'engineering']
    });
  }

  // fieldSetGroups[productOptionsGroupIndex].properties.push(...productOptionFieldSets);

  if (type === 'opencasePanel') {
    const computedMargin = Product.getComputedMargin({product});
    const computedCellCounts = Product.getComputedCellCounts({product});
    fieldSetGroups.unshift({
      title: 'Opencase',
      properties: [
        {key: 'customData.margin.left', type: 'size', title: 'Grid Inset - Left', placeholder: computedMargin.left},
        {key: 'customData.margin.bottom', type: 'size', title: 'Grid Inset - Bottom', placeholder: computedMargin.bottom},
        {key: 'customData.cellCount.rows', type: 'size', title: 'Grid Size - Rows', placeholder: computedCellCounts.rows},
        {key: 'customData.cellCount.columns', type: 'size', title: 'Grid Size - Columns', placeholder: computedCellCounts.columns}
      ]
    });
  }

  if (activeDetailLevel === 'rendering') {
    fieldSetGroups[otherGroupIndex].properties.push({
      path: `customData.renderingOverrideFill`,
      type: 'color',
      title: 'Rendering Override Fill Color',
      allowEmptyString: true,
      views: ['front'],
      userLenses: ['design', 'engineering']
    });
  }

  return _.flatMap(_.filter(fieldSetGroups, fieldSetGroup => _.flatMap(fieldSetGroup.properties).length > 0), fieldSetGroup => ({title: fieldSetGroup.title, properties: _.flatMap(fieldSetGroup.properties)}));
}

function handleProductPropertyChange ({activeEntityId, activeEntity, path, resourceActions, value, isBatched}) {
  var product = activeEntity;
  var updatesMap = {
    rooms: {creations: [], deletedIds: [], updates: []},
    containers: {creations: [], deletedIds: [], updates: []},
    products: {creations: [], deletedIds: [], updates: []},
    productOptions: {creations: [], deletedIds: [], updates: []}
  };

  var updates = {};

  var {room, productType, container} = Product.get(['room', 'productType', 'container'], {product});
  var shouldUpdateRoomManagedResources = false;

  //HINT when autofilled products are adjusted, they stop being automanaged
  //for containers with complex logic (IE generating multiple products of different sizes, stop autofilling all products)
  if (!isBatched && product.managedData) {
    shouldUpdateRoomManagedResources = true;
    product.managedData = null;

    if (Container.getHasComplexAutofillLogic({container})) {
      let siblingAutofilledStorage = _.filter(Container.get('products', {container}), siblingCandidate => _.get(siblingCandidate, 'managedData.managedKey') === 'autofilledStorage');

      _.forEach(siblingAutofilledStorage, siblingAutofilledProduct => {
        updatesMap.products.updates.push({where: {id: siblingAutofilledProduct.id}, props: {managedData: null}});
      });
    }

    updates = {...updates, managedData: null};
  }

  if (!isBatched && path === 'customData.productionIdEnabled' || path === 'customData.productionIdOffset') shouldUpdateRoomManagedResources = true;

  if (path === 'productId') {
    var {managedUpdatesMap} = Product.updateManagedResources({product, actionKey: 'destroy', resourceActions, isBatched: true});

    updatesMap = UpdatesMapsHelpers.combineUpdatesMaps(updatesMap, managedUpdatesMap);

    if (_.includes(K[Product.get('companyKey', {product})].ids.tallCustomApplianceFrameProductIds, product.productId)) {
      updatesMap.products.deletedIds.push(..._.map(Product.get('childProducts', {product}), 'id'));
    }

    //HINT update product option based on number of fronts
    if (Product.getHasAppliedBrass({product})) {
      var appliedBrassOptionIds = [80, 81, 82, 83];
      var newProductType = Product.get('productType', {product: {...product, productId: value}});
      var appliedBrassOptionKey = _.findKey(_.get(newProductType, 'associations.product_options'), (value, key) => {
        return _.includes(['id_80', 'id_81', 'id_82', 'id_83'], key);
      });

      if (appliedBrassOptionKey) {
        var productOptionId = _.toNumber(_.trim(appliedBrassOptionKey, 'id_'));

        product.customData = {...product.customData, productOptions: {...product.customData.productOptions, [productOptionId]: {enabled: 1}}};
      }
    }

    //HINT need new product id when calling new updateManagedResources
    product.productId = value;

    var {managedUpdatesMap, productCacheUpdate} = Product.updateManagedResources({product, actionKey: 'create', resourceActions, isBatched: true});

    product.customData = {...product.customData, cachedManagedData: productCacheUpdate};

    if (product.productId === 755) product.customData = {...product.customData, hasFixedPanel: true};

    product = Product.constrainProps({product});

    updatesMap = UpdatesMapsHelpers.combineUpdatesMaps(updatesMap, managedUpdatesMap);

    updates = {...updates, ...product};

    updatesMap.products.updates.push({where: {id: activeEntityId}, props: {...updates}});

    UpdatesMapsHelpers.makeReduxUpdatesFor({updatesMap, resourceActions});
  }
  else {
    if (_.split(path, '.').length > 1) {
      var topLevelKey = _.split(path, '.')[0];
      var isSubproductDetail = _.split(path, '-').length > 1;

      if (_.includes(path, 'customData.productOptions')) {
        var productOptions = product.customData.productOptions;

        if (!productOptions || _.isEmpty(productOptions)) {
          _.set(product, 'customData.productOptions', {});
        }
        //handle outdated format
        else if (productOptions.constructor === Array) {
          productOptions = _.clone(productOptions);

          _.set(product, 'customData.productOptions', {});

          _.forEach(productOptions, (productOptionData, productOptionId) => {
            if (productOptionData && productOptionData.enabled) {
              _.set(product, `customData.productOptions.${productOptionId}.enabled`, true);
            }
          });
        }
      }

      _.set(product, path, value);

      updates = {
        ...updates,
        [topLevelKey]: product[topLevelKey]
      };

      if (_.includes(path, 'isCustomAppliance')) {
        if (product.customData.isPreCustomApplianceUpdate) {
          delete product.customData.isPreCustomApplianceUpdate;
          updates.customData = product.customData;
        }
      }

      if (_.includes(path, 'customData.flyBys')) {
        var side = _.split(path, '.')[2];

        var newReveal = value === '' ? '' : 'flyBy';
        updates.customData = {...product.customData, reveals: {...product.customData.reveals, [side]: newReveal}};
        product.customData = {...product.customData, reveals: {...product.customData.reveals, [side]: newReveal}}
      }

      if (_.includes(path, 'customData.reveals') && value !== 'flyBy') {
        var side = _.split(path, '.')[2];

        updates.customData = {...product.customData, flyBys: {...product.customData.flyBys, [side]: ''}};
        product.customData = {...product.customData, flyBys: {...product.customData.flyBys, [side]: ''}}
      }

      if (path === 'customData.hasNonStandardDimensions') {
        if (value) {
          updates.customData = {...product.customData, hasShopDrawing: 1};
          product.customData = {...product.customData, hasShopDrawing: 1};
        }
        else {
          //HINT run twice to first constrain, then apply any dimension specific constraints
          //IE first constrain depth, then constrain depth-dependent width constraint
          var constraints = Product.getConstraints({product});

          const constrainer = new lib.DimensionConstrainer({constraints});
          updates.dimensions = constrainer.constrain({dimensions: product.dimensions});
          product.dimensions = updates.dimensions;
        }
      }

      if (
        _.includes(['customData.topStackHeight', 'customData.bottomStackHeight'], path)
      ) {
        var topStackHeight = _.get(product, 'customData.topStackHeight');
        var bottomStackHeight = _.get(product, 'customData.bottomStackHeight');

        if (topStackHeight && bottomStackHeight && (topStackHeight + bottomStackHeight !== product.dimensions.height)) {
          updates.dimensions = {...product.dimensions, height: _.toNumber(topStackHeight) + _.toNumber(bottomStackHeight)};
          product.dimensions = updates.dimensions;
        }
      }

      //HINT properties that cause changes to dimensions
      var shouldConstrainDimensions = _.includes(['customData.isFlutedAdjacent', 'customData.middleBayHeight', 'customData.topStackHeight', 'customData.bottomStackHeight'], path);

      if ((updates.dimensions || shouldConstrainDimensions) && !product.customData.hasNonStandardDimensions) {
        var updatedDimensions = {...product.dimensions, ...(updates.dimensions || {})};
        var constraints = Product.getConstraints({product: {...product, dimensions: updatedDimensions}});

        const constrainer = new lib.DimensionConstrainer({constraints});
        updates.dimensions = constrainer.constrain({dimensions: updatedDimensions});
        product.dimensions = updates.dimensions;
      }

      if (path === 'customData.countertopThickness' && Product.getIsHorizontalBarblock({product})) {
        //HINT height = countertop height + opening height (4 5/8) + top panel (3/4") + revealed bottom panel height above countertop (1/4")
        var height = value + .75 + 4.625 + .25;
        var dimensions = {...product.dimensions, height};

        updates.dimensions = dimensions;

        updatesMap.containers.updates.push({where: {id: product.containerInstanceId}, props: {dimensions: {...container.dimensions, height}}});
      }

      if (_.includes(path, 'customData.productOptions')) {
        var appliedBrassOptionIds = [80, 81, 82, 83];
        var dependencies = Product.get('dependencies', {product});
        var flattenedMaterials = _.flatMap(_.values(dependencies.materialClasses.byId), materialClass => materialClass.materials);

        _.forEach(appliedBrassOptionIds, id => {
          if (_.includes(path, `customData.productOptions.${id}.enabled`)) {
            var hasAppliedBrass = _.get(product, `customData.productOptions.${id}.enabled`);

            if (hasAppliedBrass) {
              product.details = {..._.omit(product.details, ['pullMaterial']), pullType: {id: 23}};
              _.unset(product, 'materialIds.pull');
              updates.details = {..._.omit(product.details, ['pullMaterial']), pullType: {id: 23}};
              updates.materialIds = _.omit(product.materialIds, ['pull']);

              var appliedMaterial = _.find(flattenedMaterials, {id: _.get(product, 'details.frontMaterial.id')});

              if (!lib.materials.getIsVeneer({material: appliedMaterial})) {
                _.set(product, 'details.frontMaterial.id', {id: 358});
                _.set(product, 'materialIds.front', {id: 358});
                _.set(updates, 'details.frontMaterial.id', {id: 358});
                _.set(updates, 'materialIds.front', {id: 358});
              }
            }
          }
        });
      }

      if (_.includes(path, 'details')) {
        var subproductData = Product.getSubproductData({product});
        var isUpdatingMaterial = _.includes(path, 'Material');
        const materialKey = _.replace(_.replace(path, 'details.', ''), 'Material', '');

        if (isUpdatingMaterial) _.set(product, `materialIds.${materialKey}`, value);

        if (!isSubproductDetail && subproductData && subproductData.length && product.details) {
          _.forEach(subproductData, (data, index) => {
            _.times(data.quantity, n => {
              delete product.details[`${data.type}-${n}-${_.split(path, '.')[1]}`];
              delete product.materialIds[`${data.type}-${n}-${_.split(materialKey, '.')[0]}`];
            });
          });
        }

        //HINT ST pocketing flip can't have normal scooped bottom pull when front is color matched edge
        if (_.includes([990, 1191, 1673, 1674], product.productId) && _.includes(path, 'frontMaterial')) {
          var dependencies = Product.get('dependencies', {product});
          var flattenedMaterials = _.flatMap(_.values(dependencies.materialClasses.byId), materialClass => materialClass.materials);
          var frontIsColorMatchedEdge = _.get(_.find(flattenedMaterials, {id: value}), 'materialTypeId') === 21;

          if (product.details.pullType.id === 10 && frontIsColorMatchedEdge) {
            product.details.pullType.id === 1;
            updates.details = {...updates.details, pullType: {id: 1}};
          }
        }

        if (_.includes(path, 'frontPanelType')) {
          var compatibleDetails = DetailsHelper.getCompatibleDetailsFor({product});

          if (!_.find(compatibleDetails, {key: 'frontPanelType'})) {
            value = 'flat';
            _.set(product, path, value);
          }

          if (_.includes(['beveledFramedPanel', 'squareFramedPanel', 'beveledFramedGlass', 'squareFramedGlass'], value)) {
            var doorStorageProductOptionIds = [4, 14, 12];

            _.forEach(doorStorageProductOptionIds, id => {
              if (_.get(product, `customData.productOptions.${id}.enabled`) === 1) {
                updates.customData = product.customData;
                _.set(updates, `customData.productOptions.${id}.enabled`, 0);
              }
            });

            if (_.includes(['beveledFramedPanel', 'squareFramedPanel'], value)) {
              _.forEach(['front', 'panel'], dependentMaterialKey => {
                if (_.get(product, `details.${dependentMaterialKey}Material.id`) && !_.includes(K.frameAndPanelCompatibleFrontMaterials, _.get(product, `details.${dependentMaterialKey}Material.id`))) {
                  product.details[`${dependentMaterialKey}Material`].id = 1;
                  _.set(product, `materialIds[${dependentMaterialKey}].id`, 1);
                  updates.details = {...updates.details, [`${dependentMaterialKey}Material`]: {id: 1}};

                  isUpdatingMaterial = true;
                }
              });

              //HINT no cirle pulls for framed panels (knobs and i pulls are allowed, specifically the circle pull is not)
              if (_.includes([16], _.get(product, `details.pullType.id`))) {
                product.details[`pullType`].id = 25;
                updates.details = {...updates.details, pullType: {id: 25}};
              }
            }
          }
        }

        if (isUpdatingMaterial) updates.materialIds = product.materialIds;

        updates.details = _.mapValues(updates.details, detail => {
          return _.pick(detail, ['id', 'settings']);
        });
      }
      // else {
      //   updatesMap.products.updates.push({where: {id: activeEntityId}, props: {...updates}});
      // }
    }
    else {
      updates = {...updates, [path]: value};
    }

    if (_.includes(path, 'details') && !isSubproductDetail) {
      var children = DetailsHelper.getDetailsChildrenFor({product});

      if (children) _.forEach(children, child => {
        //TODO update appliance panels to pull from correct subproduct material callout
        UpdatesMapsHelpers.combineUpdatesMaps(updatesMap, handleProductPropertyChange({activeEntityId: child.id, activeEntity: child, path, resourceActions, value, isBatched: true}));
      });
    }

    var {managedUpdatesMap, productCacheUpdate} = Product.updateManagedResources({product, actionKey: 'update', resourceActions, isBatched: true});

    UpdatesMapsHelpers.combineUpdatesMaps(updatesMap, managedUpdatesMap);

    if (productCacheUpdate) {
      product.customData = {...product.customData, cachedManagedData: productCacheUpdate};

      updates = {...updates, customData: product.customData};
    }

    updatesMap.products.updates.push({where: {id: activeEntityId}, props: {...updates}});

    if (!isBatched) {
      if (shouldUpdateRoomManagedResources) Room.updateManagedResources({room, resourceActions});

      UpdatesMapsHelpers.makeReduxUpdatesFor({updatesMap, resourceActions});
    }

    return updatesMap;
  }
}

export { getProductFieldGroups, handleProductPropertyChange, getCompatibleProductOptionsFieldSets };
