import React, { useEffect, useRef } from 'react';
import DrawingPageFooter from '../drawings-components/drawing-page-footer';
import CoverPage from './cover-page';
import CriticalSchedulesPage from './critical-schedules-page';
import MaterialsAndAppliancesPage from './materials-and-appliances-page';
import StandardPage from './standard-page';
import InstallationPage1 from './typical-installation-details-page-1';
import InstallationPage2 from './typical-installation-details-page-2';
import InstallationPage3 from './typical-installation-details-page-3';
import InstallationPage4 from './typical-installation-details-page-4';
import Project from 'project-helpers/project';
import _ from 'lodash';
import { connect, resourceActions } from '../../../redux/index';

import ErrorFallback from 'components/error-fallback';
import {withErrorBoundary} from 'react-error-boundary';

const pageTypes = {
  cover: CoverPage,
  criticalSchedules: CriticalSchedulesPage,
  materialsAndAppliances: MaterialsAndAppliancesPage,
  standard: StandardPage,
};

const imgTypes = {
  typicalInstallationDetails1: InstallationPage1,
  typicalInstallationDetails2: InstallationPage2,
  typicalInstallationDetails3: InstallationPage3,
  typicalInstallationDetails4: InstallationPage4,
}

const Page = ({type, id, page, project, floors, rooms, elevations, mode, isEditing, pageCount, pageNumber, updateProject, fillMode, detailLevel, notes, handleUpdateNotes, materialFrequencyGroups, visibilityLayers, ...rest}) => {
  const companyName = Project.getCompanyName({project});
  const PageComponent = type === 'imgPage'
    ? imgTypes[id] || <div/>
    : pageTypes[type] || <div/>;
  const footerRef = useRef(null);

  //HINT only set footerHeight once when rest.onSetFooterHeight
  useEffect(() => {
    if (rest.onSetFooterHeight && footerRef?.current) {
      rest.onSetFooterHeight(footerRef.current.clientHeight);
    }
  }, [rest.onSetFooterHeight, footerRef]);

  return (
    <div className={`drawings-page type-${_.kebabCase(type)}`} key={`${type}-${id}`}>
      <div className="drawings-page-content">
        <PageComponent
          {...{page, project, floors, rooms, elevations, mode, isEditing, updateProject, fillMode, detailLevel,
            notes, handleUpdateNotes, materialFrequencyGroups, visibilityLayers,
            ..._.omit(rest, ['onSetFooterHeight'])
          }}
          dimensionsVisible={false}
          isBindingDimensionsPage={false}
        />
      </div>
      <DrawingPageFooter
        {...{project, pageCount, pageNumber, companyName, detailLevel, isSpaceTheory: project.companyKey === 'vp', page, updateProject}}
        isEditingEnabled={isEditing}
        ref={footerRef}
      />
    </div>
  );

};

export default withErrorBoundary(connect({
  mapState: state => {
    return {
      elevations: state.resources.elevations.byId,
      project: _.values(state.resources.projects.byId)[0],
      rooms: state.resources.rooms.byId,
      floors: state.resources.floors.byId
    };
  },
  mapDispatch: {
    ..._.pick(resourceActions.projects, ['updateProject']),
  },
})(Page), {
  FallbackComponent: ErrorFallback,
  onError: (error, info) => global.handleError({error, info, message: 'Standard Page Graphic'})
});
