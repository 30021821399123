import K from 'k';
import _ from 'lodash';
import lib from 'lib';
import Wall from 'project-helpers/wall';
import Room from 'project-helpers/room';
import Container from 'project-helpers/container';
import Volume from 'project-helpers/volume';
import ProjectGraphic from 'project-helpers/project-graphic';
import ArchElement from 'project-helpers/arch-element';
import getDependencies from 'helpers/get-dependencies';
import memo from 'helpers/memo';
import Product from './product';

// import { store } from 'redux/index.js';

var Elevation = {
  get(dependencyKeys, {elevation}) {
    return getDependencies({dependencyKeys}, ({state, useDependency}) => {
      if (useDependency('archElements') || useDependency('walls')) {
        var isSection = Elevation.getIsSection({elevation});
      }

      if (useDependency('archElements') || useDependency('allWalls') || useDependency('walls') || useDependency('unsortedWalls') || useDependency('wallSets')) {
        if (_.has(elevation, 'lineInRoom')) {
          var footprintInRoom = Elevation.getFootprintInRoom({elevation});
        }

        var unsortedWalls = _.chain(Room.get('walls', {room: state.resources.rooms.byId[elevation.roomId]}))
          .values()
          .filter(wall => {
            var wallLineInRoom = Wall.getLine({wall}).inRoom;
            var endPointsAreInFootprint = _.some(_.values(wallLineInRoom), point => lib.polygon.pointInsidePolygon({point, polygon: footprintInRoom}));
            var wallLineIntersectsWithElevationLine = lib.math.linesIntersect({l1: elevation.lineInRoom, l2: wallLineInRoom}); //HINT rawLineInRoom is important here to prevent inifinite loop

            return (endPointsAreInFootprint || wallLineIntersectsWithElevationLine);
          })
          .value();

        var allWalls = unsortedWalls;
      }

      if ((useDependency('walls') || useDependency('unsortedWalls') || useDependency('wallSets')) && !isSection) {
        unsortedWalls = _.filter(unsortedWalls, wall => {
          var theta = Elevation.getWallTheta({elevation, wall});
          var thetaScalar = Math.cos(theta);

          return Math.abs(thetaScalar) > Number.EPSILON;
        });
      }

      if (useDependency('floor') || useDependency('containers') || useDependency('products') || useDependency('unmanagedProducts') || useDependency('room') || useDependency('archElements') || useDependency('volumes')) {
        var room = state.resources.rooms.byId[elevation.roomId];
      }

      if (useDependency('containers') || useDependency('products') || useDependency('unmanagedProducts') || useDependency('volumes')) {
        var elevationFootprintInRoom = Elevation.getFootprintInRoom({elevation});

        var containers = _.filter(Room.get('containers', {room}), container => {
          var containerFootprintInRoom = Container.getFootprintInRoom({container});

          return container.position && !_.isEqual(container.position, {}) && lib.polygon.polygonsOverlap(elevationFootprintInRoom, containerFootprintInRoom);
        });

        var volumes = _.filter(Room.get('volumes', {room}), volume => {
          var elevationFootprintInRoom = Elevation.getFootprintInRoom({elevation});
          var volumeFootprintInRoom = Volume.getFootprintInRoom({volume});

          return lib.polygon.polygonsOverlap(elevationFootprintInRoom, volumeFootprintInRoom);
        });

        var productCandidates = Room.get('products', {room});

        var products = _.filter(productCandidates, product => {
          return _.includes(_.map(containers, 'id'), product.containerInstanceId);
        });

        products = [...products, ..._.filter(productCandidates, product => _.includes(_.map(products, 'id'), product.productInstanceId))];
      }

      if (useDependency('archElements')) {
        var archElementCandidates = Room.get('archElements', {room});

        var archElements = _.filter(archElementCandidates, archElement => {
          const typeData = ArchElement.get('typeData', {archElement});
          let archElementWall = _.find(unsortedWalls, {id: archElement.wallId});

          return _.includes(['wall', 'hybrid'], typeData.classification) && archElementWall && ArchElement.isShowingFrontFor({archElement, elevation}) && _.some(ArchElement.getWallprintInElevation({elevation, archElement}), point => point.x > 0 && point.x <= Elevation.getWidth({elevation}));
        });

        if (!isSection) {
          archElements = _.filter(archElements, archElement => {
            return _.includes(_.map(unsortedWalls, 'id'), archElement.wallId);
          });
        }
      }

      return {
        room: () => room,
        floor: () => state.resources.floors.byId[room.floorId],
        walls: () => _.sortBy(unsortedWalls, wall => Elevation.getWallX({elevation, wall})),
        wallSets: () => Room.getWallSets({room: state.resources.rooms.byId[elevation.roomId], walls: unsortedWalls}),
        allWalls: () => allWalls,
        unsortedWalls: () => unsortedWalls,
        containers: () => containers,
        volumes: () => volumes,
        products: () => products,
        unmanagedProducts: () => _.filter(products, product => !Product.getIsManaged({product}) && product.position),
        archElements: () => archElements,
        projectGraphics: () => state.resources.projectGraphics.byFieldKeyIndex.elevationId[elevation.id],
        datums: () => room.datums,
        xzDatums: () => _.filter(room.xzDatums, (datum) => {
          return lib.math.linesIntersect({l1: datum, l2: elevation.lineInRoom});
        }),
        dependencies: () => state.resources
        // computedWalls: () => Room.getComputedWalls({wallSets: dependencies.wallSets, room})
      };
    });
  },

  update({id, elevation = {}, cachedElevation = {}, props, resourceActions, pushToUndoQueue}) {
    if (!id) id = elevation.id || cachedElevation.id;

    resourceActions.updateElevation({id, props});

    if (pushToUndoQueue && cachedElevation) pushToUndoQueue({type: 'elevation', eventKey: 'transformEnd', instance: cachedElevation});
  },

  destroy({elevation, resourceActions, pushToUndoQueue}) {
    if (pushToUndoQueue) {
      pushToUndoQueue({type: 'elevation', eventKey: 'destroy', instance: elevation, data: {}});
    }

    resourceActions.destroyElevation({id: elevation.id});
  },

  getPosition2d: memo(({elevation, position3d}) => {
    var elevationXYOrigin = elevation.lineInRoom.from;
    var elevationXZOrigin = {x: elevationXYOrigin.x, z: elevationXYOrigin.y};

    var relativeXZPositionInElevation = lib.object.difference(position3d, elevationXZOrigin);

    var rotatedXZPositionInElevation = lib.math.trig.rotate({
      point: {x: relativeXZPositionInElevation.x, y: relativeXZPositionInElevation.z},
      byDegrees: -lib.trig.radiansToDegrees(Elevation.getAlpha({elevation}) + Math.PI)
    });

    var position2d = {x: K.round(rotatedXZPositionInElevation.x), y: -position3d.y, z: K.round(rotatedXZPositionInElevation.y)};

    return position2d;
  }, {name: 'getPosition2d'}),

  getIsSection: memo(({elevation}) => {
    var walls = Elevation.get('allWalls', {elevation});
    var elevationAlpha = Elevation.getAlpha({elevation});

    return walls.length === 1 && _.filter(walls, wall => {
      var wallAlpha = Wall.getAlpha({wall});

      return lib.trig.anglesAreEqual({a1: wallAlpha, a2: elevationAlpha - Math.PI / 2})
          || lib.trig.anglesAreEqual({a1: wallAlpha, a2: elevationAlpha + Math.PI / 2});
    }).length === 1;
  }),

  getTitle({elevation}) {
    const elevations = Room.get('sortedElevations', {roomId: elevation.roomId});
    const regex = /elevation \d+(?: -)?/i;

    let title = _.clone(elevation.title);

    if (title && regex.test(title)) {
      title = title.replace(regex, '').trim();
    }

    return `Elevation ${elevations.indexOf(elevation) + 1} ${!_.isEmpty(title) ? `- ${title}` : ''}`;
  },

  getAlpha: memo(({elevation, perpendicularAlpha = false}) => {
    if (elevation.lineInRoom) {
      return lib.trig.alpha({p1: elevation.lineInRoom.to, p2: elevation.lineInRoom.from, perpendicularAlpha});
    }
  }),

  getVisibleLineInRoom({elevation}) {
    var walls = Elevation.get('unsortedWalls', {elevation});

    // if (_.has(elevation, 'lineInRoom')) {
    var footprintInRoom = Elevation.getFootprintInRoom({elevation});
    // }

    var points = [];
    var intersectionPoints = {from: null, to: null};
    var midpoint = lib.math.midpoint({p1: elevation.lineInRoom.from, p2: elevation.lineInRoom.to});

    _.forEach(walls, wall => {
      var wallLineInRoom = Wall.getLine({wall}).inRoom;

      points.push(..._.filter(wallLineInRoom, point => lib.polygon.pointInsidePolygon({point, polygon: footprintInRoom})));

      _.forEach(['from', 'to'], rangeKey => {
        var l1 = {from: elevation.lineInRoom[rangeKey], to: midpoint};

        if (lib.math.linesIntersect({l1, l2: wallLineInRoom})) {
          var intersectionPoint = lib.math.intersectionPoint({l1, l2: wallLineInRoom});

          intersectionPoints[rangeKey] = intersectionPoint;
        }
      });
    });

    points.push(..._.map(['from', 'to'], rangeKey => intersectionPoints[rangeKey] || elevation.lineInRoom[rangeKey]));

    var pointsOnLine = _.map(points, point => lib.trig.nearestPoint({point, onLine: elevation.lineInRoom}));
    var pointsByDistance = _.sortBy(pointsOnLine, point => lib.trig.distance({fromPoint: point, toPoint: elevation.lineInRoom.from}));

    return {from: _.first(pointsByDistance), to: _.last(pointsByDistance)};
  },

  getWidth({elevation}) {
    return lib.math.trig.distance({fromPoint: elevation.lineInRoom.from, toPoint: elevation.lineInRoom.to});
  },

  getOutline({elevation, wallOutlines}) {
    if (!wallOutlines) {
      var walls = Elevation.get('walls', {elevation});

      wallOutlines = _.filter(_.map(walls, wall => Wall.getOutlinePoints({elevation, wall})), wallOutline => !_.isEmpty(wallOutline));
    }

    var outline = _.first(wallOutlines);

    _.forEach(wallOutlines, (p, index) => {
      if (index >= 1) outline = lib.polygon.union({p1: outline, p2: p});
    });

    return outline;
  },

  getFootprintInRoom: memo(({elevation}) => {
    var depthPositionFor = position => lib.object.sum(position, lib.trig.rotate({point: {x: 0, y: viewDepth}, byRadians: Elevation.getAlpha({elevation, perpendicularAlpha: true})}));

    var {viewDepth} = elevation;
    var {from, to} = elevation.lineInRoom;

    return [from, to, depthPositionFor(to), depthPositionFor(from)];
  }),

  getFootprintLinesInRoom: memo(({elevation}) => {
    var footprint = Elevation.getFootprintInRoom({elevation});

    return {
      front: {from: footprint[0], to: footprint[1]},
      back: {from: footprint[2], to: footprint[3]},
      left: {from: footprint[0], to: footprint[3]},
      right: {from: footprint[1], to: footprint[2]}
    };
  }),

  getWallTheta: memo(({elevation, wall}) => {
    var alpha = Elevation.getAlpha({elevation});
    var theta = lib.trig.normalize({radians: alpha - Wall.getAlpha({wall}) - Math.PI}); //HINT - Math.PI because elevation is opposite a wall that would be facing it (theta "0")

    if (Math.abs(theta) < 0.0001) theta = 0;

    return theta;
  }),

  getMinWallX({elevation}) {
    let minWallX = 0;
    let {allWalls, walls, room} = Elevation.get(['allWalls', 'walls', 'room'], {elevation});
    const fromIsInRoom = lib.polygon.pointInsidePolygon({point: elevation.lineInRoom.from, polygon: room.plan.points});

    if (!fromIsInRoom && walls && walls.length > 0) {
      minWallX = _.min(_.map(walls, wall => Elevation.getWallX({elevation, wall})));

      var wallsWithSectionCuts = _.filter(allWalls, wall => !wall.isPseudoWall && lib.math.linesIntersectInclusive({l1: elevation.lineInRoom, l2: Wall.getLine({wall}).inRoom}));
      var sectionWallLineXs = _.map(wallsWithSectionCuts, wall => {
        var positionInRoom = lib.trig.nearestPoint({point: Wall.getLine({wall}).inRoom.from, onLine: elevation.lineInRoom});
        var positionInElevation = Elevation.getPosition2d({elevation, position3d: {y: 0, x: positionInRoom.x, z: positionInRoom.y}});

        return positionInElevation.x;
      });

      minWallX = _.min([minWallX, ...sectionWallLineXs]);
    }

    return minWallX;
  },

  getWallX: memo(({elevation, wall}) => {
    var wallLine = Wall.getLine({wall}).inRoom;
    var visibleLineInRoom = Elevation.getVisibleLineInRoom({elevation});

    var flattenedWallLine = _.map(wallLine, point => lib.trig.nearestPoint({point, onLine: visibleLineInRoom}));
    var wallLineDistances = _.map(flattenedWallLine, point => lib.trig.distance({fromPoint: point, toPoint: visibleLineInRoom.from}));

    return lib.number.round(_.min(wallLineDistances), {toNearest: 1/32});
  }, {name: 'getWallX'}),

  getWallFor({elevation, x}) {
    var walls = Elevation.get('walls', {elevation});

    walls = _.filter(walls, wall => Elevation.getVisibleWallWidth({wall, elevation}) > 0);

    var wall = _.find(walls, wall => {
      var wallX = Elevation.getWallX({elevation, wall});
      var wx1 = wallX, wx2 = wallX + Elevation.getVisibleWallWidth({wall, elevation});

      return wx1 <= x && x < wx2;
    });
    return wall || walls[0];
  },

  getVolumeFor({elevation, position}) {
    var {volumes} = Elevation.get(['volumes'], {elevation});

    var volumeCandidates = _.filter(volumes, volume => {
      return lib.polygon.pointInsidePolygon({point: position, polygon: Volume.getWallprint({volume, elevation})});
    });

    return _.minBy(volumeCandidates, volume => Volume.getZIndex({volume, elevation}));
  },

  getVisibleWallWidth({elevation, wall}) {
    var theta = Elevation.getWallTheta({wall, elevation});

    if (theta !== 0) return 0; //TODO angled walls

    var outlinePoints = Wall.getOutlinePoints({elevation, wall});
    var xs = _.map(outlinePoints, 'x');

    return _.max(xs) - _.min(xs);
  },

  getXRange({elevation}) {
    const outline = Elevation.getOutline({elevation});

    return {from: _.min(_.map(outline, 'x')), to: _.max(_.map(outline, 'x'))};
  },

  //#WARNING container x's are relative to the room, not the elevation
  getContainerBoundaryPositions({elevation, filter}) {
    var containers = Elevation.get('containers', {elevation});

    if (filter) containers = filter({containers});

    var boundaries = lib.object.fromKeys(['max', 'min'], boundaryKey => {
      return lib.object.fromKeys(['y', 'x'], axisKey => {
        var rangeKey = boundaryKey === 'min' ? 'from' : 'to';
        var containerBoundaries = _.map(containers, container => {
          return Container[`get${_.upperFirst(axisKey)}Range`]({container})[rangeKey];
        });

        return _[boundaryKey](containerBoundaries) || 0;
      });
    });

    return boundaries;
  },

  getRotation({elevation}) {
    var {from, to} = Elevation.getVisibleLineInRoom({elevation});

    return lib.trig.normalize({degrees: lib.math.trig.radiansToDegrees(lib.math.trig.alpha({p1: from, p2: to}))});
  },

  getParallelWalls({elevation}) {
    var walls = Elevation.get('walls', {elevation});

    return _.filter(walls, wall => {
      var elevationWallTheta = Elevation.getWallTheta({elevation, wall});

      //HINT radians have rounding issues and need to include the 'back' side of walls
      return _.includes([0, 360], _.round(lib.trig.radiansToDegrees(elevationWallTheta)));
    });
  },

  getMaxHeight({elevation}) {
    var {allWalls, walls, containers, volumes} = Elevation.get(['containers', 'walls', 'allWalls', 'volumes'], {elevation});

    var wallOutlines = _.filter(_.map(walls, wall => Wall.getOutlinePoints({elevation, wall})), wallOutline => !_.isEmpty(wallOutline));
    var outline = Elevation.getOutline({elevation, wallOutlines});

    var wallHeights = [..._.map(walls, wall => Wall.getHeight({wall}))];

    var wallsWithSectionCuts = _.filter(allWalls, wall => !wall.isPseudoWall && lib.math.linesIntersectInclusive({l1: elevation.lineInRoom, l2: Wall.getLine({wall}).inRoom}))
    _.forEach(wallsWithSectionCuts, wall => {
      var positionInRoom = lib.trig.nearestPoint({point: Wall.getLine({wall}).inRoom.from, onLine: elevation.lineInRoom});
      var positionInElevation = Elevation.getPosition2d({elevation, position3d: {y: 0, x: positionInRoom.x, z: positionInRoom.y}});
      var heightCandidates = _.filter(_.map(outline, (point, i) => ({from: point, to: lib.array.next(outline, i)})), (line, i) => {
        return lib.math.linesIntersectInclusive({l1: {from: {x: positionInElevation.x, y: 0}, to: {x: positionInElevation.x, y: -10000}}, l2: line})
      });

      var height = _.min(_.map(heightCandidates, (line, i) => {
        return lib.math.intersectionPoint({l1: {from: {x: positionInElevation.x, y: 0}, to: {x: positionInElevation.x, y: -10000}}, l2: line}).y
      }));

      wallHeights.push(-height || wall.outline.height);
    });

    return _.max([... wallHeights, ..._.map(containers, container => Container.getYRange({container}).to), ..._.map(volumes, volume => Volume.getYRange({volume}).to)]);
  },

  getHeight({elevation}) {
    return Elevation.getMaxHeight({elevation});
  },

  getFieldGroups({elevation, activeDetailLevel}) {
    var fieldSetGroups = [
      {title: Elevation.getTitle({elevation}), properties: []},
    ];

    var isIsle = Elevation.getIsIsle({elevation});

    fieldSetGroups[0].properties.push(...[
      {
        key: 'ceilingHeight',
        type: 'number',
        views: ['front'],
        title: 'Ceiling Height',
        userLenses: ['design', 'sales', 'engineering']
      },
      {
        key: 'indicatorOffset',
        type: 'number',
        views: ['top'],
        title: 'Indicator Offset'
      },
      {
        path: 'datums',
        type: 'text',
        title: 'Datum Lines',
        placeholder: 'i.e. 35, 60',
        views: ['front']
      },
      {
        path: 'customData.pullAlignment',
        type: 'size',
        title: 'Pull Alignment (distance from floor to center of pull, only for full height tall units)',
        views: ['top', 'front']
      },
      ...(isIsle ? [{
        path: 'customData.hideIsleCeilingLine',
        type: 'checkbox',
        views: ['top', 'front'],
        title: 'Hide Ceiling Line',
      }] : []),
      ...(activeDetailLevel === 'rendering' ? [
        {
          path: 'customData.floorRenderingGradientFill',
          type: 'color',
          defaultColor: 'rgb(0, 0, 0)',
          views: ['top', 'front'],
          title: 'Floor Gradient Fill',
        },
        {
          path: 'customData.floorRenderingGradientFromOpacity',
          type: 'number',
          views: ['top', 'front'],
          title: 'Floor Gradient From Opacity',
        },
        {
          path: 'customData.floorRenderingGradientHeight',
          type: 'number',
          placeholder: 30,
          views: ['top', 'front'],
          title: 'Floor Gradient Height',
        },
      ] : [])
    ]);

    return fieldSetGroups;
  },

  getAlignmentIndicatorLines({elevation}) {
    var alignmentLinesX = [];
    var alignmentLinesY = [];
    var alignmentIndicators = [];

    var {unmanagedProducts, containers, archElements} = Elevation.get(['unmanagedProducts', 'containers', 'archElements'], {elevation});

    var alignmentCandidates = [
      ..._.map(unmanagedProducts, unmanagedProduct => ({candidate: unmanagedProduct, type: 'unmanagedProduct'})),
      ..._.map(containers, container => ({candidate: container, type: 'container'})),
      ..._.map(archElements, archElement => ({candidate: archElement, type: 'archElement'}))
    ]; //collection of containers, obstacles, products, and walls

    var alignmentVertices = _.map(alignmentCandidates, ({candidate, type}) => {
      var vertices;
      if (type === 'container') {
        vertices = Container.getWallprint({container: candidate, elevation});
      }
      else if (type === 'archElement') {
        vertices = ArchElement.getWallprintInElevation({archElement: candidate, elevation});
      }
      else if (type === 'unmanagedProduct') {
        let positionInElevation = Product.getPositionInElevation({product: candidate, elevation});
        positionInElevation = _.omit(positionInElevation, ['z']);
        const size = Product.getSize({product: candidate, viewKey: 'front', elevation});
        positionInElevation.y = -positionInElevation.y - size.height;

        vertices = [
          lib.object.sum(positionInElevation, {x: size.width, y: size.height}),
          lib.object.sum(positionInElevation, {x: 0, y: size.height}),
          positionInElevation,
          lib.object.sum(positionInElevation, {x: size.width, y: 0}),
        ];
      }

      var xs = _.map(vertices, 'x');
      var centerX = (_.min(xs) + _.max(xs)) / 2;

      vertices.push({x: centerX, y: _.minBy(vertices, 'y').y, isCenterX: true}, {x: centerX, y: _.maxBy(vertices, 'y').y, isCenterX: true});

      return {vertices, candidate};
    });

    var walls = Elevation.get('walls', {elevation});

    var wallOutlines = _.filter(_.map(_.cloneDeep(walls), wall => Wall.getOutlinePoints({elevation, wall})), wallOutline => !_.isEmpty(wallOutline));

    alignmentVertices.push(..._.map(wallOutlines, polygon => {
      var vertices = polygon;

      var xs = _.map(vertices, 'x');

      var centerX = (_.min(xs) + _.max(xs)) / 2;

      if (vertices.length) {
        vertices.push({x: centerX, y: _.minBy(vertices, 'y').y, isCenterX: true}, {x: centerX, y: _.maxBy(vertices, 'y').y, isCenterX: true});
      }

      vertices = _.map(vertices, vertex => ({...vertex, y: -vertex.y}));

      return {vertices, candidate: {resourceKey: 'wall'}};
    }));

    //for Xs
    _.forEach(alignmentVertices, (alignmentVertice) => {
      if (alignmentVertice) {
        var { vertices, candidate } = alignmentVertice;
        var isProductOrContainer = _.includes(['product', 'container'], candidate.modelKey);
        var isNotFront = _.includes(['left', 'right'], candidate.sideKey);
        var isWall = _.includes(['wall'], candidate.resourceKey);
        var isCountertop = candidate.type === 'countertop';

        if (!((isProductOrContainer && isNotFront) || isWall || isCountertop)) {
          _.forEach(vertices, vertex => {
            _.forEach(alignmentVertices, ({vertices: verticesOne, candidate: candidateOne}) => {
              if (!_.isEqual(candidate, candidateOne)) {
                var parentAndChildrenIds = _.map([candidate.container, candidate.parent, ...(candidate.overlappedCountertops || []), ...(candidate.allChildren || []), ...(candidate.products || [])], 'id');

                if (!_.includes(parentAndChildrenIds, candidateOne.id)) {
                  _.forEach(verticesOne, vertexOne => {
                    if (vertex.x === vertexOne.x && (vertex.y !== vertexOne.y)) {

                      alignmentLinesX.push({
                        from: {...vertex, y: -vertex.y},
                        to: {...vertexOne, y: -vertexOne.y}
                      });
                    }

                    if (!(vertex.isCenterX || vertexOne.isCenterX) && vertex.y !== 0 && vertex.y === vertexOne.y && vertex.x !== vertexOne.x) {
                      alignmentLinesY.push({
                        from: {...vertex, y: -vertex.y},
                        to: {...vertexOne, y: -vertexOne.y}
                      });
                    }
                  });
                }
              }
            });
          });

          _.remove(alignmentVertices, ({candidate: c1}) => _.isEqual(c1, candidate));
        }
      }
    });

    alignmentIndicators = _.map(_.uniqBy(alignmentLinesX, line => JSON.stringify(line)), line => {
      var shouldSwapFromTo = false;

      var lineAlpha = lib.trig.alpha({p1: line.from, p2: line.to});

      if (lineAlpha === Math.PI / 2) {
        shouldSwapFromTo = true;
      }

      if (shouldSwapFromTo) {
        var from = {...line.from};

        line.from = line.to;
        line.to = from;
      }

      return {
        from: {x: 0, y: 0},
        to: {x: 0, y: line.to.y - line.from.y},
        origin: {x: 'left', y: 'bottom'},
        offset: line.from,
        stroke: 'red',
        strokeWidth: 1,
      };
    });

    alignmentIndicators = [
      ...alignmentIndicators,
      ..._.map(_.uniqBy(alignmentLinesY, line => JSON.stringify(line)), line => {
        var shouldSwapFromTo = false;

        var lineAlpha = lib.trig.alpha({p1: line.from, p2: line.to});

        if (lineAlpha === Math.PI) {
          shouldSwapFromTo = true;
        }

        if (shouldSwapFromTo) {
          var from = {...line.from};

          line.from = line.to;
          line.to = from;
        }

        return {
          from: {x: 0, y: 0},
          to: {x: line.to.x - line.from.x, y: 0},
          origin: {x: 'left', y: 'bottom'},
          offset: line.from,
          stroke: 'red',
          strokeWidth: 1,
        };
      })
    ];

    return alignmentIndicators;
  },

  getProjectionHeightFor({elevation, drawingsMode}) {
    return Elevation.topProjectionHeightFor({elevation, drawingsMode}) + Elevation.bottomProjectionHeightFor({elevation, drawingsMode});
  },

  topProjectionHeightFor({elevation, drawingsMode}) {
    const containers = Elevation.get('containers', {elevation});
    const frontFacingContainers = _.filter(containers, container => Container.getSideKey({elevation, container, viewKey: 'front'}) === 'front');
    const lightingContainers = _.filter(frontFacingContainers, container => Container.getHasLighting({container}) && _.get(container, 'customData.lightingType') !== 'linear');

    let topProjectionHeight = 0;

    if (drawingsMode === 'production' && lightingContainers?.length > 0) {
      topProjectionHeight = _.max(_.map(lightingContainers, 'dimensions.height')) + 40;
    }

    return topProjectionHeight;
  },

  bottomProjectionHeightFor({elevation, drawingsMode}) {
    const containers = Elevation.get('containers', {elevation});
    const containersWithBottomProjections = _.filter(containers, container => {
      return container.type === 'opencase' && Container.getHasProjection({container, elevation});
    });

    let bottomProjectionHeight = 0;

    if (drawingsMode === 'production' && containersWithBottomProjections?.length > 0) {
      const projectionExtrema = Elevation.getContainerBoundaryPositions({
        elevation,
        filter: ({containers}) => {
          return _.filter(containers, container => {
            return _.includes(containersWithBottomProjections, container);
          })
        }
      });
      //HINT projection margin + dimension height + unit heights
      bottomProjectionHeight = 35 + (projectionExtrema.max.y - projectionExtrema.min.y);
    }

    return bottomProjectionHeight;
  },

  getSameAngleContainers({elevation}) {
    var elevationAlpha = Elevation.getAlpha({elevation});
    var containers = Elevation.get('containers', {elevation});

    containers = _.filter(containers, container => !Container.getTypeDataFor({container}).isOrnament);

    return _.filter(containers, container => lib.trig.anglesAreEqual({a1: elevationAlpha + Math.PI, a2: Container.getAlpha({container})}));
  },

  getSize({elevation}) {
    return {
      width: Elevation.getWidth({elevation}),
      height: Elevation.getHeight({elevation}),
    };
  },

  getContentOutline({elevation, activeDetailLevel}) {
    // containers, walls, volumes
    const {containers, volumes, projectGraphics, walls, allWalls} = Elevation.get(['containers', 'volumes', 'projectGraphics', 'allWalls', 'walls'], {elevation});

    var wallOutlines = _.filter(_.map(walls, wall => Wall.getOutlinePoints({elevation, wall})), wallOutline => !_.isEmpty(wallOutline));

    var outline = _.map(Elevation.getOutline({elevation, wallOutlines}) || [], point => ({...point, y: -point.y}));
    var minWallX = Elevation.getMinWallX({elevation});

    var wallsData = _.filter(_.map(_.orderBy(walls, wall => Wall.getZIndexFor({wall, elevation})), wall => {
      var wallX = Elevation.getWallX({elevation, wall});
      var outlinePoints = Wall.getOutlinePoints({elevation, wall});

      return {wall, wallX, outlinePoints};
    }), wallData => !_.isEmpty(wallData.outlinePoints));

    _.forEach(wallsData, wallData => {
      outline.push(..._.map(wallData.outlinePoints, point => ({x: point.x, y: -point.y})));
    });

    const projectGraphicPoints = _.flatMap(_.filter(projectGraphics, projectGraphic => _.includes(['polygon', 'text', 'textPointer'], projectGraphic.type) && projectGraphic.data.position) , projectGraphic => {
      var normalizedPosition = lib.object.sum({...projectGraphic.data.position, y: -projectGraphic.data.position.y}, {x: minWallX});

      if (projectGraphic.type === 'polygon') {
        var normalizedPoints = _.map(projectGraphic.data.points, point => ({...point, y: -point.y}));
        var minX = _.min(_.map(normalizedPoints, 'x'));
        var maxX = _.max(_.map(normalizedPoints, 'x'));
        var minY = _.min(_.map(normalizedPoints, 'y'));
        var maxY = _.max(_.map(normalizedPoints, 'y'));

        var size = {width: Math.abs(maxX - minX), height: Math.abs(maxY - minY)};

        return [
          normalizedPosition,
          ..._.map([{x: minX, y: minY}, {x: maxX, y: maxY}], polygonExtrema => lib.object.sum(normalizedPosition, polygonExtrema)),
        ];
      }
      else {
        var size = projectGraphic.data.size || {width: 50, height: 10};

        return [
          normalizedPosition,
          lib.object.sum(normalizedPosition, {x: size.width, y: -size.height}),
          ...(projectGraphic.type === 'textPointer' ? [lib.object.sum({x: projectGraphic.data.to.x, y: -projectGraphic.data.to.y}, {x: minWallX})] : [])
        ];
      }
    });

    var wallsWithSectionCuts = _.filter(allWalls, wall => !wall.isPseudoWall && lib.math.linesIntersectInclusive({l1: elevation.lineInRoom, l2: Wall.getLine({wall}).inRoom}));
    var sectionWallLineXs = _.map(wallsWithSectionCuts, wall => {
      var positionInRoom = lib.trig.nearestPoint({point: Wall.getLine({wall}).inRoom.from, onLine: elevation.lineInRoom});
      var positionInElevation = Elevation.getPosition2d({elevation, position3d: {y: 0, x: positionInRoom.x, z: positionInRoom.y}});

      return positionInElevation.x;
    });

    const containerPoints = _.map(containers, container => Container.getWallprintInElevation({container, elevation}));
    const volumePoints = _.map(volumes, volume => Volume.getWallprintInElevation({volume, elevation}));
    const wallYs = outline.length ? [] : _.map(allWalls, wall => Wall.getHeight({wall}));
    const points = _.flatten(outline.concat(projectGraphicPoints, containerPoints, volumePoints));
    const xs = [..._.map(points, 'x'), ...sectionWallLineXs];
    const ys = [..._.map(points, 'y'), ...wallYs];

    const bottomProjectionHeight = Elevation.bottomProjectionHeightFor({elevation, drawingsMode: global.visibilityLayers.projections ? 'production' : 'client'});
    const topProjectionHeight = Elevation.topProjectionHeightFor({elevation, drawingsMode: global.visibilityLayers.projections ? 'production' : 'client'});

    let minY = _.min(ys) - bottomProjectionHeight;
    let maxY = _.max(ys) + topProjectionHeight;

    if (!bottomProjectionHeight && activeDetailLevel === 'rendering') {
      minY = _.min(ys) - 30;

      var floorlineXOffset = wallsData.length === 0 ? 20 : 0;
      xs.push(_.min(xs) - 45 - floorlineXOffset);
      xs.push(_.max(xs) + 45 + floorlineXOffset);
    }

    return {
      min: {x: _.min(xs), y: minY},
      max: {x: _.max(xs), y: maxY},
      minNonGraphicY: _.min([..._.map(_.flatten(outline.concat(containerPoints, volumePoints)), 'y'), ...wallYs]),
      maxNonGraphicY: _.max([..._.map(_.flatten(outline.concat(containerPoints, volumePoints)), 'y'), ...wallYs])
    };
  },

  getFilteredProjectGraphicsFor({elevation, projectGraphics, visibilityLayers, activeDetailLevel}) {
    if (!projectGraphics) projectGraphics = Elevation.get('projectGraphics', {elevation});

    var filteredProjectGraphics = [];

    filteredProjectGraphics = _.filter(projectGraphics, projectGraphic => {
      var detailKey = `hideOn${_.upperFirst(activeDetailLevel)}`;
      var isHidden = false;

      if (projectGraphic.data[detailKey] === 1) {
        isHidden = true;
      }

      if (projectGraphic.data.isGrainflowIndicator && !visibilityLayers.grainFlow) isHidden = true;

      return !isHidden;
    });

    return filteredProjectGraphics;
  },

  //HINT IMPORTANT ONLY WORKS FOR PROJECT GRAPHICS RIGHT NOW
  //MOSTLY WRITTEN GENERALLY
  getUpdatedZForSendTo({elevation, entityId, entityResourceKey, sendTo, entities, orderedEntities, visibilityLayers}) {
    var newZPosition;
    var showWallsAndArchElements = visibilityLayers.wallsAndArchElements;
    // getNormalizedPositionInElevationFor = (entity) => {
    //   if (entity.resourceKey === 'projectGraphic') {
    //     return {z: ProjectGraphic.getZIndex({[entity.resourceKey]: entity, elevation, viewKey: 'front'})};
    //   }
    //   else {

    //   }
    // };

    if (!orderedEntities) {
      if (!entities) {
        var {containers, volumes, archElements, projectGraphics} = Elevation.get(['containers', 'volumes', 'archElements', 'projectGraphics'], {elevation});

        var filteredContainers = _.filter(containers, container => !!Container.getScript({container, elevation, viewKey: 'front'}))

        entities = [
          ..._.map(volumes, volume => ({...volume, resourceKey: 'volume'})), ..._.map(filteredContainers, container => ({...container, resourceKey: 'container'})),
          ...(showWallsAndArchElements ? _.map(archElements, archElement => ({...archElement, resourceKey: 'archElement'})) : {}),
          ..._.map(projectGraphics, projectGraphic => ({...projectGraphic, resourceKey: 'projectGraphic'}))
        ];
      }

      orderedEntities = _.orderBy(_.map(entities, entity => {
        var Resource = {
          container: Container,
          volume: Volume,
          archElement: ArchElement,
          projectGraphic: ProjectGraphic,
          wall: Wall
        }[entity.resourceKey];

        return {...entity, zIndex: Resource.getZIndex({[entity.resourceKey]: entity, elevation, viewKey: 'front'})};
      }), 'zIndex', 'asc');
    }

    var movedEntityIndex = _.findIndex(orderedEntities, entity => entity.resourceKey === entityResourceKey && entity.id === entityId);
    var movedEntity = orderedEntities[movedEntityIndex];

    if (movedEntityIndex !== -1) {
      var relativeEntity;

      if (sendTo === 'forward') relativeEntity = movedEntityIndex === orderedEntities.length - 1 ? orderedEntities[movedEntityIndex] : orderedEntities[movedEntityIndex + 1];
      if (sendTo === 'backward') relativeEntity = movedEntityIndex === 0 ? orderedEntities[movedEntityIndex] : orderedEntities[movedEntityIndex - 1];
      if (sendTo === 'front') relativeEntity = _.last(orderedEntities);
      if (sendTo === 'back') relativeEntity = _.first(orderedEntities);

      // var relativeObjectNormalPosition = getNormalizedPositionInElevationFor(relativeObject);
      // var movedEntityNormalPosition = getNormalizedPositionInElevationFor(movedEntity);

      var scalar = _.includes(['backward', 'back'], sendTo) ? -1 : 1;
      newZPosition = relativeEntity.zIndex;

      if (!_.isEqual(relativeEntity, movedEntity)) newZPosition += (0.001 * scalar);

      // var position3d = denormalizePositionToElevationAlpha(lib.object.sum(offset, relativeObjectNormalPosition));
    }

    return newZPosition;
  },

  getFirstWallXInset({elevation}) {
    var {walls, allWalls, room} = Elevation.get(['walls', 'allWalls', 'room'], {elevation});
    var isSection = Elevation.getIsSection({elevation});
    var elevationWidth = Elevation.getWidth({elevation});

    var wallOutlines = _.filter(_.map(walls, wall => Wall.getOutlinePoints({elevation, wall})), wallOutline => !_.isEmpty(wallOutline));

    var outline = Elevation.getOutline({elevation, wallOutlines});

    var wallsData = _.filter(_.orderBy(_.map(walls, wall => {
      var wallX = Elevation.getWallX({elevation, wall});
      var outlinePoints = Wall.getOutlinePoints({elevation, wall});

      var showingCompleteOutline = !(Wall.getXOffsetInElevation({wall, elevation}) < 0 || (wallX + _.max(_.map(wall.outline.points, 'x'))) > elevationWidth);

      //HINT pseudoWalls should appear behind everything else
      return {wall, wallX, outlinePoints, showingCompleteOutline, zIndex: wall.isPseudoWall ? -10000 : Wall.getZIndexFor({wall, elevation})};
    }), wallData => wallData.zIndex), wallData => !_.isEmpty(wallData.outlinePoints) && !lib.trig.anglesAreEqual({a1: Elevation.getWallTheta({elevation, wall: wallData.wall}), a2: Math.PI}));

    var wallsWithSectionCuts = _.filter(allWalls, wall => !wall.isPseudoWall && lib.math.linesIntersectInclusive({l1: elevation.lineInRoom, l2: Wall.getLine({wall}).inRoom}));
    var sectionCutLinesData = _.map(wallsWithSectionCuts, wall => {
      var positionInRoom = lib.trig.nearestPoint({point: Wall.getLine({wall}).inRoom.from, onLine: elevation.lineInRoom});
      var positionInElevation = Elevation.getPosition2d({elevation, position3d: {y: 0, x: positionInRoom.x, z: positionInRoom.y}});
      var heightCandidates = _.filter(_.map(outline, (point, i) => ({from: point, to: lib.array.next(outline, i)})), (line, i) => {
        return lib.math.linesIntersectInclusive({l1: {from: {x: positionInElevation.x, y: 0}, to: {x: positionInElevation.x, y: -10000}}, l2: line});
      });

      var height = _.min(_.map(heightCandidates, (line, i) => {
        return lib.math.intersectionPoint({l1: {from: {x: positionInElevation.x, y: 0}, to: {x: positionInElevation.x, y: -10000}}, l2: line}).y;
      }));

      return {wall, height: height || -wall.outline.height, x: positionInElevation.x};
    });

    if (isSection && !wallsData.length && sectionCutLinesData.length === 1) {
      var wallData = sectionCutLinesData[0];
      var {wall} = wallData;

      wallsData = [
        {wall, wallX: Elevation.getWallX({elevation, wall}), zIndex: -10000, outlinePoints: [{x: wallData.x, y: wallData.height}, {x: wallData.x, y: 0}, {x: wallData.x, y: 0}, {x: wallData.x, y: wallData.height}]}
      ];
    }

    var leftMostWallData = _.minBy(wallsData, wallData => _.min(_.map(wallData.outlinePoints, 'x')));

    const fromIsInRoom = lib.polygon.pointInsidePolygon({point: elevation.lineInRoom.from, polygon: room.plan.points});

    var firstWallXInset = fromIsInRoom ? 0 : (_.min(_.map(leftMostWallData?.outlinePoints, 'x')) || 0);

    return firstWallXInset;
  },

  getVisibleWalls({elevation}) {
    var {walls, allWalls} = Elevation.get(['walls', 'allWalls'], {elevation});

    var wallsWithSectionCuts = _.filter(allWalls, wall => !wall.isPseudoWall && lib.math.linesIntersectInclusive({l1: elevation.lineInRoom, l2: Wall.getLine({wall}).inRoom}));

    return _.uniqBy([...walls, ...wallsWithSectionCuts], 'id');
  },

  getIsIsle({elevation}) {
    var {walls, allWalls, room} = Elevation.get(['walls', 'allWalls', 'room'], {elevation});
    var elevationWidth = Elevation.getWidth({elevation});

    var wallsData = _.filter(_.orderBy(_.map(walls, wall => {
      var wallX = Elevation.getWallX({elevation, wall});
      var outlinePoints = Wall.getOutlinePoints({elevation, wall});

      var showingCompleteOutline = !(Wall.getXOffsetInElevation({wall, elevation}) < 0 || (wallX + _.max(_.map(wall.outline.points, 'x'))) > elevationWidth);

      //HINT pseudoWalls should appear behind everything else
      return {wall, wallX, outlinePoints, showingCompleteOutline, zIndex: wall.isPseudoWall ? -10000 : Wall.getZIndexFor({wall, elevation})};
    }), wallData => wallData.zIndex), wallData => !_.isEmpty(wallData.outlinePoints) && !lib.trig.anglesAreEqual({a1: Elevation.getWallTheta({elevation, wall: wallData.wall}), a2: Math.PI}));

    var wallsWithSectionCuts = _.filter(allWalls, wall => !wall.isPseudoWall && lib.math.linesIntersectInclusive({l1: elevation.lineInRoom, l2: Wall.getLine({wall}).inRoom}));

    return wallsData.length === 0 && wallsWithSectionCuts.length === 2;
  }
};

export default Elevation;
