import React, { Component } from 'react';
import _ from 'lodash';
import K from 'k';
import upArrowIcon from '../../../assets/up-arrow-black.png';
import HudElement from './hud-element';
import NumberInput from 'components/number-input';
import {ConnectedPropertyField} from 'components/property-field';
import ProjectGraphic from 'project-helpers/project-graphic';
import Elevation from 'project-helpers/elevation';
import ArchElement from 'project-helpers/arch-element';
import DetailsHelper from 'helpers/details-helper';

import { resourceActions, connect } from 'redux/index.js';
import { pluralize } from 'inflection';

import {getRoomFieldGroups, handleRoomPropertyChange} from 'properties-view-data/room-properties-view-helpers';
import {getContainerFieldGroups, handleContainerPropertyChange} from 'properties-view-data/container-properties-view-helpers';
import {getProductFieldGroups, handleProductPropertyChange} from 'properties-view-data/product-properties-view-helpers';
import { getVolumeFieldGroups } from 'properties-view-data/volume-properties-view-helpers';
import TextInput from 'components/text-input';
import { getFloorFieldGroups } from 'properties-view-data/floor-properties-view-helpers';

class PropertiesViewHudElement extends React.Component {
  shouldComponentUpdate(nextProps) {
    return (_.get(nextProps.activeEntities, '0.eventType') !== 'transform' || _.get(this.props.activeEntities, '0.eventType') !== 'transform');
  }

  state = {
    activeGroupIndices: [],
    // activeGroupIndex: 0,
    activePropertyKey: null,
    fieldSets: null
  };

  componentDidMount() {
    this.setFieldSets({considerSettingActiveGroupIndices: true});
    this.searchInputRef = React.createRef();

    document.addEventListener('keydown', this.handleSearchFocus);
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this.handleSearchFocus);
  }

  handleSearchFocus = (event) => {
    if (event.key === 'f' && lib.event.keyPressed(event, 'ctrlcmd') && !lib.event.keyPressed(event, 'shift')) {
      event.preventDefault();

      if (!_.isEmpty(this.searchInputRef.current)) {
        this.searchInputRef.current.focus();
      }
    }
  };

  componentDidUpdate(prevProps, prevState) {
    var activeEntityChanged = _.get(prevProps, 'activeEntities.0.id') !== _.get(this.props, 'activeEntities.0.id') || _.get(prevProps, 'activeEntities.0.resourceKey') !== _.get(this.props, 'activeEntities.0.resourceKey');
    var activeEntityIsTransforming = _.get(this.props, 'activeEntities.0.eventType') === 'transform';
    var activeViewEntityChanged = prevProps.activeViewEntityId !== this.props.activeViewEntityId || prevProps.activeViewEntityResourceKey !== this.props.activeViewEntityResourceKey;
    var activeEntityWasUpdated = !activeEntityIsTransforming && _.get(prevProps, 'activeEntities.0') && _.get(this.props, 'activeEntities.0') && this.difference(_.get(prevProps, 'activeEntities.0'), _.get(this.props, 'activeEntities.0')).length > 0;
    var activeDetailLevelChanged = prevProps.activeDetailLevel !== this.props.activeDetailLevel;
    var activeUserLenseChanged = prevProps.activeUserLense !== this.props.activeUserLense;

    var fieldSetsShouldUpdate = activeEntityChanged || activeEntityWasUpdated || activeDetailLevelChanged || activeUserLenseChanged || activeViewEntityChanged;

    if (fieldSetsShouldUpdate) {
      this.setFieldSets({considerSettingActiveGroupIndices: activeEntityChanged});
      if (this.state.activePropertyKey !== null) this.setState({activePropertyKey: null});
    }
  }

  difference = (obj1, obj2) => {
    if (!obj2) return [undefined];

    const diffProperties = _.reduce(obj1, (result, value, key) => {
      return _.isEqual(value, obj2[key]) ?
        result : result.concat(key);
    }, []);

    return diffProperties || [];
  };

  setFieldSets({considerSettingActiveGroupIndices = false}) {
    var fieldSets;
    var {activeEntities, activeEntitiesData, getIncludesActiveEntity, activeDetailLevel, viewKey, viewMode, elevation, activeFillMode, activeUserLense} = this.props;
    var newState = {};

    if (_.size(activeEntities) && activeEntities.length === 1) {
      if (getIncludesActiveEntity({resourceKey: 'room'})) {
        fieldSets = getRoomFieldGroups({room: _.first(activeEntities)});
      }
      else if (getIncludesActiveEntity({resourceKey: 'container'})) {
        fieldSets = getContainerFieldGroups({container: _.first(activeEntities), activeDetailLevel, activeFillMode, viewKey, elevation, viewMode, activeUserLense});
      }
      else if (getIncludesActiveEntity({resourceKey: 'archElement'})) {
        fieldSets = ArchElement.getFieldGroups({archElement: _.first(activeEntities), viewKey, elevation, viewMode});
      }
      else if (getIncludesActiveEntity({resourceKey: 'product'})) {
        fieldSets = getProductFieldGroups({product: _.first(activeEntities), viewKey, elevation, viewMode, activeUserLense, activeDetailLevel});
      }
      else if (getIncludesActiveEntity({resourceKey: 'projectGraphic'})) {
        fieldSets = ProjectGraphic.getFieldGroups({projectGraphic: _.first(activeEntities), viewKey, elevation, viewMode});
      }
      else if (getIncludesActiveEntity({resourceKey: 'elevation'})) {
        fieldSets = Elevation.getFieldGroups({elevation: _.first(activeEntities), activeDetailLevel});
      }
      else if (getIncludesActiveEntity({resourceKey: 'volume'})) {
        fieldSets = getVolumeFieldGroups({volume: _.first(activeEntities), viewKey, elevation, viewMode, activeDetailLevel, activeFillMode});
      }
      else if (getIncludesActiveEntity({resourceKey: 'scope'})) {
        fieldSets = [];
      }
      else if (getIncludesActiveEntity({resourceKey: 'floor'})) {
        fieldSets = getFloorFieldGroups({floor: _.first(activeEntities)});
      }
    }

    if (fieldSets) {
      _.forEach(fieldSets, fieldSet => {
        fieldSet.properties = _.filter(fieldSet.properties, property => {
          var propertyUserLenses = property.userLenses || ['design', 'engineering'];

          return _.includes(propertyUserLenses, activeUserLense);
        });
      });

      fieldSets = _.filter(fieldSets, fieldSet => fieldSet.properties.length > 0);
    }

    if (this.difference(fieldSets, this.state.fieldSets).length > 0) {
      newState.fieldSets = fieldSets;
    }

    if (_.size(activeEntitiesData) && _.includes(['room', 'container', 'product'], _.get(activeEntitiesData, '0.resourceKey'))) {
      newState.details = DetailsHelper.getDetailsFor({[_.get(activeEntitiesData, '0.resourceKey')]: _.first(activeEntities)});
    }

    if (considerSettingActiveGroupIndices) {
      if (activeUserLense === 'sales') {
        newState.activeGroupIndices = [...Array(_.get(fieldSets, 'length', 0)).keys()];
      }
      else {
        newState.activeGroupIndices = _.isEmpty(fieldSets) ? [] : [0];
      }
    }

    if (!_.isEmpty(newState)) {
      this.setState(newState);
    }
  }

  handleSearchInputChange = (value) => {
    var filteredProperties = _.filter(_.flatMap(this.state.fieldSets, 'properties'), property => {
      return _.includes(property.title.toLowerCase(), value.toLowerCase());
    });

    this.setState({searchTerm: value, filteredProperties});
  };

  toggleActiveGroup({index}) {
    var {activeUserLense} = this.props;
    var {activeGroupIndices} = this.state;
    var isActive = _.includes(activeGroupIndices, index);

    var newActiveGroupIndices = isActive ? _.filter(activeGroupIndices, activeIndex => activeIndex !== index) : [...activeGroupIndices, index];

    //HINT for design only 1 group is expanded at a time, due to length
    if (activeUserLense !== 'sales') newActiveGroupIndices = isActive ? [] : [index];

    this.setState({activeGroupIndices: newActiveGroupIndices, activePropertyKey: undefined});
  }

  render () {
    const {activeEntities, activeEntitiesData, toggleParameterEditor} = this.props;
    const {fieldSets, details, searchTerm, filteredProperties, activePropertyKey} = this.state;

    var activeEntity = _.get(activeEntities, '[0]');

    var renderPropertyFields = ({properties, parentIndex}) => {
      return _.map(properties, ({path, title, type, defaultTrue, inverseValue, noThumbnail, hasOptionGroups, options, optionGroups, views, key, placeholder, maxValue, minValue, step, allowEmptyString, isMultiline, defaultColor}, index) => {

        var widerOptionsDropdown = hasOptionGroups || !noThumbnail || _.some(options, option => option.title && option.title.length > 20);

        return (
          <ConnectedPropertyField
            key={index}
            {...{details, path: path || key, title, type, defaultTrue, inverseValue, noThumbnail, hasOptionGroups, views, placeholder, index, options, optionGroups, activeEntity, activeEntityId: _.get(activeEntitiesData, '0.id'), activeEntityResourceKey: _.get(activeEntitiesData, '0.resourceKey'), maxValue, minValue, step, allowEmptyString, isMultiline, widerOptionsDropdown, defaultColor, toggleParameterEditor}}
            onClick={() => {this.setState({activePropertyKey: this.state.activePropertyKey === `${parentIndex}-${index}` ? null : `${parentIndex}-${index}`});}}
            isExpanded={_.includes(['radio', 'select'], type) && this.state.activePropertyKey === `${parentIndex}-${index}`}
          />
        );
      });
    };

    var activeOption = _.get(fieldSets, `[${_.split(activePropertyKey, '-')[0]}].properties[${_.split(activePropertyKey, '-')[1]}]`);

    var wideOptionOpen = false;

    if (activeOption) {
      var {hasOptionGroups, noThumbnail, options, type} = activeOption;

      var isWide = _.includes(['radio', 'select'], type) && (hasOptionGroups || !noThumbnail || _.some(options, option => option.title && option.title.length > 20));

      wideOptionOpen = isWide;
    }


    return (
      <HudElement x='right' y='bottom' pane style={{width: K.paneWidth * (wideOptionOpen ? 1.5 : 1), height: '100%', borderLeft: '1px solid rgba(0, 0, 0, 0.05)', backgroundColor: '#f5f5f5', zIndex: 15, display: 'flex', flexDirection: 'column', overflow: 'overlay'}}>
        <div style={{flex: 1}}>
          <div style={{width: '100%'}}>
            <TextInput
              style={{width: '100%', backgroundColor: '#f5f5f5', border: '1px solid rgba(0, 0, 0, 0.07)'}}
              value={searchTerm}
              onInput={this.handleSearchInputChange}
              placeholder='SEARCH...'
              ref={this.searchInputRef}
            />
            {searchTerm && (
              <div style={{opacity: 0.5, padding: K.spacing, paddingTop: K.spacing / 2, display: 'flex', justifyContent: 'space-between'}}>
                <div>{filteredProperties.length} result(s)</div>
                <div
                  style={{textDecoration: 'underline', cursor: 'pointer'}}
                  onClick={() => this.setState({searchTerm: null})}
                >clear</div>
              </div>
            )}
          </div>
          {searchTerm ? (
            <div style={{paddingTop: K.spacing, paddingBottom: K.spacing * 2}}>
              {renderPropertyFields({properties: filteredProperties})}
            </div>
          ) : (
            <div style={{marginTop: K.spacing * 2}}>
              {_.map(fieldSets, ({title, properties}, index) => {
                var isActive = _.includes(this.state.activeGroupIndices, index);

                return (
                  <div key={index} style={{marginBottom: K.spacing}}>
                    <div onClick={() => this.toggleActiveGroup({index})} style={{cursor: 'pointer', display: 'flex', flexDirection: 'row', justifyContent: 'flex-end'}}>
                      <div style={{marginBottom: K.margin, marginRight: K.spacing, ...(isActive ? {fontWeight: 'bold'} : {opacity: 0.5}), fontSize: 17, textAlign: 'right'}}>{title}</div>
                      <img src={upArrowIcon} alt='arrow' style={{width: 16, height: 16, marginLeft: 8, marginRight: 8, top: 4, position: 'relative', ...(isActive ? {transform: 'rotate(180deg)'} : {transform: 'rotate(270deg)'}), backgroundColor: 'transparent'}}/>
                    </div>
                    {isActive && (
                      <div style={{paddingTop: K.spacing, paddingBottom: K.spacing * 2}}>
                        {renderPropertyFields({properties, parentIndex: index})}
                      </div>
                    )}
                  </div>
                );
              })}
            </div>
          )}
        </div>
      </HudElement>
    );
  }
}

export default connect({
  mapState: (state, ownProps) => {
    var {activeEntitiesData} = ownProps;

    var activeEntities = _.map(activeEntitiesData, activeEntityData => {
      return _.get(state.resources, `[${pluralize(activeEntityData.resourceKey)}].byId[${activeEntityData.id}]`);
    });

    return {
      materialClasses: state.resources.materialClasses.byId,
      productTypes: state.resources.productTypes.byId,
      pulls: state.resources.pulls.byId,
      productOptionTypes: state.resources.productOptionTypes.byId,
      activeEntities,
      activeEntitiesData
    };
  },
  mapDispatch: {
    ..._.pick(resourceActions.rooms, ['trackRooms', 'updateRoom', 'updateRooms']),
    ..._.pick(resourceActions.containers, ['trackContainers', 'updateContainer']),
    ..._.pick(resourceActions.products, ['trackContainers', 'updateProducts']),
    ..._.pick(resourceActions.parts, ['updateParts']),
  }
})(PropertiesViewHudElement);
