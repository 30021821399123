import K from 'k';

var Types = {
  polygon: {
    title: 'Generic Polygon',
    thumbnail: require('assets/arch-element-icons/generic-object-element-icon.png'),
    canvasObjectType: 'polyline',
    resourceProps: {
      customData: {
        points: [
          {x: -10, y: -10},
          {x: 10, y: -10},
          {x: 10, y: 10},
          {x: -10, y: 10}
        ]
      }
    }
  }
};

var FloorTypes = {
  pillar: {
    scripts: {top: 'rect()'},
    thumbnail: require('assets/arch-element-icons/pillar-element-icon.png'),
    resourceProps: {customData: {width: 4, depth: 4}},
    getSize: ({props}) => {
      return {width: props.customData.width, height: props.customData.depth};
    },
    editablePropFieldSets: [
      {key: 'customData.width', type: 'size', title: 'Width'},
      {key: 'customData.depth', type: 'size', title: 'Depth'}
    ]
  },
  rectangularFloorArchitecturalElement: {
    scripts: {top: 'rect()'},
    title: 'Generic Rectangle',
    thumbnail: require('assets/arch-element-icons/generic-object-element-icon.png'),
    resourceProps: {customData: {width: 5, depth: 5}},
    getSize: ({props}) => {
      return {width: props.customData.width, height: props.customData.depth};
    },
    editablePropFieldSets: [
      {key: 'customData.width', type: 'size', title: 'Width'},
      {key: 'customData.depth', type: 'size', title: 'Depth'}
    ]
  }
  // wall: {
  //   title: 'Floating Wall',
  //   canvasObjectType: 'polyline',
  //   resourceProps: {
  //     customData: {
  //       points: [{x: -10, y: 0}, {x: 10, y: 0}]
  //     }
  //   }
  // }
};

var WallTypes = {
  outlet: {
    canvasObjectType: 'script',
    thumbnail: require('assets/arch-element-icons/outlet-element-icon.png'),
    resourceProps: {customData: {width: 3, height: 4}},
    scripts: {front: 'rect({})'},
    editablePropFieldSets: [
      {key: 'customData.width', type: 'size', title: 'Width'},
      {key: 'customData.height', type: 'size', title: 'Height'}
    ]
  },
  rectangularWallArchitecturalElement: {
    title: 'Generic Rectangle',
    thumbnail: require('assets/arch-element-icons/generic-object-element-icon.png'),
    canvasObjectType: 'script',
    resourceProps: {customData: {width: 5, height: 5}},
    scripts: {front: 'rect({})'},
    getSize: ({props}) => {
      return {width: props.customData.width, height: props.customData.height};
    },
    editablePropFieldSets: [
      {key: 'customData.width', type: 'size', title: 'Size', subtitle: 'Width'},
      {key: 'customData.height', type: 'size', subtitle: 'Height'}
    ]
  },
  soffit: {
    canvasObjectType: 'model',
    thumbnail: require('assets/arch-element-icons/generic-object-element-icon.png'),
    resourceProps: {customData: {dimensions: {width: 10, height: 10, depth: 10}}},
    scripts: {
      top: 'rect({})',
      front: 'rect({})',
      left: 'rect({})',
      right: 'rect({})'
    },
    editablePropFieldSets: [
      {key: 'customData.dimensions.width', type: 'size', title: 'Width'},
      {key: 'customData.dimensions.height', type: 'size', title: 'Height'},
      {key: 'customData.dimensions.depth', type: 'size', title: 'Depth'}
    ]
  }
};

var HybridTypes = {
  door: {
    thumbnail: require('assets/arch-element-icons/door-element-icon.png'),
    snapToFloor: true,
    resourceProps: {
      customData: {
        openingWidth: 30, openingHeight: 90, trimDepth: 1.5,
        leftTrimWidth: 2, rightTrimWidth: 2, topTrimHeight: 2,
        action: 'left', opens: 'in'
      }
    },
    getSize: ({props, sideKey}) => {
      props = props.customData;

      return {
        top: {
          width:  props.trimDepth + props.openingWidth,
          height: props.openingWidth + props.leftTrimWidth + props.rightTrimWidth},
        front: {
          width: props.openingWidth + props.leftTrimWidth + props.rightTrimWidth,
          height: props.openingHeight + props.topTrimHeight}
      }[sideKey];
    },
    scripts: {
      top: `
        var {
          openingWidth: w, openingHeight: oh,
          leftTrimWidth: ltw, rightTrimWidth: rtw,
          topTrimHeight: tth, action, opens, trimDepth
        } = _.props.customData;

        var cornerX = opens === 'in' ? w : -1 * w;
        var actionLeft = action === 'left';
        var doorGroup = group({height: w + ' - ' + ltw + ' - ' + rtw, top: rtw}, [
          line({x1: 0, y1: actionLeft ? w : 0, x2: cornerX, y2: actionLeft ? w : 0}),
          path({dashed: true}, [
            {x: 0, y: actionLeft ? 0 : w},
            {x: cornerX, y: actionLeft ? w : 0, arc: true, quarterCircle: true,
              flipped: (opens === 'out' && action === 'right') || (opens === 'in' && action === 'left')}
          ])
          //line({x1: 0, y1: , x2: cornerX, y2: , dashed: true})
        ]);

        group({}, [
          rect({height: '100% - ' + (ltw + rtw), top: rtw, width: ${K.drawingsStrokeWidths.heavy}, fill: 'white', stroke: '', opacity: 1, left: -1.5}),
          line({x1: 0, y1: 0, x2: 0, y2: '100%', strokeWidth: 1}),
          rect({height: rtw, width: trimDepth}),
          doorGroup,
          rect({height: ltw, width: trimDepth, top: rtw + w}),
        ])`,
      front: `
        var {
          openingWidth: ow, openingHeight: oh,
          leftTrimWidth: ltw, topTrimHeight: tth, openingWidth: w, action, opens
        } = _.props.customData;

        var handleLeft = ltw + (action === 'right' ? 4 : (ow - 4));

        group({}, [
          rect({height: oh, width: ow, left: ltw, top: tth}),
          circle({radius: 1, top: '100% - 34', left: handleLeft}),
          rect()
        ])`
    },
    editablePropFieldSets: [
      {key: 'customData.openingWidth', type: 'size', title: 'Opening Width'},
      {key: 'customData.openingHeight', type: 'size', title: 'Opening Height'},
      {key: 'customData.leftTrimWidth', type: 'size', title: 'Left Trim'},
      {key: 'customData.rightTrimWidth', type: 'size', title: 'Right Trim'},
      {key: 'customData.topTrimHeight', type: 'size', title: 'Top Trim'},
      {key: 'customData.trimDepth', type: 'size', title: 'Trim Depth', views: ['top']},
      {key: 'customData.action', type: 'radio', title: 'Door Swings', options: [
          {value: 'left', title: 'Left'}, {value: 'right', title: 'Right'}]},
      {key: 'customData.opens', type: 'radio', title: 'Door Opens', options: [
          {value: 'in', title: 'In'}, {value: 'out', title: 'Out'}]}
    ]
  },
  window: {
    snapToCenterX: true,
    thumbnail: require('assets/arch-element-icons/window-element-icon.png'),
    defaultY: 36,
    resourceProps: {
      customData: {
        openingWidth: 36, openingHeight: 36, trimDepth: 1.5,
        leftTrimWidth: 3, rightTrimWidth: 3, topTrimHeight: 3, bottomTrimHeight: 3
      }
    },
    getSize: ({props, sideKey}) => {
      props = props.customData;

      return {
        top: {
          width: (_.isNaN(props.trimDepth) ? 1.5 : props.trimDepth) + 6,
          height: props.openingWidth + props.leftTrimWidth + props.rightTrimWidth
        },
        front: {
          width: props.openingWidth + props.leftTrimWidth + props.rightTrimWidth,
          height: props.openingHeight + props.topTrimHeight + props.bottomTrimHeight}
      }[sideKey];
    },
    scripts: {
      top: `
        var {
          rightTrimWidth: rtw,
          leftTrimWidth: ltw, topTrimHeight: tth, openingWidth: w, trimDepth=1.5
        } = _.props.customData;

        group({}, [
          rect({height: '100% - ' + (ltw + rtw), top: rtw, width: ${K.drawingsStrokeWidths.heavy}, fill: 'white', stroke: '', opacity: 1, left: -1.5}),
          rect({height: rtw, width: trimDepth}),
          group({height: '100% - ' + rtw + ' - ' + ltw, top: rtw}, [
            rect({left: '-6'}),
            line({x1: '-3', y1: 0, x2: '-3', y2: '100%', opacity: 0.5})
          ]),
          rect({height: ltw, width: trimDepth, top: rtw + w}),
        ])`,
      front: `
        var {
          leftTrimWidth: ltw, topTrimHeight: tth,
          openingHeight: oh, openingWidth: ow
        } = _.props.customData;

        var children = [
          group({left: ltw, top: tth, width: ow, height: oh}, [
            rect(),
            group({left: '50% - 12', top: '50% - 3'}, [
              line({x1: 2, x2: 7, y1: 5, y2: 0}),
              line({x1: 4, x2: 7, y1: 5.5, y2: 2.5}),
            ])
          ]),
          rect()
        ];

        if (_.getShouldShowCenterline && _.getShouldShowCenterline()) {
          children.push(line({dashed: true, x1: '50%', y1: 0, x2: '50%', y2: '100%'}));
          children.push(text({
            text: 'C',
            origin: {x: 'left', y: 'top'},
            fontSize: 8/3,
            fontWeight: 'bold',
            top: '50% - 1.5',
            left: '50% + 1'
          }));
          children.push(text({
            text: 'L',
            origin: {x: 'left', y: 'top'},
            fontSize: 8/3,
            fontWeight: 'bold',
            top: '50% - 0.7',
            left: '50% + 0.7 + 1'
          }));
        }

        group({}, [...children])`
    },
    editablePropFieldSets: [
      {key: 'customData.openingWidth', type: 'size', title: 'Opening Width'},
      {key: 'customData.openingHeight', type: 'size', title: 'Opening Height'},
      {key: 'customData.leftTrimWidth', type: 'size', title: 'Left Trim'},
      {key: 'customData.rightTrimWidth', type: 'size', title: 'Right Trim'},
      {key: 'customData.bottomTrimHeight', type: 'size', title: 'Bottom Trim'},
      {key: 'customData.topTrimHeight', type: 'size', title: 'Top Trim'},
      {key: 'customData.trimDepth', type: 'size', title: 'Trim Depth', views: ['top']},

    ]
  },
  opening: {
    snapToFloor: true,
    thumbnail: require('assets/arch-element-icons/opening-element-icon.png'),
    resourceProps: {
      customData: {
        openingWidth: 36, openingHeight: 90, trimDepth: 1.5,
        leftTrimWidth: 3, rightTrimWidth: 3, topTrimHeight: 3
      }
    },
    getSize: ({props, sideKey}) => {
      props = props.customData;

      return {
        top: {
          width: props.trimDepth + 2,
          height: props.openingWidth + props.leftTrimWidth + props.rightTrimWidth},
        front: {
          width: props.openingWidth + props.leftTrimWidth + props.rightTrimWidth,
          height: props.openingHeight + props.topTrimHeight}
      }[sideKey];
    },
    scripts: {
      top: `
        var {
          openingWidth: w,
          leftTrimWidth: ltw, rightTrimWidth: rtw, trimDepth
        } = _.props.customData;

        group({}, [
          rect({height: '100% - ' + (ltw + rtw), top: rtw, width: ${K.drawingsStrokeWidths.heavy}, fill: 'white', stroke: '', opacity: 1, left: -1.5}),
          line({dashed: true, x1: 0, y1: 0, x2: 0, y2: '100%', strokeWidth: 1}),
          rect({width: trimDepth, height: rtw}),
          line({y1: rtw, y2: rtw, x1: 0, x2: -2}),
          rect({height: ltw, width: trimDepth, top: w + rtw}),
          line({top: w + rtw, y1: 0, y2: 0, x1: 0, x2: -2})
        ])`,
      front: `
        var {
          openingWidth: ow, openingHeight: oh,
          leftTrimWidth: ltw, topTrimHeight: tth, openingWidth: w
        } = _.props.customData;

        group({}, [
          rect({height: oh, width: ow, left: ltw, top: tth}),
          rect()
        ])`
    },
    editablePropFieldSets: [
      {key: 'customData.openingWidth', type: 'size', title: 'Opening Width'},
      {key: 'customData.openingHeight', type: 'size', title: 'Opening Height'},
      {key: 'customData.leftTrimWidth', type: 'size', title: 'Left Trim'},
      {key: 'customData.rightTrimWidth', type: 'size', title: 'Right Trim'},
      {key: 'customData.topTrimHeight', type: 'size', title: 'Top Trim'},
      {key: 'customData.trimDepth', type: 'size', title: 'Trim Depth', views: ['top']},
    ]
  }
};

var ArchElementConstants = {generic: Types, floor: FloorTypes, wall: WallTypes, hybrid: HybridTypes};

export default ArchElementConstants;
